import React, { useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../../../theme/Tabs/index';
import Summary from './summary';
import Traffic from './traffic';
import { NavLink } from 'react-router-dom';
import { AntContainerHead } from '../../../../theme/Wrapper';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_ALERTS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import TabPanelLayout from '../../../../components/global/tab/TabPanelLayout/Component';
import TabPanelBackLink from '../../../../components/global/tab/TabPanelBackLink';

const AnomalyDetails = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const { tab, backLink, id, indexPage } = useParams();

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    const { data, loading } = useQuery(GET_ARBOR_ALERTS, {
        variables: { detailsId: Number(id) },
    });

    if (loading) return <Preloader />;

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <TabPanelBackLink to={`/${backLink}/${indexPage}`} text="Anomaly details" />
                    <AntTabs value={indexTab} onChange={handleChange}>
                        <AntTab
                            label="summary"
                            {...a11yProps(0)}
                            component={NavLink}
                            to={`/anomaly-details/0/${backLink}/${id}/${indexPage}`}
                        />
                        <AntTab
                            label="traffic"
                            {...a11yProps(1)}
                            component={NavLink}
                            to={`/anomaly-details/1/${backLink}/${id}/${indexPage}`}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <Summary store={data} />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <Traffic store={data} />
            </TabPanelLayout>
        </>
    );
};

export default AnomalyDetails;
