import React, { useContext, useEffect, useState } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table/index';
import { BaseSwitch } from '../../../theme/Switch';
import {
    Table,
    TableBody,
    Typography,
    TableContainer,
    TableHead,
    TableRow,
    FormGroup,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { textWidthCalculation } from '../../../helpers/calculation';
import ModalMore from '../../../components/modals/modalsTable/more';
import ModalThreatLevel from '../../../components/modals/modalsTable/threatLevel';
import TableFilter from '../../../components/global/table/TableFilter';
import TableDatePicker from '../../../components/global/table/TableDatePicker';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import Preloader from '../../../components/global/Preloader';
import { PageContext } from '../../../context/pages';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_ALERTS } from '../../../actions/get';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import Anomaly from './Anomaly';
import TableSort from '../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const Anomalies = () => {
    const classes = useStyles();

    const { filter, setFilter } = useContext(PageContext);
    const [sortActive, setSortActive] = useState('sortId');

    const [anchorElThreatLevel, setAnchorElThreatLevel] = useState(null);
    const [anchorElMore, setAnchorElMore] = useState(null);

    const [state, setState] = useState(null);
    const [typePopover, setTypePopover] = useState([]);
    const [stateSwitch, setStateSwitch] = useState({
        bps: true,
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_ALERTS);

    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            importance: filter?.threatLevel || ['low', 'medium', 'high'],
            ips: filter?.ips || '',
            gte: new Date(filter.gte).toISOString(),
            lte: new Date(filter.lte).toISOString(),
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);
        if (filter?.sort?.checked) proxy.ongoing = 'true';
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
        filter?.threatLevel &&
            Object.entries(filter?.threatLevel || {})?.reduce((acc, value) => {
                if (value[1]) {
                    acc.push(value[0]);
                }
                return acc;
            }, []);
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );

    if (loading) return <Preloader />;

    const openModalThreatLevel = Boolean(anchorElThreatLevel);
    const idPopoverThreatLevel = openModalThreatLevel ? 'threat-Level' : undefined;
    const openModalMore = Boolean(anchorElMore);
    const idPopoverMore = openModalMore ? 'popover-more' : undefined;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertId))),
        ips: Math.max.apply(
            Math,
            data && data.arborAlerts.items.map(items => textWidthCalculation(items.alertResourceCidr))
        ),
    };

    return (
        <>
            <ModalMore
                id={idPopoverMore}
                open={openModalMore}
                anchorEl={anchorElMore}
                setAnchorEl={setAnchorElMore}
                value={typePopover}
                title="Type"
            />
            <ModalThreatLevel
                id={idPopoverThreatLevel}
                open={openModalThreatLevel}
                anchorEl={anchorElThreatLevel}
                setAnchorEl={setAnchorElThreatLevel}
                state={state}
                setState={setState}
                filter={filter}
                setFilter={setFilter}
            />

            <TableContainer>
                <div className={classes.header}>
                    <Typography variant="h1">Detected traffic anomalies list</Typography>
                    <div className={classes.date}>
                        <FormControlLabel
                            style={{ marginRight: 40 }}
                            control={
                                <Checkbox
                                    checked={filter?.sort?.checked || false}
                                    onChange={event =>
                                        setFilter({
                                            ...filter,
                                            sort: { ...filter?.sort, checked: event.target.checked },
                                        })
                                    }
                                    name="checkedA"
                                    color="primary"
                                />
                            }
                            label="Show only ongoin anomalies"
                        />
                        <Typography style={{ paddingRight: 15 }}>Time period of shown stats</Typography>
                        <div className={classes.date}>
                            <TableDatePicker filter={filter} setFilter={setFilter} />
                        </div>
                    </div>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <AntTableCell>
                                <TableSort
                                    name="sortId"
                                    direction={filter?.sort?.sortId}
                                    label="ID"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>Threat level</AntTableCell>
                            <AntTableCell>Destination IPs</AntTableCell>
                            <AntTableCell>Type</AntTableCell>
                            <AntTableCell>Resource Name</AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortStart"
                                    direction={filter?.sort?.sortStart}
                                    label="Started At"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortStop"
                                    direction={filter?.sort?.sortStop}
                                    label="Ended At"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortDuration"
                                    direction={filter?.sort?.sortDuration}
                                    label="Duration"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name={stateSwitch.bps ? 'sortBps' : 'sortPps'}
                                    direction={stateSwitch.bps ? filter?.sort?.sortBps : filter?.sort?.sortPps}
                                    label="Max volume"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell />
                        </TableRow>
                        <TableRow>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="id"
                                    maxWidth={maxWidth.id}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.btnFilter}
                                    aria-describedby="threat-Level"
                                    onClick={event => {
                                        setAnchorElThreatLevel(event.currentTarget);
                                    }}
                                >
                                    Set filter
                                </Button>
                            </AntTableCellFilter>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="ips"
                                    maxWidth={maxWidth.ips}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter style={{ padding: '4.5px 10px' }}>
                                <FormGroup>
                                    <Grid component="label" className={classes.switcher}>
                                        <Grid item>pps</Grid>
                                        <Grid item>
                                            <BaseSwitch
                                                checked={stateSwitch.bps}
                                                onChange={event => {
                                                    setStateSwitch({
                                                        ...stateSwitch,
                                                        [event.target.name]: event.target.checked,
                                                    });
                                                }}
                                                name="bps"
                                            />
                                        </Grid>
                                        <Grid item>bps</Grid>
                                    </Grid>
                                </FormGroup>
                            </AntTableCellFilter>
                            <AntTableCellFilter />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.arborAlerts.items.length !== 0 ? (
                            data.arborAlerts.items.map(
                                ({
                                    alertId,
                                    importance,
                                    alertResourceCidr,
                                    arborResource,
                                    misuseTypes,
                                    start,
                                    stop,
                                    duration,
                                    maxImpactBps,
                                    maxImpactPps,
                                    id,
                                    severityPercent,
                                    threshold,
                                    unit,
                                    impactBpsPoints,
                                    impactPpsPoints,
                                    impactRecorded,
                                    alertManagedObjectName,
                                }) => (
                                    <Anomaly
                                        key={alertId}
                                        alertId={alertId}
                                        importance={importance}
                                        alertResourceCidr={alertResourceCidr}
                                        arborResource={arborResource}
                                        misuseTypes={misuseTypes}
                                        start={start}
                                        stop={stop}
                                        duration={duration}
                                        maxImpactBps={maxImpactBps}
                                        maxImpactPps={maxImpactPps}
                                        id={id}
                                        severityPercent={severityPercent}
                                        threshold={threshold}
                                        unit={unit}
                                        impactBpsPoints={impactBpsPoints}
                                        impactPpsPoints={impactPpsPoints}
                                        impactRecorded={impactRecorded}
                                        alertManagedObjectName={alertManagedObjectName}
                                        setAnchorElMore={setAnchorElMore}
                                        setTypePopover={setTypePopover}
                                        stateSwitch={stateSwitch}
                                    />
                                )
                            )
                        ) : (
                            <TableNoDataComponent colSpan={10} />
                        )}
                    </TableBody>
                </Table>
                <TablePaginationComponent
                    filter={filter}
                    setFilter={setFilter}
                    count={data && data.arborAlerts.info.totalCount}
                    getFilter={getFilter}
                    getVariables={getVariables()}
                    type={'FILTER_ACTIVE'}
                />
            </TableContainer>
        </>
    );
};
export default Anomalies;
