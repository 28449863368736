import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/auth';
import Header from '../../Header';

const MyPrivateRoute = ({ children, ...props }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/" replace />;
    }

    return (
        <>
            <Header />
            {children}
        </>
    );
};

export default MyPrivateRoute;
