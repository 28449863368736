import { Container, Paper, withStyles } from '@material-ui/core';

export const AntContainerHead = withStyles(theme => ({
    root: {
        paddingRight: 35,
        paddingLeft: 35,
        margin: 0,
        boxSizing: 'border-box',
        minWidth: 340,
        display: 'flex',
        flexDirection: 'row',
    },
    disableGutters: {
        padding: 0,
    },
}))(Container);

export const AntContainer = withStyles(theme => ({
    root: {
        padding: 35,
        margin: 0,
        boxSizing: 'border-box',
        minWidth: 340,
    },
    disableGutters: {
        padding: 0,
    },
}))(Container);
