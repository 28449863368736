import React, { useState } from 'react';
import { useStyles } from './styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    TextField,
    InputAdornment,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff, FiberManualRecord, Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import routes from '../../../routes';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { toast } from 'react-toastify';
import { SET_PASSWORD } from '../../../actions/update';
import { useMutation } from '@apollo/client';
import Preloader from '../../../components/global/Preloader';

const ResetPassword = () => {
    const classes = useStyles();
   const navigate = useNavigate();
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showDoubleNewPassword, setShowDoubleNewPassword] = useState(false);

    const [setPassword, { loading }] = useMutation(SET_PASSWORD, {
        fetchPolicy: 'no-cache',
        context: {
            plug: true,
        },
    });
    const onSubmit = inputData => {
        localStorage.removeItem('jwtToken');
        setPassword({
            variables: {
                newPassword: inputData.newPassword,
                email: email,
                resetToken: token,
                type: 'PARTNER_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => toast.success('Success'))
            .then(() => navigate('/'))
            .catch(err => toast.error(err.message));
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(checkUppercase, 'Password must have one uppercase character')
            .matches(checkLowercase, 'Password must have one lowercase character')
            .matches(checkNumber, 'Password must have one number')
            .matches(checkSpecialCharacter, 'Password must have one special character')
            .required('Current New Password is required'),
        doubleNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Confirm New Password must match')
            .required('Confirm New Password is required'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('newPassword') ? '' : watch('newPassword');

    if (loading) return <Preloader />;

    return (
        <AuthWrapper>
            <Typography variant="h1" className={classes.title}>
                Reset your password
            </Typography>

            <p className={classes.description}>Almost done, just enter your new password.</p>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="newPassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            error={!!errors.newPassword}
                            type={showNewPassword ? 'text' : 'password'}
                            value={
                                value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                    ? value
                                    : value.replace(value[value.length - 1], '')
                            }
                            label="New Password"
                            onChange={onChange}
                            variant="filled"
                            helperText={errors?.newPassword?.message ?? ' '}
                            className={classes.input}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {errors.newPassword ? (
                                                <Error color="error" />
                                            ) : showNewPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <List className={classes.list}>
                    <ListItem
                        className={`${classes.listItem} ${
                            inputVariable?.split('').length >= 8 ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="8 characters minimum" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkUppercase.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one uppercase character" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkLowercase.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one lowercase character" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkNumber.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one number" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary=" one special character" />
                    </ListItem>
                </List>
                <Controller
                    name="doubleNewPassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            type={showDoubleNewPassword ? 'text' : 'password'}
                            value={value}
                            label="Confirm New Password"
                            onChange={onChange}
                            variant="filled"
                            className={classes.input}
                            error={!!errors.doubleNewPassword}
                            helperText={errors?.doubleNewPassword?.message ?? ' '}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() => setShowDoubleNewPassword(!showDoubleNewPassword)}
                                        >
                                            {errors.doubleNewPassword ? (
                                                <Error color="error" />
                                            ) : showDoubleNewPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <Button type="submit" className={classes.button}>
                    save
                </Button>
            </form>
            <Link to={routes.auth} className={classes.link}>
                RETURN TO LOG IN
            </Link>
        </AuthWrapper>
    );
};

export default ResetPassword;
