import React, { useContext } from 'react';
import { AuthContext } from '../../../../context/auth';
import { Navigate } from 'react-router-dom';

const AuthRoute = ({ children, ...props }) => {
    const { user } = useContext(AuthContext);

    if (user) {
        return <Navigate to="/my-profile/0" replace />;
    }

    return children;
};

export default AuthRoute;
