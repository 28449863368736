import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Popover, Paper, Typography, Button } from '@material-ui/core';
import { useStyles } from '../../../../theme/Modals';

const ModalMore = ({ id, open, anchorEl, setAnchorEl, value, title }) => {
    const classes = useStyles();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const valueArr = Array.from(value);
    const endEll = valueArr.pop();

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.modal}>
                <div className={classes.header}>
                    <Typography variant="h1">{title}</Typography>

                    <Button className={classes.close} onClick={() => handleClose()}>
                        <CloseIcon />
                    </Button>
                </div>
                <div className={classes.modalBody}>
                    <Paper elevation={0} variant="outlined">
                        <Typography style={{ padding: 15 }}>
                            {valueArr.map((valueMap, index) => (
                                <span key={index}>{valueMap}, </span>
                            ))}
                            {<span>{endEll}.</span>}
                        </Typography>
                    </Paper>
                </div>
            </div>
        </Popover>
    );
};

export default ModalMore;
