import { blue } from '@material-ui/core/colors';

const custom = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 429,
            md: 768,
            lg: 1024,
            xl: 1200,
        },
    },
    spacing: 10,
    palette: {
        common: {
            black: '#000000',
            white: '#FFFFFF',
        },
        primary: {
            main: 'rgba(1, 205, 203, 1)',
            light: 'rgba(1, 205, 203, 1)',
            dark: 'rgba(1, 205, 203, 1)',
            contrastText: '#FFFFFF',
            primary300: 'rgba(1, 205, 203, 0.8)',
            primary200: 'rgba(1, 205, 203, 0.2)',
            primary100: 'rgba(1, 205, 203, 0.2)',
            primary50: 'rgba(1, 205, 203, 0.1)',
        },
        secondary: {
            light: 'rgba(0, 233, 198, 0.16)',
            main: 'rgba(0, 233, 198, 0.16)',
            dark: 'rgba(0, 233, 198, 0.16)',
            contrastText: '#01CDCB',
        },
        error: {
            light: '#FCE7E7',
            main: '#E53935',
            dark: '#C62828',
            contrastText: '#FFFFFF',
            backgroundError: '#FFDCDC',
        },
        grey: {
            grey500: '#9E9E9E',
            backgroundDark: '#E8E8E8',
            backgroundMain: '#F0F2F5',
            backgroundLight: '#E5E5E5',
            greyDisable: '#C4C4C4',
            greyDivider: '#E5E5E5',
            greyDark: 'rgba(0, 0, 0, 0.24)',
            greyLight: 'rgba(0, 0, 0, 0.04)',
            greyInactive: 'rgba(0, 0, 0, 0.54)',
            greyMediumEmphasis: 'rgba(0, 0, 0, 0.6)',
            greyHighEmphasis: 'rgba(0, 0, 0, 0.87)',
            greyRgba45: 'rgba(0, 0, 0, 0.45)',
            greyRgba12: 'rgba(0, 0, 0, 0.12)',
            greyRgba85: 'rgba(0, 0, 0, 0.85)',
        },
        black: {
            inactive: 'rgba(0, 0, 0, 0.54)',
        },
        header: {
            bg: '#F2F5F8',
            border: '#FFFFFF',
        },
        navTab: {
            bg: '#F2F5F8',
            border: '#E5E5E5',
        },
        dataPiker: {
            bgInputDefault: 'rgba(245, 245, 245, 1)',
            colorInputDefault: 'rgba(0, 0, 0, 0.6)',
            colorInputFocus: 'rgba(0, 0, 0, 1)',
        },
    },
    typography: {
        h1: {
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '30px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        button: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            fontStyle: 'normal',
            textTransform: 'uppercase',
            textAlign: 'center',
            letterSpacing: '1.25px',
            borderRadius: '4px',
        },
    },
};

export const scheme = () => {
    return custom;
};
