import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 28,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        textAlign: 'center',
        letterSpacing: '0.15px',
        color: theme.palette.grey.greyHighEmphasis,
    },
    description: {
        padding: '0 60px',
        marginBottom: 28,
        textAlign: 'center',
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    text: {
        padding: '0 20px',
        marginBottom: 45,
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));
