import React from 'react';
import { useStyles } from './styles';
import DatePicker from 'react-datepicker';

const TableDatePicker = ({ filter, setFilter }) => {
    const classes = useStyles();
    const dateNow = new Date();
    const gte = new Date(filter.gte);
    const lte = new Date(filter.lte);

    return (
        <div className={classes.pickerWrapper}>
            <DatePicker
                className={classes.dataPicker}
                selected={gte}
                onChange={date => {
                    setFilter({ ...filter, gte: date, page: 0, indexTab: false });
                }}
                showTimeSelect
                startDate={gte}
                endDate={lte}
                maxDate={dateNow}
                dateFormat="dd.MM.yyyy, h:mm aa"
                popperClassName={classes.poper}
                popperPlacement="top-end"
                showPopperArrow={false}
            />
            <span>-</span>
            <DatePicker
                className={classes.dataPicker}
                selected={lte}
                onChange={date => {
                    setFilter({ ...filter, lte: date, page: 0, indexTab: false });
                }}
                showTimeSelect
                startDate={gte}
                endDate={lte}
                maxDate={dateNow}
                dateFormat="dd.MM.yyyy, h:mm aa"
                popperClassName={classes.poper}
                popperPlacement="top-end"
                showPopperArrow={false}
            />
        </div>
    );
};
export default TableDatePicker;
