import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../theme/Modals';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Preloader from '../../../global/Preloader';
import { DELETE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE } from '../../../../actions/delete';

const ModalDeleteMember = ({ show, hideModal, id, email, query, getVariables }) => {
    const classes = useStyles();

    const [setDeleteAccount, { loading }] = useMutation(DELETE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE);

    const onClickDelete = () => {
        setDeleteAccount({
            variables: {
                id,
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    if (loading) return <Preloader />;
    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">Delete member</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>
                            Are you sure that you want to delete {email} from Account ID {id}?
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            Cancel
                        </Button>
                        <Button className={classes.delete} onClick={onClickDelete}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalDeleteMember;
