import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    popover: {
        zIndex: 99999,
    },
    popoverHeader: {
        padding: 20,
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.light,
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    popoverError: {
        padding: '10px 20px',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    popoverBody: {
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    popoverItem: {
        width: '100%',
        padding: 0,
    },
    popoverMenuItem: {
        justifyContent: 'flex-start',
        width: '100%',
        padding: '5px 20px',
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        color: theme.palette.grey.dark,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },
    },
    link: {
        textDecoration: 'none',
    },
}));
