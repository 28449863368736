import React, { useContext, useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs';
import { AntContainerHead } from '../../theme/Wrapper';
import TabPanelLayout from '../../components/global/tab/TabPanelLayout';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import Resources from './resources';
import Anomalies from './anomalies';
import Mitigations from './mitigations';
import { PageContext } from '../../context/pages';

const NetworkProtection = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);
    const { tab } = useParams();
    const { deleteFilter } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <AntTabs value={indexTab} onChange={handleChange} className={classes.itemTab}>
                        <AntTab
                            component={NavLink}
                            label="resources"
                            {...a11yProps(0)}
                            to="/net-protection/0"
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            component={NavLink}
                            label="anomalies"
                            {...a11yProps(1)}
                            to="/net-protection/1"
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            component={NavLink}
                            label="mitigations"
                            {...a11yProps(2)}
                            to="/net-protection/2"
                            onClick={() => deleteFilter()}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <Resources />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <Anomalies />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={2}>
                <Mitigations />
            </TabPanelLayout>
        </>
    );
};
export default NetworkProtection;
