import React, { useContext, useEffect, useRef, useState } from 'react';
import { AntTableCell, AntTableCellFilter } from '../../theme/Table';
import { AntContainer } from '../../theme/Wrapper';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import TableScroll from '../../components/global/table/TableScroll';
import { textWidthCalculation } from '../../helpers/calculation';
import { PageContext } from '../../context/pages';
import Preloader from '../../components/global/Preloader';
import { useLazyQuery } from '@apollo/client';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES } from '../../actions/get';
import TableFilter from '../../components/global/table/TableFilter';
import TablePaginationComponent from '../../components/global/table/TablePagination';
import User from './User';
import TableSort from '../../components/global/table/TableSort';
import TableNoDataComponent from '../../components/global/table/TableNoData';

const Users = () => {
    const table = useRef(null);
    const [sortActive, setSortActive] = useState('sortId');
    const { filter, setFilter } = useContext(PageContext);

    const [getFilter, { data, loading }] = useLazyQuery(GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            email: filter?.email,
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);
        else if (filter?.partnerClientAccountId) proxy.partnerClientAccountId = Number(filter?.partnerClientAccountId);
        if (!filter?.sort?.checked) proxy.deletedAt = null;
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );

    if (loading) return <Preloader />;

    const maxWidth = {
        id: Math.max.apply(
            Math,
            data && data.partnerClientAccountEmployees.items.map(items => textWidthCalculation(items.id))
        ),
        email: Math.max.apply(
            Math,
            data && data.partnerClientAccountEmployees.items.map(items => textWidthCalculation(items.email))
        ),
        partnerClientAccountId: Math.max.apply(
            Math,
            data &&
                data.partnerClientAccountEmployees.items.map(items =>
                    textWidthCalculation(items.partnerClientAccountId)
                )
        ),
    };

    return (
        <AntContainer maxWidth={false}>
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1">Users</Typography>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <FormControlLabel
                                style={{ marginRight: 300 }}
                                control={
                                    <Checkbox
                                        checked={filter?.sort?.checked || false}
                                        onChange={event =>
                                            setFilter({
                                                ...filter,
                                                sort: { ...filter?.sort, checked: event.target.checked },
                                            })
                                        }
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Show deleted users"
                            />
                        </Grid>
                    </Grid>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>
                                    <TableSort
                                        name="sortId"
                                        direction={filter?.sort?.sortId}
                                        label="ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>Email</AntTableCell>
                                <AntTableCell>First Name</AntTableCell>
                                <AntTableCell>Last Name</AntTableCell>
                                <AntTableCell>Account ID</AntTableCell>
                                <AntTableCell>Created At</AntTableCell>
                                <AntTableCell>Status</AntTableCell>
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="id"
                                        maxWidth={maxWidth.id}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="email"
                                        setFilter={setFilter}
                                        filter={filter}
                                        maxWidth={maxWidth.email}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="partnerClientAccountId"
                                        setFilter={setFilter}
                                        filter={filter}
                                        maxWidth={maxWidth.partnerClientAccountId}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.partnerClientAccountEmployees.items.length !== 0 ? (
                                data.partnerClientAccountEmployees.items.map(
                                    ({
                                        id,
                                        name: { first, last },
                                        createdAt,
                                        deletedAt,
                                        email,
                                        partnerClientAccountId,
                                    }) => (
                                        <User
                                            key={id}
                                            id={id}
                                            first={first}
                                            last={last}
                                            createdAt={createdAt}
                                            deletedAt={deletedAt}
                                            email={email}
                                            partnerClientAccountId={partnerClientAccountId}
                                        />
                                    )
                                )
                            ) : (
                                <TableNoDataComponent colSpan={7} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.partnerClientAccountEmployees.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </AntContainer>
    );
};

export default Users;
