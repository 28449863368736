import React, { useState, useRef, useEffect, useContext } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table';
import {
  TableContainer,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import TableScroll from '../../../components/global/table/TableScroll';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  GET_ARBOR_RESOURCES,
  GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
} from '../../../actions/get';
import { SET_ACCESS } from '../../../actions/update';
import Preloader from '../../../components/global/Preloader';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/table/TableFilter';
import { toast } from 'react-toastify';
import { PageContext } from '../../../context/pages';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import TableSort from '../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const L3ProtectionAccountMembers = ({
  partnerClientAccountEmployeeId,
  partnerClientAccountId,
  email,
  role,
}) => {
  const classes = useStyles();
  const table = useRef(null);
  const { setSecondaryFilter, secondaryFilter } = useContext(PageContext);
  const [sortActive, setSortActive] = useState('sortId');

  const [setAccess] = useMutation(SET_ACCESS);
  const { data: access } = useQuery(GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS, {
    variables: {
      partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
    },
  });

  const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_RESOURCES);
  const getVariables = () => {
    const proxy = {
      limit: secondaryFilter.limit,
      page: secondaryFilter.page + 1,
      name: secondaryFilter?.name || '',
      partnerClientAccountId: Number(partnerClientAccountId),
      deletedAt: null,
      [sortActive]: secondaryFilter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
    };
    if (secondaryFilter?.id) proxy.id = Number(secondaryFilter?.id);
    return proxy;
  };

  useEffect(() => {
    getFilter({ variables: getVariables() });
  }, []);

  useDebouncedEffect(
    () => {
      getFilter({ variables: getVariables() });
    },
    1000,
    [secondaryFilter]
  );

  if (loading) return <Preloader />;

  const maxWidth = {
    id: Math.max.apply(
      Math,
      data && data.arborResources.items.map((items) => textWidthCalculation(items.id))
    ),
    name: Math.max.apply(
      Math,
      data && data.arborResources.items.map((items) => textWidthCalculation(items.name))
    ),
  };

  const pushItem = (event, index) => {
    const arr = [];
    arr.push(
      ...(access && access.partnerClientAccountEmployeeAccess?.[index]),
      Number(event.target.name)
    );
    return arr;
  };

  const returnItem = (event, index) => {
    const arr = [];
    arr.push(...(access && access.partnerClientAccountEmployeeAccess?.[index]));
    const position = arr.indexOf(Number(event.target.name));
    arr.splice(position, 1);
    return arr;
  };

  const handleChange = (event, index) => {
    const variables = event.target.checked
      ? {
          partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          [index]: pushItem(event, index),
        }
      : {
          partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          [index]: returnItem(event, index),
        };

    setAccess({
      variables: variables,
      refetchQueries: [
        {
          query: GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
          variables: {
            partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          },
        },
      ],
    })
      .then(() => toast.success('Success'))
      .catch((err) => toast.error(err.message));
  };

  const customData =
    data &&
    data.arborResources.items.map((item) => {
      return {
        ...item,
        l3Read: access && access.partnerClientAccountEmployeeAccess?.l3Read.includes(item.id),
      };
    });

  return (
    <>
      <TableScroll tableRef={table}>
        <TableContainer ref={table}>
          <Grid container>
            <Grid item xs>
              <Typography variant="h1" className={classes.titleHeader}>
                Access to network protection resources for {email}
              </Typography>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <AntTableCell>
                  <TableSort
                    name="sortId"
                    direction={secondaryFilter?.sort?.sortId}
                    label="ID"
                    setFilter={setSecondaryFilter}
                    sortActive={sortActive}
                    setSortActive={setSortActive}
                    filter={secondaryFilter}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCell>
                <AntTableCell>Resource Name</AntTableCell>
                <AntTableCell />
              </TableRow>
              <TableRow>
                <AntTableCellFilter>
                  <TableFilter
                    name="id"
                    setFilter={setSecondaryFilter}
                    filter={secondaryFilter}
                    maxWidth={maxWidth.id}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCellFilter>
                <AntTableCellFilter>
                  <TableFilter
                    name="name"
                    setFilter={setSecondaryFilter}
                    filter={secondaryFilter}
                    maxWidth={maxWidth.name}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCellFilter>
                <AntTableCellFilter />
              </TableRow>
            </TableHead>
            <TableBody>
              {customData && access && customData.length !== 0 ? (
                access.partnerClientAccountEmployeeAccess &&
                customData.map(({ id, name, l3Read }) => (
                  <TableRow hover key={id}>
                    <AntTableCell>{id}</AntTableCell>
                    <AntTableCell>{name}</AntTableCell>
                    <AntTableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ padding: '0 9px' }}
                            checked={l3Read}
                            onChange={(event) => {
                              handleChange(event, 'l3Read');
                            }}
                            name={`${id}`}
                            color="primary"
                          />
                        }
                        label="View"
                      />
                    </AntTableCell>
                  </TableRow>
                ))
              ) : (
                <TableNoDataComponent colSpan={3} />
              )}
            </TableBody>
          </Table>
          <TablePaginationComponent
            filter={secondaryFilter}
            setFilter={setSecondaryFilter}
            count={data && data.arborResources.info.totalCount}
            getFilter={getFilter}
            getVariables={getVariables()}
            type={'SECONDARY_FILTER'}
          />
        </TableContainer>
      </TableScroll>
    </>
  );
};
export default L3ProtectionAccountMembers;
