import { makeStyles } from '@material-ui/core/styles';
import bg from '../../../assets/img/bg-404.jpg';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '150px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: '#7B7B7B',
        marginBottom: '80px',
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '50px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: '#7B7B7B',
        marginBottom: '80px',
    },
    link: {
        display: 'block',
        textDecoration: 'none',
    },
}));
