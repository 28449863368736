import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    inputWrapper: {
        maxWidth: 550,
    },
    input: {
        width: '100%',
        marginBottom: 30,
    },
    buttonBox: {
        marginBottom: 100,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },

    title: {
        marginBottom: 30,
    },

    checkedBox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },
    switch: {
        margin: '0 50px',
    },
}));
