import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    bar: {
        background: theme.palette.navTab.bg,
        borderTopWidth: 0,
        borderBottomWidth: '1px',
        borderColor: theme.palette.navTab.border,
        borderBlockStyle: 'solid',
    },
    validation: {
        marginLeft: 30,
    },
    link: {
        textDecoration: 'none',
        color: '#363636',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
    },
    linkActive: {
        color: theme.palette.primary.main,
    },
    itemTab: {
        flex: '100%',
    },
    wrapper: {
        display: 'none',
        padding: '0 5px 0 14px',
        borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
    title: {
        paddingLeft: 20,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.6)',
    },
    icon: {
        color: theme.palette.grey.greyInactive,
        fontSize: '24px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

export const AntTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);

export const AntTab = withStyles(theme => ({
    root: {
        minWidth: 0,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        padding: '24px 15px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: '#363636',
        maxWidth: 500,
        '&:hover': {
            background: theme.palette.grey.greyLight,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    textColorInherit: {
        opacity: 1,
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);
