import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
        textAlign: 'center',
    },
    description: {
        padding: '0 60px',
        marginBottom: 30,
        textAlign: 'center',
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    form: {
        background: theme.palette.grey.backgroundMain,
    },
    input: {
        marginBottom: 20,
        width: '100%',
    },
    list: {
        marginBottom: 30,
        width: '100%',
    },
    listItem: {
        padding: '0',
    },
    listIcon: {
        marginRight: 15,
    },
    active: {
        color: theme.palette.grey.greyMediumEmphasis,
    },
    disable: {
        color: theme.palette.grey.greyDisable,
    },
    nextLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },
    link: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.grey.greyHighEmphasis,
        position: 'relative',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
        },
    },
    button: {
        marginBottom: 32,
        padding: '16px 0',
    },
}));
