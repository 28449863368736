import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(theme => ({
    modalWidth: {
        width: 700,
    },
    modalBody: {
        padding: '15px 15px 50px 24px',
    },
    scrollElement: {
        maxHeight: 500,
        overflowY: 'scroll',
    },
    overflow: {
        minHeight: 100,
    },
    tableCellHead: {
        borderBottom: 'none !important',
        paddingBottom: '0 !important',
        paddingTop: '0 !important',
    },
}));
