import React, { useContext } from 'react';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import User from './User';
import Password from './Password';
import { AuthContext } from '../../../context/auth';
import { GET_PARTNER_ACCOUNT_EMPLOYEE } from '../../../actions/get';
import { useQuery } from '@apollo/client';
import Preloader from '../../../components/global/Preloader';

const UserInfo = ({ token }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const { data, loading } = useQuery(GET_PARTNER_ACCOUNT_EMPLOYEE, {
    variables: {
      id: user && user.id,
    },
  });

  if (loading) return <Preloader />;

  return (
    <Grid container spacing={3} className={classes.wrapperContainers}>
      <Grid item xs={6}>
        <User user={user} data={data} query={GET_PARTNER_ACCOUNT_EMPLOYEE} />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Password user={user} data={data} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
