import React from 'react';
import Popover from '@material-ui/core/Popover';
import { useStyles } from './styles';
import { MenuItem, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { LOGIN_BY_EMPLOYEE } from '../../../actions/update';

const LoginEmployeePopover = ({ id, open, anchorEl, handleClose, data, type }) => {
    const classes = useStyles();
    const [loginByEmployee] = useMutation(LOGIN_BY_EMPLOYEE);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverHeader}>Log in</div>
                <div className={classes.popoverBody}>
                    {(data.length < 1 && <div className={classes.popoverError}>NO DATA</div>) ||
                        data.map(item =>
                            item.deletedAt === null ? (
                                <MenuItem className={classes.popoverMenuItem}>
                                    <Typography
                                        variant="inherit"
                                        onClick={event => {
                                            loginByEmployee({
                                                variables: {
                                                    type: type,
                                                    email: item.email,
                                                },
                                            }).then(r => {
                                                console.log(r);
                                                const win = window.open(
                                                    `${r.data.loginByEmployee.returnUrl}?tokenByEmployee=${r.data.loginByEmployee.accessToken}`,
                                                    '_blank'
                                                );
                                                win.focus();
                                            });
                                        }}
                                    >
                                        {item.email}
                                    </Typography>
                                </MenuItem>
                            ) : (
                                ''
                            )
                        )}
                </div>
            </div>
        </Popover>
    );
};

export default LoginEmployeePopover;
