import React from 'react';
import { TablePagination } from '@material-ui/core';

const TablePaginationComponent = ({ width, filter, setFilter, count = 0, getFilter, getVariables, type }) => {
    const handleChangePage = (event, newPage) => {
        setFilter({ ...filter, page: newPage }, type);
        getFilter && getFilter({ variables: { ...getVariables, limit: filter.limit, page: newPage + 1 } });
    };
    const handleChangeRowsPerPage = event => {
        setFilter({ ...filter, limit: parseInt(event.target.value, 10), page: 0 }, type);
        getFilter && getFilter({ variables: { ...getVariables, limit: parseInt(event.target.value, 10) } });
    };

    return (
        <TablePagination
            component="div"
            count={count}
            page={filter?.page}
            onChangePage={handleChangePage}
            rowsPerPage={filter?.limit}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
            labelRowsPerPage={width === 'xs' || width === 'sm' ? '' : 'Rows per page:'}
        />
    );
};

export default TablePaginationComponent;
