import React from 'react';
import { useStyles } from '../../theme/Chart';
import { valueCalculation } from '../../helpers/calculation';
import { Line } from 'react-chartjs-2';
import dateFormat from 'dateformat';

const AreaMitigationsStatistic = ({ timestamp, data, format, calcFormat }) => {
    const classes = useStyles();

    const schemeStatistic = {
        ratesTotal: {
            label: 'Rates Total',
            borderColor: '#9FA8DA',
            backgroundColor: 'rgba(159, 168, 218, 0.3)',
        },
        ratesHttp: {
            label: 'Rates Http',
            borderColor: '#eb4034',
            backgroundColor: 'rgba(235, 64, 52, 0.3)',
        },
        ratesFilter: {
            label: 'Rates Filter',
            borderColor: '#F4FA58',
            backgroundColor: 'rgba(244, 250, 88, 0.3)',
        },
        ratesProtocolBaseline: {
            label: 'Rates Protocol Baseline',
            borderColor: '#FE2E2E',
            backgroundColor: 'rgba(254, 46, 46, 0.3)',
        },
        ratesSource24Baseline: {
            label: 'Rates Source24 Baseline',
            borderColor: '#BDBDBD',
            backgroundColor: 'rgba(189, 189, 189, 0.3)',
        },
        ratesSynAuth: {
            label: 'Rates Syn Auth',
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.3)',
        },
        ratesDnsAuth: {
            label: 'Rates Dns Auth',
            borderColor: '#E0E0E0',
            backgroundColor: 'rgba(105, 240, 174, 0.3)',
        },
        ratesDnsMalformed: {
            label: 'Rates Dns Malformed',
            borderColor: '#FF6E40',
            backgroundColor: 'rgb(64, 196, 255, 0.3)',
        },
        ratesHttpRegex: {
            label: 'Rates Http Regex',
            borderColor: '#40C4FF',
            backgroundColor: 'rgba(105, 240, 174, 0.3)',
        },
        ratesDnsRegex: {
            label: 'Rates Dns Regex',
            borderColor: '#69F0AE',
            backgroundColor: 'rgb(64, 196, 255, 0.3)',
        },
        ratesIdleReset: {
            label: 'Rates Idle Reset',
            borderColor: '#69F0AE',
            backgroundColor: 'rgb(64, 196, 255, 0.6)',
        },
        ratesHttpMalformed: {
            label: 'Rates Http Malformed',
            borderColor: '#40C4FF',
            backgroundColor: 'rgba(105, 240, 174, 0.6)',
        },
        ratesSipRatelimit: {
            label: 'Rates Sip Rate limit',
            borderColor: '#FF6E40',
            backgroundColor: 'rgb(64, 196, 255, 0.6)',
        },
        ratesSipMalformed: {
            label: 'Rates Sip Malformed',
            borderColor: '#E0E0E0',
            backgroundColor: 'rgba(105, 240, 174, 0.6)',
        },
        ratesZombie: {
            label: 'Rates Zombie',
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.6)',
        },
        ratesRatelimit: {
            label: 'Rates Rate limit',
            borderColor: '#BDBDBD',
            backgroundColor: 'rgba(189, 189, 189, 0.6)',
        },
        ratesRegex: {
            label: 'Rates Regex',
            borderColor: '#FE2E2E',
            backgroundColor: 'rgba(254, 46, 46, 0.6)',
        },
        ratesInvalidPkts: {
            label: 'Rates Invalid Pkts',
            borderColor: '#F4FA58',
            backgroundColor: 'rgba(244, 250, 88, 0.6)',
        },
        ratesDnsRatelimit: {
            label: 'Rates Dns Rate limit',
            borderColor: '#9FA8DA',
            backgroundColor: 'rgba(159, 168, 218, 0.6)',
        },
        ratesDnsNxRatelimit: {
            label: 'Rates Dns Nx Rate limit',
            borderColor: '#9FA8DA',
            backgroundColor: 'rgba(159, 168, 218, 0.1)',
        },
        ratesGeoipFilter: {
            label: 'Rates Geo ip Filter',
            borderColor: '#F4FA58',
            backgroundColor: 'rgba(244, 250, 88, 0.7)',
        },
        ratesGeoipRatelimit: {
            label: 'Rates Geoip Rate limit',
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.1)',
        },
        ratesSrcnetFilter: {
            label: 'Rates Srcnet Filter',
            borderColor: '#E0E0E0',
            backgroundColor: 'rgba(105, 240, 174, 0.1)',
        },
        ratesConnlimit: {
            label: 'Rates Conn limit',
            borderColor: '#D358F7',
            backgroundColor: 'rgba(211, 88, 247, 0.1)',
        },
    };

    if (data?.arborMitigationStatistic.ratesTotal[`drop${format}`].length < 1) return <div className={classes.errorCharts}>NO DATA</div>;

    const customData =
        data &&
        Object.entries(data?.arborMitigationStatistic).reduce((acc, item) => {
            if (item[1][`drop${format}`]?.length >= 1 && item[0] !== 'ratesTotal') {
                return [
                    ...acc,
                    {
                        label: schemeStatistic[item[0]]?.label,
                        data: item[1][`drop${format}`],
                        backgroundColor: schemeStatistic[item[0]]?.backgroundColor,
                        borderWidth: 1,
                        radius: 0,
                        hoverRadius: 3,
                    },
                ];
            } else {
                return acc;
            }
        }, []);
    const series = {
        labels: timestamp,
        datasets: customData,
    };

    const options = {
        interaction: {
            mode: 'index',
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value) {
                        return valueCalculation(value, calcFormat);
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value), 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(Number(context[0].label), 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, calcFormat)}`;
                    },
                },
            },
        },
    };

    return (
        <div className={classes.chart}>
            <Line data={series} options={options} />
        </div>
    );
};

export default AreaMitigationsStatistic;
