import React, { useContext, useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs';
import { NavLink, useParams } from 'react-router-dom';
import { AntContainerHead } from '../../theme/Wrapper';
import TabPanelLayout from '../../components/global/tab/TabPanelLayout';
import L3ProtectionAccountMembers from './l3ProtectionAccountMembers';
import L7ProtectionAccountMembers from './l7ProtectionAccountMembers';
import { loadState } from '../../helpers/localStorage';
import { PageContext } from '../../context/pages';
import TabPanelBackLink from '../../components/global/tab/TabPanelBackLink';

const AccountMembersEdit = () => {
    const classes = useStyles();
    const { tab } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { deleteSecondaryFilter } = useContext(PageContext);

    const { partnerClientAccountEmployeeId, partnerClientAccountId, email, role } =
        loadState('settings-account-members');

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <TabPanelBackLink
                        to={`/edit-account/1/${partnerClientAccountId}`}
                        deleteFilter={deleteSecondaryFilter}
                        edit="Edit"
                        text="client account"
                    />
                    <AntTabs
                        value={indexTab}
                        onChange={handleChange}
                        className={classes.itemTab}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <AntTab
                            label="Network Protection"
                            {...a11yProps(0)}
                            to={`/settings-account-members/0/${partnerClientAccountEmployeeId}`}
                            component={NavLink}
                            onClick={() => deleteSecondaryFilter()}
                        />
                        <AntTab
                            label="Application Protection"
                            {...a11yProps(1)}
                            to={`/settings-account-members/1/${partnerClientAccountEmployeeId}`}
                            component={NavLink}
                            onClick={() => deleteSecondaryFilter()}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>

            <TabPanelLayout value={indexTab} index={0}>
                <L3ProtectionAccountMembers
                    partnerClientAccountEmployeeId={partnerClientAccountEmployeeId}
                    partnerClientAccountId={partnerClientAccountId}
                    email={email}
                    role={role}
                />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <L7ProtectionAccountMembers
                    partnerClientAccountEmployeeId={partnerClientAccountEmployeeId}
                    partnerClientAccountId={partnerClientAccountId}
                    email={email}
                    role={role}
                />
            </TabPanelLayout>
        </>
    );
};
export default AccountMembersEdit;
