import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MITIGATION_STATISTIC } from '../../../../../actions/get';
import Preloader from '../../../../../components/global/Preloader';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { AntTab, AntTabs } from '../../../anomalies/anomalyDetails/summary/styles';
import CardsHead from '../../../../../components/global/cards/CardsHead';
import TabPanel from '../../../../../components/global/tab/TabPanel';
import AreaMitigationsStatistic from '../../../../../components/charts/AreaMitigationsStatistic';
import AreaDropPass from '../../../../../components/charts/AreaDropPass';
import { useParams } from 'react-router';

const MitigationTrafficDetails = ({ mitigation }) => {
    const { id, start, mitigationId } = useParams();
    const classes = useStyles();

    const [indexTabMitigation, setIndexTabMitigation] = useState('_bps' ? 0 : 1);
    const [indexTabDropped, setIndexTabDropped] = useState('_bps' ? 0 : 1);
    const format = indexTab => (indexTab === 0 ? '_bps' : '_pps');
    const calcFormat = indexTab => (indexTab === 0 ? 'bps' : 'pps');

    const handleChangeMitigation = (event, newValue) => {
        setIndexTabMitigation(newValue);
    };
    const handleChangeDropped = (event, newValue) => {
        setIndexTabDropped(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const { data, loading, error } = useQuery(GET_MITIGATION_STATISTIC, {
        variables: {
            arborMitigationId: Number(id),
        },
    });

    if (loading) return <Preloader />;

    const timestamp = format => {
        const minute = 60000;
        const timestamp = [new Date(start).getTime()];
        for (let i = 0; i < data?.arborMitigationStatistic.ratesTotal[`drop${format}`].length - 1; i++) {
            const proxy = timestamp[i] + minute;
            timestamp.push(proxy);
        }
        return timestamp;
    };

    return (
        <>
            <div className={classes.wrapper}>
                <Grid container spacing={2} className={classes.headerWrapper}>
                    <Grid item xs={12} lg={2} className={classes.titleWrapper}>
                        <Typography variant="h6">Mitigation details</Typography>
                        <Typography variant="h6">(id {mitigationId})</Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <CardsHead title="Duration" data={mitigation?.duration} />
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <CardsHead title="Protected IP" data={mitigation?.prefix} />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <CardsHead title="Resource Name" data={mitigation?.managedObjectName} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.wrapperCharts}>
                {error ? (
                    <div className={classes.charts}>
                        <div className={classes.errorCharts}>
                            <Typography variant="h6">NO DATA</Typography>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={classes.charts} style={{ marginBottom: 5 }}>
                            <div className={classes.tabs}>
                                <div className={classes.cartTitle}>Mitigation traffic</div>
                                <AntTabs value={indexTabMitigation} onChange={handleChangeMitigation}>
                                    <AntTab label="BPS" {...a11yProps(0)} />
                                    <AntTab label="PPS" {...a11yProps(1)} />
                                </AntTabs>
                            </div>
                            <TabPanel value={indexTabMitigation} index={0}>
                                <AreaMitigationsStatistic
                                    timestamp={timestamp(format(indexTabMitigation))}
                                    calcFormat={calcFormat(indexTabMitigation)}
                                    format={format(indexTabMitigation)}
                                    data={data}
                                />
                            </TabPanel>
                            <TabPanel value={indexTabMitigation} index={1}>
                                <AreaMitigationsStatistic
                                    timestamp={timestamp(format(indexTabMitigation))}
                                    calcFormat={calcFormat(indexTabMitigation)}
                                    format={format(indexTabMitigation)}
                                    data={data}
                                />
                            </TabPanel>
                        </div>
                        <div className={classes.charts}>
                            <div className={classes.tabs}>
                                <div className={classes.cartTitle}>Dropped traffic by countermeasures</div>
                                <AntTabs value={indexTabDropped} onChange={handleChangeDropped}>
                                    <AntTab label="BPS" {...a11yProps(0)} />
                                    <AntTab label="PPS" {...a11yProps(1)} />
                                </AntTabs>
                            </div>
                            <TabPanel value={indexTabDropped} index={0}>
                                <AreaDropPass
                                    data={data}
                                    calcFormat={calcFormat(indexTabDropped)}
                                    format={format(indexTabDropped)}
                                    timestamp={timestamp(format(indexTabDropped))}
                                />
                            </TabPanel>
                            <TabPanel value={indexTabDropped} index={1}>
                                <AreaDropPass
                                    data={data}
                                    calcFormat={calcFormat(indexTabDropped)}
                                    format={format(indexTabDropped)}
                                    timestamp={timestamp(format(indexTabDropped))}
                                />
                            </TabPanel>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MitigationTrafficDetails;
