import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    itemWrapper: {
        minWidth: '430px',
    },
    titleWrapper: {
        borderBottom: '2px solid #F6F7FB',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    title: {
        color: theme.palette.grey.greyHighEmphasis,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        padding: '11px 15px',
        whiteSpace: 'nowrap',
        backgroundColor: '#F6F7FB',
        maxWidth: 200,
        borderRadius: '8px 8px 0px 0px',
    },
    tableCellHead: {
        borderBottom: 'none !important',
        paddingBottom: '0 !important',
    },
    noDataText: {
        padding: '10px 10px 10px 15px',
        color: 'rgb(0 0 0 / 36%)',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        minHeight: 22,
    },
    button: {
        textTransform: 'none',
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '21px',
        letterSpacing: '0.25px',
    },
}));
