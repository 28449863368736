import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const propTypes = {
    tableRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
    children: PropTypes.node.isRequired,
};

const displayName = 'TableScrollComponent';

const TableScroll = props => {
    const { tableRef, children } = props;
    const outer = useRef(null);
    const [value, setValue] = useState(0);
    const [translate, setTranslate] = useState(0);
    const classes = useStyles({ translate });

    const handleResize = useCallback(() => {
        setValue(0);
        setTranslate(0);
    }, [setValue, setTranslate]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div ref={outer} className={classes.outer}>
                {children}
            </div>
        </>
    );
};

TableScroll.displayName = displayName;
TableScroll.propTypes = propTypes;

export default TableScroll;
