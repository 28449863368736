import React from 'react';
import { useStyles } from './styles';
import Logo from '../../../components/global/Logo';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Link } from 'react-router-dom';
import routes from '../../../routes';

const JoinAccountDisabledToken = () => {
    const classes = useStyles();

    return (
        <AuthWrapper>
            <Logo />
            <div className={classes.title}>Join account</div>
            <div className={classes.description}>The invite link is no longer active!</div>
            <div className={classes.text}>
                Most likely you have already used it and you already have an account in our system. If this is not the
                case, please contact our support.
            </div>
            <Link to={routes.auth} className={classes.link}>
                GO TO LOG IN
            </Link>
        </AuthWrapper>
    );
};

export default JoinAccountDisabledToken;
