import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapperContainers: {
        marginBottom: 30,
    },
    wrapper: {
        padding: 25,
    },
    containerUser: {
        height: '100%',
    },
    containerVerify: {
        minHeight: '100%',
    },
}));
