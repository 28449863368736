import { gql } from 'graphql-tag';

export const DELETE_PARTNER_ACCOUNT_EMPLOYEE = gql`
    mutation deletePartnerAccountEmployee($id: Float!) {
        deletePartnerAccountEmployee(id: $id) {
            deletedAt
            id
        }
    }
`;

export const DELETE_PARTNER_CLIENT_ACCOUNT = gql`
    mutation deletePartnerClientAccount($id: Float!) {
        deletePartnerClientAccount(id: $id) {
            deletedAt
            id
        }
    }
`;

export const DELETE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE = gql`
    mutation deletePartnerClientsAccountEmployee($id: Float!) {
        deletePartnerClientsAccountEmployee(id: $id) {
            deletedAt
            id
        }
    }
`;
