import React from 'react';
import { TableRow } from '@material-ui/core';
import { AntTableCell, useStyles } from '../../../../theme/Table';
import { valueTimeCalculation } from '../../../../helpers/calculation';
import dateFormat from 'dateformat';
import { Description } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const Mitigation = ({ id, mitigationId, arborAlertId, prefix, managedObjectName, start, stop, duration }) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {mitigationId}
            </AntTableCell>
            <AntTableCell>{arborAlertId}</AntTableCell>
            <AntTableCell>{prefix}</AntTableCell>
            <AntTableCell>{managedObjectName}</AntTableCell>
            <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
            <AntTableCell>
                {stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}
            </AntTableCell>
            <AntTableCell>
                <Link to={`/mitigations-details/net-protection/${start}/${mitigationId}/${id}/2`}>
                    <Description color="action" className={classes.icon} />
                </Link>
            </AntTableCell>
        </TableRow>
    );
};

export default Mitigation;
