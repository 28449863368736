export const valueCalculation = (value, format) => {
    if (value >= 1000000000) {
        return +(value / 1000000000).toFixed(2) + ` G${format}`;
    } else if (value >= 1000000) {
        return +(value / 1000000).toFixed(2) + ` M${format}`;
    } else if (value >= 1000) {
        return +(value / 1000).toFixed(2) + ` K${format}`;
    } else {
        return value + ` ${format}`;
    }
};

export const simpleValueCalculation = value => {
    if (value >= 1000000000) {
        return +(value / 1000000000).toFixed(2) + 'G';
    } else if (value >= 1000000) {
        return +(value / 1000000).toFixed(2) + 'M';
    } else if (value >= 1000) {
        return +(value / 1000).toFixed(2) + 'K';
    } else {
        return value;
    }
};

export const valueTimeCalculation = value => {
    let proxy;
    const d = Math.floor(value / (3600*24));
    const h = Math.floor(value % (3600*24) / 3600);
    const m = Math.floor(value % 3600 / 60);
    const s = Math.floor(value % 60);

    const formsTime = num => {
        const resultNum = num < 10 ? `0${num}` : num;
        return resultNum;
    };

    if(value >= 86400) return (proxy = `${d} d ${h} h ${formsTime(m)}:${formsTime(s)} min`);
    else if (value >= 3600) return (proxy = `${h} h ${formsTime(m)}:${formsTime(s)} min`);
    else if (value >= 60) return (proxy = `${m}:${formsTime(s)} min`);
    else if (60 >= value) return (proxy = `${s} sec`);
};

export const updateSortDirection = value => {
    const order = value === 'asc' ? 'desc' : value === 'desc' ? 'asc' : 'asc';
    return order;
};

export const dateTabsCalculation = index => {
    const dateNow = new Date();
    if (index === 0) {
        return dateNow.setDate(dateNow.getDate() - 1);
    } else if (index === 1) {
        return dateNow.setDate(dateNow.getDate() - 7);
    } else if (index === 2) {
        return dateNow.setMonth(dateNow.getMonth() - 1);
    }
};

export const textWidthCalculation = text => {
    const canvas = textWidthCalculation.canvas || (textWidthCalculation.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = '500 14px/1.43 "Roboto", sans-serif';
    const metrics = context.measureText(text);
    return metrics.width;
};

export const percentCalculation = (a, b) => {
    const number = (a / b) * 100;
    return +number.toFixed(2);
};
