import React from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table/index';
import { Table, TableBody, TableHead, TableRow, Button } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { textWidthCalculation } from '../../../../helpers/calculation';
import TableFilter from '../../../../components/global/table/TableFilter';
import dateFormat from 'dateformat';
import TableSort from '../../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../../components/global/table/TableNoData';

const TableL3Resources = ({ data, filter, setFilter, setAnchorEl, setIpsPopover, sortActive, setSortActive }) => {
    const classes = useStyles();

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.name))),
        ips: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.ips[0]))),
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell>
                            <TableSort
                                name="sortId"
                                direction={filter?.sort?.sortId}
                                label="ID"
                                setFilter={setFilter}
                                sortActive={sortActive}
                                setSortActive={setSortActive}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell>Resource Name</AntTableCell>
                        <AntTableCell>IPs</AntTableCell>
                        <AntTableCell>
                            <TableSort
                                name="sortCreatedAt"
                                direction={filter?.sort?.sortCreatedAt}
                                label="Created At"
                                setFilter={setFilter}
                                sortActive={sortActive}
                                setSortActive={setSortActive}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter
                                name="id"
                                setFilter={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                                maxWidth={maxWidth.id}
                            />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter
                                name="name"
                                setFilter={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                                maxWidth={maxWidth.name}
                            />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.arborResources.items.length !== 0 ? (
                        data.arborResources.items.map(({ id, name, ips, createdAt }) => (
                            <TableRow hover key={id}>
                                <AntTableCell component="th" scope="row">
                                    {id}
                                </AntTableCell>
                                <AntTableCell>{name}</AntTableCell>
                                <AntTableCell>
                                    {ips.length === 1 ? (
                                        ips[0]
                                    ) : ips.length === 0 ? (
                                        '-'
                                    ) : (
                                        <div>
                                            {ips[0]}
                                            <Button
                                                className={classes.buttonMore}
                                                aria-describedby={id}
                                                onClick={event => {
                                                    setAnchorEl(event.currentTarget);
                                                    setIpsPopover(ips);
                                                }}
                                            >
                                                <span className={classes.buttonMoreSpan}>
                                                    <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" />{' '}
                                                    more
                                                </span>
                                            </Button>
                                        </div>
                                    )}
                                </AntTableCell>
                                <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ss TT')}</AntTableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableNoDataComponent colSpan={4} />
                    )}
                </TableBody>
            </Table>
        </>
    );
};
export default TableL3Resources;
