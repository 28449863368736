import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { CustomSwitchOnOff } from '../../../theme/Switch';
import { Typography, TableContainer } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import TableL3Resources from './TableL3Resources';
import TableScroll from '../../../components/global/table/TableScroll';
import ModalMore from '../../../components/modals/modalsTable/more';
import { PageContext } from '../../../context/pages';
import { GET_ARBOR_RESOURCES, GET_PARTNER_CLIENT_ACCOUNT } from '../../../actions/get';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import Preloader from '../../../components/global/Preloader';
import { useLazyQuery, useMutation } from '@apollo/client';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import { toast } from 'react-toastify';
import { UPDATE_ACCOUNT_SETTINGS } from '../../../actions/update';

const L3ProtectionAccount = ({ dataPartnerClientAccount }) => {
    const classes = useStyles();
    const table = useRef(null);

    const { id: partnerClientAccountId } = useParams();
    const { filter, setFilter } = useContext(PageContext);
    const [sortActive, setSortActive] = useState('sortId');

    const [switches, setSwitches] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [ipsPopover, setIpsPopover] = useState([]);

    const [updateAccountSettings] = useMutation(UPDATE_ACCOUNT_SETTINGS);

    const update = dataInput => {
        updateAccountSettings({
            variables: {
                id: dataPartnerClientAccount.accountSettings.id,
                l3Limit: Number(dataInput),
                l7Limit: dataPartnerClientAccount.accountSettings.l7.l7Limit,
                dosGateLimit: dataPartnerClientAccount.accountSettings.dosGate.dosGateLimit,
            },
            refetchQueries: [
                {
                    query: GET_PARTNER_CLIENT_ACCOUNT,
                    variables: {
                        id: dataPartnerClientAccount.id,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_RESOURCES);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            deletedAt: null,
            name: filter?.name || '',
            partnerClientAccountId: Number(partnerClientAccountId),
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);

        return proxy;
    };

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables(), fetchPolicy: 'network-only' });
        },
        500,
        [filter]
    );

    useEffect(() => {
        if (dataPartnerClientAccount && dataPartnerClientAccount.accountSettings.l3.l3Limit >= 1) {
            setSwitches(true);
        } else {
            setSwitches(false);
        }
    }, [dataPartnerClientAccount]);

    if (loading) return <Preloader />;

    const handleChange = event => {
        setSwitches(event.target.checked);
        if (event.target.checked === true) {
            update(1);
        } else {
            update(0);
        }
    };

    const open = Boolean(anchorEl);
    const idPopover = open ? 'popover-more' : undefined;

    return (
        <>
            <ModalMore
                id={idPopover}
                open={open}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                value={ipsPopover}
                title="Ips"
            />
            <Typography variant="h1" className={classes.title}>
                Network protection service
            </Typography>
            <div className={classes.checkedBox}>
                <Typography>Network protection service visibility</Typography>
                <CustomSwitchOnOff
                    name="l3service"
                    checked={switches}
                    onChange={handleChange}
                    className={classes.switch}
                />
            </div>
            <div>
                <TableScroll tableRef={table}>
                    <TableContainer>
                        <TableL3Resources
                            data={data}
                            setFilter={setFilter}
                            filter={filter}
                            setAnchorEl={setAnchorEl}
                            setIpsPopover={setIpsPopover}
                            sortActive={sortActive}
                            setSortActive={setSortActive}
                        />
                        <TablePaginationComponent
                            filter={filter}
                            setFilter={setFilter}
                            count={data && data.arborResources.info.totalCount}
                            getFilter={getFilter}
                            getVariables={getVariables()}
                            type={'FILTER_ACTIVE'}
                        />
                    </TableContainer>
                </TableScroll>
            </div>
        </>
    );
};
export default L3ProtectionAccount;
