import React, { useState, useRef, useEffect, useContext } from 'react';
import { AntTableCell, useStyles } from '../../../theme/Table';
import { Button, TableContainer, Typography, Grid, TableRow, Table, TableBody, TableHead } from '@material-ui/core';
import TableScroll from '../../../components/global/table/TableScroll';
import ModalInviteMember from '../../../components/modals/modalsMyProfile/inviteMember';
import ModalDeleteMember from '../../../components/modals/modalsMyProfile/deleteMember';
import { GET_PARTNER_ACCOUNT_EMPLOYEES } from '../../../actions/get';
import Preloader from '../../../components/global/Preloader';
import { useLazyQuery } from '@apollo/client';
import { PageContext } from '../../../context/pages';
import { AuthContext } from '../../../context/auth';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import Member from './Member';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const Members = () => {
    const classes = useStyles();
    const table = useRef(null);

    const { user } = useContext(AuthContext);
    const { filter, setFilter } = useContext(PageContext);

    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
        id: '',
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_PARTNER_ACCOUNT_EMPLOYEES);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
        };
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    if (loading) return <Preloader />;

    return (
        <>
            <ModalInviteMember
                show={isCreated}
                hideModal={() => setIsCreated(false)}
                query={GET_PARTNER_ACCOUNT_EMPLOYEES}
                getVariables={getVariables()}
                partnerAccountId={user && user.partnerAccountId}
                type={user && user.type}
            />
            <ModalDeleteMember
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                id={isDeleted.id}
                query={GET_PARTNER_ACCOUNT_EMPLOYEES}
                getVariables={getVariables()}
            />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1">Account Members</Typography>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => setIsCreated(true)}
                            >
                                invite member
                            </Button>
                        </Grid>
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>ID</AntTableCell>
                                <AntTableCell>Name</AntTableCell>
                                <AntTableCell>Email</AntTableCell>
                                <AntTableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.partnerAccountEmployees.items.length !== 0 ? (
                                data.partnerAccountEmployees.items.map(({ id, name: { first, last }, email }) => (
                                    <Member
                                        id={id}
                                        key={id}
                                        first={first}
                                        last={last}
                                        email={email}
                                        setIsDeleted={setIsDeleted}
                                    />
                                ))
                            ) : (
                                <TableNoDataComponent colSpan={4} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.partnerAccountEmployees.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </>
    );
};
export default Members;
