import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: 400,
    },
    input: {
        marginBottom: 5,
        width: '100%',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        padding: '15px 30px',
        marginTop: 22,
    },
}));
