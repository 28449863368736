import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    Chip,
} from '@material-ui/core';
import TableScroll from '../../../components/global/table/TableScroll';
import { useParams } from 'react-router';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/table/TableFilter';
import ModalInviteMember from '../../../components/modals/modalsAccountEdit/inviteMember';
import ModalDeleteMember from '../../../components/modals/modalsAccountEdit/deleteMember';
import AccountMembersPopover from './popoverBar';
import { MoreHoriz } from '@material-ui/icons';
import { FormControl, Select } from '@material-ui/core';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import {
    GET_INVITATION_LINK,
    GET_PARTNER_CLIENT_ACCOUNTS,
    GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES,
} from '../../../actions/get';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE } from '../../../actions/update';
import { toast } from 'react-toastify';
import ModalInvitationLink from '../../../components/modals/modalsAccountEdit/invitationLink';
import TableSort from '../../../components/global/table/TableSort';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const MembersAccount = () => {
    const classes = useStyles();
    const table = useRef(null);

    const { id: partnerClientAccountId } = useParams();
    const [index, setIndex] = useState({});
    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
        email: '',
        partnerClientAccountEmployeeId: '',
    });
    const [isShowLink, setIsShowLink] = useState({
        show: false,
    });

    const [getInvitationLink, { data: dataInvitationLink }] = useLazyQuery(GET_INVITATION_LINK, {
        onError: err => toast.error(err.message),
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [sortActive, setSortActive] = useState('sortId');
    const { filter, setFilter } = useContext(PageContext);

    const [setRole] = useMutation(UPDATE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE);

    const handleChange = (event, id) => {
        setRole({
            variables: {
                id: Number(id),
                role: event.target.value,
            },
            refetchQueries: [
                {
                    query: GET_PARTNER_CLIENT_ACCOUNTS,
                    variables: {
                        limit: filter.limit,
                        page: filter.page + 1,
                    },
                },
                {
                    query: GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES,
                    variables: {
                        partnerClientAccountId: Number(partnerClientAccountId),
                    },
                },
            ],
        })
            .then(() => {
                toast.success('Role changed');
            })
            .catch(err => toast.error(err.message));
    };

    const [getFilter, { data, loading }] = useLazyQuery(GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            email: filter?.email,
            partnerClientAccountId: Number(partnerClientAccountId),
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);
        if (!filter?.sort?.checked) proxy.deletedAt = null;
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );

    if (loading) return <Preloader />;

    const maxWidth = {
        id: Math.max.apply(
            Math,
            data && data.partnerClientAccountEmployees.items.map(items => textWidthCalculation(items.id))
        ),
        email: Math.max.apply(
            Math,
            data && data.partnerClientAccountEmployees.items.map(items => textWidthCalculation(items.email))
        ),
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'accountList' : undefined;

    return (
        <>
            <ModalInviteMember
                show={isCreated}
                hideModal={() => setIsCreated(false)}
                partnerClientAccountId={partnerClientAccountId}
                query={GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES}
                getVariables={getVariables()}
            />
            <ModalDeleteMember
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                email={isDeleted.email}
                id={isDeleted.id}
                query={GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES}
                getVariables={getVariables()}
            />
            <ModalInvitationLink
                show={isShowLink.show}
                hideModal={() => setIsShowLink(!isShowLink.show)}
                link={dataInvitationLink && dataInvitationLink.getInvitationLink.invitationLink}
            />

            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <div className={classes.header}>
                        <Typography variant="h1">Members</Typography>
                        <div>
                            <FormControlLabel
                                style={{ marginRight: 40 }}
                                control={
                                    <Checkbox
                                        checked={filter?.sort?.checked || false}
                                        onChange={event =>
                                            setFilter({
                                                ...filter,
                                                sort: { ...filter?.sort, checked: event.target.checked },
                                            })
                                        }
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Show deleted members"
                            />
                            <Button color="primary" variant="contained" onClick={() => setIsCreated(true)}>
                                invite member
                            </Button>
                        </div>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>
                                    <TableSort
                                        name="sortId"
                                        direction={filter?.sort?.sortId}
                                        label="ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>Email</AntTableCell>
                                <AntTableCell>First name</AntTableCell>
                                <AntTableCell>Last name</AntTableCell>
                                <AntTableCell>Role</AntTableCell>
                                <AntTableCell>Created At</AntTableCell>
                                <AntTableCell>Status</AntTableCell>
                                <AntTableCell />
                                <AntTableCell />
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="id"
                                        maxWidth={maxWidth.id}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="email"
                                        maxWidth={maxWidth.email}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.partnerClientAccountEmployees.items.length !== 0 ? (
                                data.partnerClientAccountEmployees.items.map(
                                    ({ id, email, createdAt, name: { first, last }, role, status }) => (
                                        <TableRow hover key={id}>
                                            <AntTableCell>{id}</AntTableCell>
                                            <AntTableCell>{email}</AntTableCell>
                                            <AntTableCell>{first}</AntTableCell>
                                            <AntTableCell>{last}</AntTableCell>
                                            <AntTableCell style={{ padding: '0 14px' }}>
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    style={{ padding: 0, maxWidth: 150 }}
                                                >
                                                    <Select
                                                        native
                                                        defaultValue={role === 'MANAGER' ? 'READ_ONLY' : role}
                                                        inputProps={{
                                                            name: 'name',
                                                            id: 'uncontrolled-native',
                                                        }}
                                                        onChange={event => handleChange(event, id)}
                                                    >
                                                        <option value={'ADMIN'}>Administrator</option>
                                                        <option value={'ENGINEER'}>Engineer</option>
                                                        <option value={'READ_ONLY'}>Read only</option>
                                                    </Select>
                                                </FormControl>
                                            </AntTableCell>
                                            <AntTableCell>{createdAt}</AntTableCell>
                                            <AntTableCell>{status}</AntTableCell>
                                            <AntTableCell>
                                                {status !== 'ACTIVE' && (
                                                    <Chip
                                                        label="show link"
                                                        style={{
                                                            color: '#2196F3',
                                                            background: '#E3F2FD',
                                                            padding: '0 10px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setIsShowLink({
                                                                show: true,
                                                            });
                                                            getInvitationLink({
                                                                variables: {
                                                                    partnerClientAccountEmployeeId: id,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </AntTableCell>

                                            <AntTableCell align="right">
                                                <MoreHoriz
                                                    className={classes.icon}
                                                    aria-describedby={id}
                                                    variant="contained"
                                                    onClick={e => {
                                                        handleClick(e);
                                                        setIndex({
                                                            partnerClientAccountEmployeeId: id,
                                                            email,
                                                            partnerClientAccountId: partnerClientAccountId,
                                                            role,
                                                        });
                                                    }}
                                                />
                                            </AntTableCell>
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableNoDataComponent colSpan={10} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.partnerClientAccountEmployees.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
            <AccountMembersPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                setIsDeleted={setIsDeleted}
                index={index}
            />
        </>
    );
};

export default MembersAccount;
