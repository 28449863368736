import { makeStyles } from '@material-ui/core/styles';
import { InputBase, withStyles, Select, MenuItem } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.header.border,
        padding: 0,
        background: theme.palette.header.bg,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '21px',
    },
    menuHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    menuLink: {
        padding: '24px 21px',
        color: '#363636',
        textDecoration: 'none',
        textTransform: 'none',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: '14px',
        letterSpacing: '0.25px',
        lineHeight: '21px',
        boxSizing: 'border-box',
        '&:hover': {
            backgroundColor: theme.palette.grey.greyLight,
        },
    },
    menuLinkActive: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    imgBoxLogo: {
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 32,
    },
    imgBoxUser: {
        cursor: 'pointer',
        padding: '10px 0 10px 15px',
        display: 'flex',
        alignItems: 'center',
    },
    headerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    avatar: {
        border: '1px solid #01CDCB',
        backgroundColor: 'transparent !important',
        color: '#BDBDBD !important',
        marginRight: 20,
    },
}));

export const CustomInput = withStyles(theme => ({
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '21px',
        zIndex: 999,
        '&:focus': {
            borderRadius: 0,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
        },
    },
}))(InputBase);

export const CustomSelect = withStyles(theme => ({
    select: {
        '&.MuiSelect-select': {
            paddingRight: 14,
        },
    },
}))(Select);

export const CustomMenuItem = withStyles(theme => ({
    root: {
        padding: '9px 20px',
        borderRadius: 4,
        margin: '0 8px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
