import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { BaseButton } from '../../theme/Button';
import { useStyles } from './styles';

const NotFound = () => {
    const classes = useStyles();
    return (
        <div
            className={classes.wrapper}
            display="flex"
            flexDirection="column "
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Typography className={classes.title}>404</Typography>
            <Typography className={classes.text}>Page not found</Typography>
            <Link to={routes.auth} className={classes.link}>
                <BaseButton type="submit" color="primary" variant="contained" size="large">
                    GO back home
                </BaseButton>
            </Link>
        </div>
    );
};

export default NotFound;
