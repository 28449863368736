import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, TextField, Button, Typography, ButtonGroup } from '@material-ui/core';
import { BaseButton } from '../../../../theme/Button';
import { useStyles } from '../../../../theme/Modals';
import { toast } from 'react-toastify';

const ModalInvitationLink = ({ show, hideModal, link }) => {
    const classes = useStyles();

    const copy = (event, link) => {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                toast.success('Copied');
                hideModal();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal} style={{ width: 700 }}>
                    <div className={classes.header}>
                        <Typography variant="h1">Invitation link</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <ButtonGroup disableElevation variant="contained" color="primary" style={{ width: '100%' }}>
                            <TextField value={link} variant="outlined" />
                            <BaseButton
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={event => {
                                    copy(event, link);
                                }}
                            >
                                COPY
                            </BaseButton>
                        </ButtonGroup>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalInvitationLink;
