export const logoAvatar = (firstName, lastName) => {
    if (firstName && lastName) {
        const nameLogo = `${[...firstName[0][0]]}${[...lastName[0][0]]}`;
        return nameLogo.toUpperCase();
    }
    if (firstName) {
        const first = `${[...firstName[0][0]]}`;
        return first.toUpperCase();
    }
    if (lastName) {
        const last = `${[...lastName[0][0]]}`;
        return last.toUpperCase();
    }
};
