import React from 'react';
import { AntTableCell, useStyles } from '../../../theme/Table';
import dateFormat from 'dateformat';
import { ExitToApp, MoreHoriz } from '@material-ui/icons';
import { TableRow } from '@material-ui/core';

const Account = ({
    id,
    name,
    createdAt,
    deletedAt,
    partnerClientAccountEmployees,
    setEmails,
    setAnchorElPopover,
    handleClick,
    setIndex,
}) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {id}
            </AntTableCell>
            <AntTableCell>{name}</AntTableCell>
            <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ss TT')}</AntTableCell>
            <AntTableCell>{deletedAt ? 'Deleted' : 'Active'}</AntTableCell>
            <AntTableCell style={{ width: 80 }}>
                <ExitToApp
                    className={classes.icon}
                    style={{ marginRight: 25 }}
                    onClick={event => {
                        setEmails(partnerClientAccountEmployees);
                        setAnchorElPopover(event.currentTarget);
                    }}
                />
                <MoreHoriz
                    className={classes.icon}
                    aria-describedby={id}
                    variant="contained"
                    onClick={e => {
                        handleClick(e);
                        setIndex({ id, name });
                    }}
                />
            </AntTableCell>
        </TableRow>
    );
};

export default Account;
