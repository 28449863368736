import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LinearProgress, TableCell } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    button: {
        padding: '7px 16px',
    },
    icon: {
        color: '#363636',
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: '22px',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconDelete: {
        color: '#363636',
        cursor: 'pointer',
        verticalAlign: 'middle',
        fontSize: '22px',
        '&:hover': {
            color: theme.palette.error.main,
        },
    },
    iconPowerOff: {
        color: 'gba(0, 0, 0, 0.54)',
        cursor: 'pointer',
        fontSize: '22px',
        verticalAlign: 'middle',
    },
    iconPowerOn: {
        color: '#2196F3',
        cursor: 'pointer',
        fontSize: '22px',
        verticalAlign: 'middle',
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
    },
    dateTitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.6)',
        marginRight: 10,
    },
    buttonMore: {
        textTransform: 'lowercase',
        color: theme.palette.primary.primary300,
        fontSize: '8px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '7px',
        letterSpacing: '0.25px',
        padding: 0,
        minWidth: 0,
    },
    buttonMoreSpan: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonMoreIcon: {
        fontSize: '15px',
    },
    btnFilter: {
        fontSize: '10px',
        width: '50px',
        height: '29px',
        letterSpacing: 0.25,
        borderColor: theme.palette.grey.grey500,
        color: theme.palette.grey.grey500,
        background: theme.palette.grey.greyLight,
        '&:hover': {
            color: theme.palette.primary.grey500,
            background: theme.palette.grey.greyLight,
            borderColor: theme.palette.primary.grey500,
        },
    },
    switcher: {
        display: 'flex',
        alignItems: 'center',
    },
    linear: {
        minWidth: '70px',
    },
    comment: {
        display: 'flex',
        justifyContent: 'space-between',
        textOverflow: 'fade(10px) fade(10px)',
        '& span': {
            marginRight: '20px',
        },
    },
    errorCharts: {
        fontSize: 25,
    },
}));

export const AntTableCellFilter = withStyles({
    root: {
        whiteSpace: 'nowrap',
        padding: '9px 10px',
        borderBottom: '1px solid transparent',
        '&.MuiTableCell-sizeSmall': {
            '&:last-child': {
                paddingRight: 16,
            },
            '&:first-child': {
                paddingLeft: 16,
            },
        },
    },
})(TableCell);

export const AntTableCell = withStyles(theme => ({
    root: {
        whiteSpace: 'nowrap',
        padding: '12.5px 10px',
        borderBottom: '1px solid transparent',
    },
    head: {
        color: theme.palette.grey.grey500,
    },
    body: {
        color: '#363636',
    },
}))(TableCell);

export const AntTableCellWhite = withStyles(theme => ({
    head: {
        backgroundColor: '#FFFFFF',
    },
}))(AntTableCell);

export const AntTableCellGrey = withStyles(theme => ({
    head: {
        backgroundColor: '#FAFAFA',
    },
}))(AntTableCell);

export const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bar: {
        backgroundColor: theme.palette.common.white,
    },
}))(LinearProgress);
