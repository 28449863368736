import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../theme/Modals';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_L7_RESOURCE } from '../../../../actions/update';

const ModalSwitchOffResource = ({ show, hideModal, l7ResourceId, name, query, getVariables, partnerClientAccountId }) => {
    const classes = useStyles();

    const [updateL7Resource] = useMutation(UPDATE_L7_RESOURCE);

    const onClickOffResource = () => {
        updateL7Resource({
            variables: {
                serviceEnable: 0,
                partnerClientAccountId: Number(partnerClientAccountId),
                serviceId: Number(l7ResourceId), // используется l7ResourceId, т.к. l7ResourceId === serviceId, и для общей таблицы нету фильтрации по полю serviceId
                
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">Switch off resource</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>
                            Are you sure that you want to switch off the resource id {l7ResourceId} ({name})? After you confirm, we will stop serving traffic for
                            this resource but all resource settings will store and you can activate the resource as you wish!
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            Cancel
                        </Button>
                        <Button className={classes.delete} onClick={onClickOffResource}>
                            Switch off
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalSwitchOffResource;
