import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Typography, Popover } from '@material-ui/core';
import { useStyles } from '../../../theme/PopoverBar';

const AccountPopover = ({ id, open, anchorEl, handleClose, setIsDeleted, idAccount, name, type }) => {
    const classes = useStyles();

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverBody}>
                    <div
                        className={classes.popoverItem}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <Link to={`/edit-account/0/${idAccount}`} className={classes.link}>
                            <MenuItem className={`${classes.popoverMenuItem} ${classes.item}`}>
                                <Typography variant="inherit">EDIT</Typography>
                            </MenuItem>
                        </Link>
                    </div>

                    <div
                        className={classes.popoverItem}
                        onClick={() => {
                            handleClose();
                            setIsDeleted({
                                deleted: true,
                                id: idAccount,
                                name: name,
                            });
                        }}
                    >
                        <MenuItem className={`${classes.popoverMenuItem} ${classes.itemDelete}`}>
                            <Typography variant="inherit" color="error">
                                Delete
                            </Typography>
                        </MenuItem>
                    </div>
                </div>
            </div>
        </Popover>
    );
};

export default AccountPopover;
