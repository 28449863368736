import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: 30,
    },
    cardsHeaderWrapper: {
        display: 'flex',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tablesWrapper: {
        marginTop: 40,
        padding: 10,
    },
}));
