import React from 'react';
import { useStyles } from './styles';
import { Container, Grid, Typography } from '@material-ui/core';

const AuthWrapper = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.bg}>
            <Container>
                <Grid container className={classes.wrapper}>
                    <Grid item xs={12} className={classes.formWrapper}>
                        {children}
                    </Grid>
                </Grid>

                <Typography align="center" className={classes.copyright}>
                    Copyright ©2023 Servicepipe
                </Typography>
            </Container>
        </div>
    );
};

export default AuthWrapper;
