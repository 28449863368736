import React, { useState } from 'react';
import { useStyles } from './styles';
import { TextField, InputAdornment, IconButton, Button, Grid, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff, Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ListPassword from './ListPassword';
import { SET_PASSWORD } from '../../../../actions/update';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const Password = ({ user, data }) => {
    const classes = useStyles();

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showDoubleNewPassword, setShowDoubleNewPassword] = useState(false);

    const [setPassword] = useMutation(SET_PASSWORD);

    const onSubmit = inputData => {
        setPassword({
            variables: {
                oldPassword: inputData.oldPassword,
                newPassword: inputData.newPassword,
                email: data.partnerAccountEmployee.email,
                type: user && user.type,
            },
        })
            .then(() => {
                toast.success('Success');
            })
            .catch(err => toast.error(err.message));
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Current Password is required'),
        newPassword: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(checkUppercase, 'Password must have one uppercase character')
            .matches(checkLowercase, 'Password must have one lowercase character')
            .matches(checkNumber, 'Password must have one number')
            .matches(checkSpecialCharacter, 'Password must have one special character')
            .required('Current New Password is required'),
        doubleNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Confirm New Password must match')
            .required('Confirm New Password is required'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('newPassword') ? '' : watch('newPassword');

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                Change password
            </Typography>
            <Grid container>
                <Grid item className={classes.form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="oldPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    error={!!errors.oldPassword}
                                    type={showOldPassword ? 'text' : 'password'}
                                    value={value}
                                    label="Current Password"
                                    onChange={onChange}
                                    variant="filled"
                                    helperText={errors?.oldPassword?.message ?? ' '}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowOldPassword(!showOldPassword)}>
                                                    {errors.oldPassword ? <Error color="error" /> : showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    error={!!errors.newPassword}
                                    type={showNewPassword ? 'text' : 'password'}
                                    value={
                                        value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                            ? value
                                            : value.replace(value[value.length - 1], '')
                                    }
                                    label="New Password"
                                    onChange={onChange}
                                    variant="filled"
                                    helperText={errors?.newPassword?.message ?? ' '}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowNewPassword(!showNewPassword)}>
                                                    {errors.newPassword ? <Error color="error" /> : showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div className={classes.listPass1}>
                            <ListPassword
                                inputVariable={inputVariable}
                                checkUppercase={checkUppercase}
                                checkLowercase={checkLowercase}
                                checkNumber={checkNumber}
                                checkSpecialCharacter={checkSpecialCharacter}
                            />
                        </div>

                        <Controller
                            name="doubleNewPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={showDoubleNewPassword ? 'text' : 'password'}
                                    value={value}
                                    label="Confirm New Password"
                                    onChange={onChange}
                                    variant="filled"
                                    className={classes.input}
                                    error={!!errors.doubleNewPassword}
                                    helperText={errors?.doubleNewPassword?.message ?? ' '}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" onClick={() => setShowDoubleNewPassword(!showDoubleNewPassword)}>
                                                    {errors.doubleNewPassword ? (
                                                        <Error color="error" />
                                                    ) : showDoubleNewPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button type="submit" color="primary" variant="contained" className={classes.button}>
                                restore password
                            </Button>
                        </div>
                    </form>
                </Grid>

                <Grid item xs className={classes.listPass2}>
                    <ListPassword
                        inputVariable={inputVariable}
                        checkUppercase={checkUppercase}
                        checkLowercase={checkLowercase}
                        checkNumber={checkNumber}
                        checkSpecialCharacter={checkSpecialCharacter}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Password;
