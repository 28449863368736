import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './styles';

const Preloader = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <CircularProgress size="10rem" color="primary" className={classes.circular} />
        </div>
    );
};

export default Preloader;
