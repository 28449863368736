import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../theme/Modals';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_L7_RESOURCE } from '../../../../actions/update';

const ModalSwitchOnResource = ({
    show,
    hideModal,
    l7ResourceId,
    name,
    query,
    getVariables,
    partnerClientAccountId,
}) => {
    const classes = useStyles();

    const [updateL7Resource] = useMutation(UPDATE_L7_RESOURCE);

    const onClickOnResource = () => {
        updateL7Resource({
            variables: {
                serviceEnable: 1,
                partnerClientAccountId: Number(partnerClientAccountId),
                serviceId: Number(l7ResourceId), // используется l7ResourceId, т.к. l7ResourceId === serviceId, и для общей таблицы нету фильтрации по полю serviceId
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">Switch on resource</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.dsc}>
                            Are you sure that you want to activate the resource id {l7ResourceId} ({name})?
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={classes.cancel} onClick={() => hideModal()}>
                            Cancel
                        </Button>
                        <Button className={classes.delete} onClick={onClickOnResource}>
                            activate
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalSwitchOnResource;
