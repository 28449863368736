import { withStyles } from '@material-ui/core';

export const GlobalCss = withStyles(theme => ({
    '@global': {
        '.Toastify__toast--success': {
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
        },
        '.Toastify__progress-bar': {
            background: theme.palette.primary.main,
        },
        '.Toastify__close-button': {
            color: theme.palette.primary.main,
        },
        'html, #root': {
            height: '100%',
        },
        body: {
            margin: 0,
            height: '100%',
            fontFamily: 'Roboto',
        },
        '.MuiFormControl-root': {
            width: '100%',
            minWidth: '60px !important',
        },
        '#scrollElement::-webkit-scrollbar': {
            width: 6,
            backgroundColor: 'transparent',
        },

        '#scrollElement::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
        },

        '#scrollElement::-webkit-scrollbar-track': {
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
            border: '0.5px solid transparent',
            backgroundClip: 'content-box',
        },
    },
}))(() => null);
