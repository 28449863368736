import React from 'react';
import { AntTableSortLabel } from './styles';
import { updateSortDirection } from '../../../../helpers/calculation';

const TableSort = ({ name, label, direction, setFilter, sortActive, setSortActive, filter, type }) => {
    const clickSort = event => {
        setSortActive(name);
        setFilter({ ...filter, page: 0, sort: { ...filter?.sort, [name]: updateSortDirection(direction) } }, type);
    };

    return (
        <AntTableSortLabel active={sortActive === name} direction={direction} onClick={clickSort}>
            {label}
        </AntTableSortLabel>
    );
};
export default TableSort;
