import React, { useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../../../theme/Tabs';
import { AntContainerHead } from '../../../../theme/Wrapper';
import { NavLink } from 'react-router-dom';
import MitigationTrafficDetails from './traffic';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_MITIGATIONS } from '../../../../actions/get';
import Preloader from '../../../../components/global/Preloader';
import TabPanelLayout from '../../../../components/global/tab/TabPanelLayout';
import TabPanelBackLink from '../../../../components/global/tab/TabPanelBackLink';

const MitigationsDetails = () => {
    const classes = useStyles();
    const [indexTab, setIndexTab] = useState(0);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const { backLink, indexPage, id } = useParams();

    const { data, loading } = useQuery(GET_ARBOR_MITIGATIONS, {
        variables: {
            id: Number(id),
        },
    });

    if (loading) return <Preloader />;

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false} className={classes.container}>
                    <TabPanelBackLink to={`/${backLink}/${indexPage}`} text="Mitigation traffic details" />
                    <AntTabs value={indexTab} onChange={handleChange}>
                        <AntTab
                            label="mitigation traffic details"
                            {...a11yProps(0)}
                            component={NavLink}
                            // to={`/settings-account-members/1/${partnerClientAccountEmployeeId}`}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <MitigationTrafficDetails mitigation={data && data?.arborMitigations?.items[0]} />
            </TabPanelLayout>
        </>
    );
};

export default MitigationsDetails;
