import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useStyles } from '../../theme/Chart';

const BarChartsBasic = ({ points, lastPoint }) => {
    const classes = useStyles();
    let pointsGroup = [];

    if (points && points.length >= 1) {
        pointsGroup = points.reduce((acc, item, index) => {
            const proxy = [...acc];
            if (index === 0 || index % 3 === 0) {
                proxy.push(item.sample.reduce((a, b) => Number(a) + Number(b), 0));
            } else {
                proxy[proxy.length - 1] =
                    item.sample.reduce((a, b) => Number(a) + Number(b), 0) + proxy[proxy.length - 1];
            }
            return proxy;
        }, []);
    }
    const series = {
        labels: [
            '0-150',
            '151-300',
            '301-450',
            '451-600',
            '601-750',
            '751-900',
            '901-1050',
            '1051-1200',
            '1201-1350',
            '1351-1500',
            'jumboframes',
        ],
        datasets: [
            {
                label: 'size',
                data: [...pointsGroup, lastPoint],
                backgroundColor: '#01CDCB',
                borderWidth: 3,
                borderColor: 'transparent',
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        animation: false,
        maintainAspectRatio: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            x: {
                grid: {
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value) {
                        if (value >= 1000000) {
                            return +(value / 1000000).toFixed(2) + `M`;
                        }
                        return +(value / 1000).toFixed(2) + ` K`;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            crosshair: false,
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.raw >= 1000000) {
                            return +(context.raw / 1000000).toFixed(2) + `M`;
                        }
                        return +(context.raw / 1000).toFixed(2) + ` K`;
                    },
                },
            },
        },
    };

    return (
        <div className={classes.chartBar}>
            <Bar data={series} options={options} />
        </div>
    );
};

export default BarChartsBasic;
