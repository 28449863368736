import React, { useContext, useState } from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { AuthContext } from '../../../context/auth';
import { AntContainerHead } from '../../../theme/Wrapper';
import { useStyles } from './styles';
import { GET_PARTNER_ACCOUNT_EMPLOYEE } from '../../../actions/get';
import { useQuery } from '@apollo/client';
import UserPopover from './UserPopover';
import { Link, useLocation } from 'react-router-dom';
import { logoAvatar } from '../../../helpers/createAvatar';
import { PageContext } from '../../../context/pages';
import { LogoMiniIcon, UserIcon } from '../../../../assets/img';

const Header = () => {
    const classes = useStyles();
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const { user } = useContext(AuthContext);
    const { deleteFilter } = useContext(PageContext);

    const { data } = useQuery(GET_PARTNER_ACCOUNT_EMPLOYEE, {
        variables: {
            id: user && user.id,
        },
    });

    const openUser = Boolean(userAnchorEl);
    const idUser = openUser ? 'user' : undefined;

    const handleClick = event => {
        setUserAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setUserAnchorEl(null);
    };

    const handleChangeActive = () => {
        deleteFilter();
    };

    const location = useLocation();

    return (
        <Grid container className={classes.header}>
            <AntContainerHead maxWidth={false}>
                <UserPopover
                    id={idUser}
                    open={openUser}
                    anchorEl={userAnchorEl}
                    handleClose={handleClose}
                    firstName={data && data.partnerAccountEmployee.name.first}
                    lastName={data && data.partnerAccountEmployee.name.last}
                    email={data && data.partnerAccountEmployee.email}
                />
                <Grid container className={classes.headerItems}>
                    <div className={classes.menuHeader}>
                        <div className={classes.imgBoxLogo}>
                            <LogoMiniIcon />
                        </div>

                        <div>
                            <Link
                                to={`/accounts`}
                                onClick={handleChangeActive}
                                className={
                                    location.pathname.includes('/accounts') ||
                                    location.pathname.includes('/edit-account') ||
                                    location.pathname.includes('/settings-account-members')
                                        ? `${classes.menuLinkActive} ${classes.menuLink}`
                                        : `${classes.menuLink}`
                                }
                            >
                                Accounts
                            </Link>
                            <Link
                                to={`/users`}
                                onClick={handleChangeActive}
                                className={
                                    location.pathname.includes('/users')
                                        ? `${classes.menuLinkActive} ${classes.menuLink}`
                                        : `${classes.menuLink}`
                                }
                            >
                                Users
                            </Link>
                            <Link
                                to={`/net-protection/0`}
                                onClick={handleChangeActive}
                                className={
                                    location.pathname.includes('/net-protection') ||
                                    location.pathname.includes('/anomaly-details') ||
                                    location.pathname.includes('/mitigations-details')
                                        ? `${classes.menuLinkActive} ${classes.menuLink}`
                                        : `${classes.menuLink}`
                                }
                            >
                                Network protection
                            </Link>
                            <Link
                                to={`/app-protection/0`}
                                onClick={handleChangeActive}
                                className={
                                    location.pathname.includes('/app-protection')
                                        ? `${classes.menuLinkActive} ${classes.menuLink}`
                                        : `${classes.menuLink}`
                                }
                            >
                                Application protection
                            </Link>
                        </div>
                    </div>
                    <div className={classes.imgBoxUser} aria-describedby={idUser} onClick={handleClick}>
                        <Avatar className={classes.avatar}>
                            {data && data.partnerAccountEmployee.name.first && data.partnerAccountEmployee.name.last ? (
                                logoAvatar(
                                    data && data.partnerAccountEmployee.name.first,
                                    data && data.partnerAccountEmployee.name.last
                                )
                            ) : (
                                <UserIcon />
                            )}
                        </Avatar>
                    </div>
                </Grid>
            </AntContainerHead>
        </Grid>
    );
};

export default Header;
