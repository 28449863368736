import React from 'react';
import { Chart, Interaction } from 'chart.js';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import { Line } from 'react-chartjs-2';
import { valueCalculation } from '../../helpers/calculation';
import dateFormat from 'dateformat';
import { useStyles } from '../../theme/Chart';

Chart.register(CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

const AreaDropPass = ({ data, timestamp, format, calcFormat }) => {
    const classes = useStyles();

    const series = {
        labels: timestamp,
        datasets: [
            {
                label: 'DROP',
                data: data?.arborMitigationStatistic.ratesTotal[`drop${format}`],
                borderColor: '#E57373',
                backgroundColor: 'rgba(229, 115, 115, 0.7)',
                borderWidth: 1,
                radius: 0,
                hoverRadius: 3,
            },
            {
                label: 'PASS',
                data: data?.arborMitigationStatistic.ratesTotal[`pass${format}`],
                borderColor: 'green',
                backgroundColor: 'rgba(105, 240, 174, 0.2)',
                borderWidth: 0.5,
                radius: 0,
                hoverRadius: 3,
            },
        ],
    };

    const options = {
        interaction: {
            mode: 'index',
        },
        maintainAspectRatio: false,
        elements: {
            line: {
                borderJoinStyle: 'bevel',
                borderCapStyle: 'square',
                fill: true,
            },
        },
        animation: false,
        scales: {
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                stacked: false,
                ticks: {
                    display: true,
                    callback: function (value) {
                        return valueCalculation(value, calcFormat);
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: true,
                    callback: function (value, index, values) {
                        return index % 2 === 0 ? dateFormat(this.getLabelForValue(value), 'h:MM:ssTT') : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            filler: {
                propagate: false,
            },
            crosshair: {
                line: {
                    color: '#848484',
                    width: 0.5,
                },
                sync: {
                    enabled: false,
                },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
                    zoomboxBorderColor: '#48F',
                    zoomButtonText: 'reset zoom',
                    zoomButtonClass: 'zoom',
                },
            },
            tooltip: {
                animation: false,
                intersect: false,
                callbacks: {
                    title: function (context) {
                        return dateFormat(Number(context[0].label), 'dd/mm/yy h:MM:ssTT');
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${valueCalculation(context.raw, calcFormat)}`;
                    },
                },
            },
        },
    };
    return (
        <div className={classes.chart}>
            <Line data={series} options={options} />
        </div>
    );
};

export default AreaDropPass;
