import React from 'react';
import { AntContainer } from '../../../../theme/Wrapper';

const TabPanelLayout = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <AntContainer maxWidth={false}>{children}</AntContainer>
        </div>
    );
};

export default TabPanelLayout;
