import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AntTableCell, BorderLinearProgress } from '../../../../../theme/Table';
import { Typography, Table, TableBody, TableRow, Grid } from '@material-ui/core';
import { useStyles, AntTabs, AntTab } from './styles';
import { percentCalculation, valueCalculation } from '../../../../../helpers/calculation';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_ALERT_DETAIL } from '../../../../../actions/get';
import Preloader from '../../../../../components/global/Preloader';
import AreaChartsBasic from '../../../../../components/charts/AreaChartsBasic';
import BarChartsBasic from '../../../../../components/charts/BarChartsBasic';
import CardsHead from '../../../../../components/global/cards/CardsHead';
import TabPanel from '../../../../../components/global/tab/TabPanel';

const Summary = ({ store }) => {
    const classes = useStyles();
    const { id } = useParams();

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const details = store.arborAlerts.items[0];
    console.log(details);
    const startValue = () => {
        return details.importance === 'low'
            ? 1
            : details.importance === 'medium'
            ? 2
            : details.importance === 'high'
            ? 3
            : '';
    };
    const [indexTab, setIndexTab] = useState(details.unit === 'bps' ? 0 : 1);

    const { data, loading } = useQuery(GET_ARBOR_ALERT_DETAIL, {
        variables: {
            id: details?.id,
        },
    });

    if (loading) return <Preloader />;

    const type = indexTab === 0 ? 'bytes' : 'packets';
    const format = indexTab === 0 ? 'bps' : 'pps';

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const createData = (name, data, progress) => {
        return { name, data, progress };
    };

    const progress =
        data &&
        data.arborAlertDetail[`dest_addr_${type}`].reduce((p, c) => {
            return p + c[`avg_${type}`];
        }, 0);

    const columns = {
        destIp: data && data?.arborAlertDetail[`dest_addr_${type}`][0]?.[`avg_${type}`],
        misuse: data && data.arborAlertDetail[`misuse_types_${type}`][0]?.[`avg_${type}`],
        protocols: data && data.arborAlertDetail[`protos_${type}`][0]?.[`avg_${type}`],
        srcTcp: data && data.arborAlertDetail[`src_tcp_ports_${type}`][0]?.[`avg_${type}`],
        srcCountries: data && data.arborAlertDetail[`src_countries_${type}`][0]?.[`avg_${type}`],
        srcIp: data && data.arborAlertDetail[`src_addr_${type}`][0]?.[`avg_${type}`],
        destTcp: data && data.arborAlertDetail[`dest_tcp_ports_${type}`][0]?.[`avg_${type}`],
        tcpFlags: data && data.arborAlertDetail[`tcp_flags_${type}`][0]?.[`avg_${type}`],
        srcAsn: data && data.arborAlertDetail[`src_asn_${type}`][0]?.[`avg_${type}`],
    };
    const { destIp, misuse, protocols, srcTcp, srcCountries, srcIp, destTcp, tcpFlags, srcAsn } = columns;

    const rows = [
        createData(
            'Destination IP Addresses',
            data && data?.arborAlertDetail[`dest_addr_${type}`][0]?.[`name_${type}`],
            percentCalculation(destIp, progress)
        ),
        createData(
            'Misuse Types',
            data && data?.arborAlertDetail[`misuse_types_${type}`][0]?.[`name_${type}`],
            percentCalculation(misuse, progress)
        ),
        createData(
            'Protocols',
            data && data?.arborAlertDetail[`protos_${type}`][0]?.[`name_${type}`],
            percentCalculation(protocols, progress)
        ),
        createData(
            'Source TCP Ports',
            data && data?.arborAlertDetail[`src_tcp_ports_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcTcp, progress)
        ),
        createData(
            'Source Countries',
            data && data?.arborAlertDetail[`src_countries_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcCountries, progress)
        ),
        createData(
            'Source IP Addresses',
            data && data?.arborAlertDetail[`src_addr_${type}`][0]?.[`name_${type}`] === '0.0.0.0/0'
                ? 'Highly Distributed'
                : data?.arborAlertDetail[`src_addr_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcIp, progress)
        ),
        createData(
            'Destination TCP Ports',
            data && data?.arborAlertDetail[`dest_tcp_ports_${type}`][0]?.[`name_${type}`],
            percentCalculation(destTcp, progress)
        ),
        createData(
            'TCP Flags',
            data && data?.arborAlertDetail[`tcp_flags_${type}`][0]?.[`name_${type}`],
            percentCalculation(tcpFlags, progress)
        ),
        createData(
            'Source ASNs',
            data && data?.arborAlertDetail[`src_asn_${type}`][0]?.[`name_${type}`],
            percentCalculation(srcAsn, progress)
        ),
    ];

    const points = data && [...data.arborAlertDetail.packet_distribution];

    return (
        <>
            <div className={classes.wrapper}>
                <Grid container spacing={2} className={classes.headerWrapper}>
                    <Grid item xs className={classes.titleWrapper}>
                        <Typography variant="h6">Anomaly details</Typography>
                        <Typography variant="h6">(id {id})</Typography>
                    </Grid>
                    <Grid item xs>
                        <CardsHead title="Severity Level" data={details.importance} starValue={startValue()} />
                    </Grid>
                    <Grid item xs>
                        <CardsHead title="Max bit rate" data={`${valueCalculation(details.maxImpactBps, 'bps')}`} />
                    </Grid>
                    <Grid item xs>
                        <CardsHead title="Max packet rate" data={`${valueCalculation(details.maxImpactPps, 'pps')}`} />
                    </Grid>
                    <Grid item xs md={3}>
                        <CardsHead title="Resource Name" data={details.alertManagedObjectName} />
                    </Grid>
                    <Grid item xs>
                        <CardsHead title="Target IP" data={details.alertResourceCidr} />
                    </Grid>
                </Grid>
            </div>

            <div className={classes.charts}>
                <div className={classes.tabs}>
                    <div className={classes.cartTitle}>Anomaly traffic</div>
                    <AntTabs value={indexTab} onChange={handleChange}>
                        <AntTab label="BPS" {...a11yProps(0)} />
                        <AntTab label="PPS" {...a11yProps(1)} />
                    </AntTabs>
                </div>
                <TabPanel value={indexTab} index={0}>
                    <AreaChartsBasic
                        formatter="bps"
                        time={details?.impactRecorded}
                        points={data?.arborAlertDetail?.misuse_types_bytes}
                        details={details}
                    />
                </TabPanel>
                <TabPanel value={indexTab} index={1}>
                    <AreaChartsBasic
                        formatter="pps"
                        time={details?.impactRecorded}
                        points={data?.arborAlertDetail?.misuse_types_packets}
                        details={details}
                    />
                </TabPanel>
            </div>

            <div className={classes.wrapper}>
                <Grid container spacing={10} className={classes.wrapperBox}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className={classes.itemWrapper}>
                            <div className={classes.itemTitle}>Alert Characterization</div>
                            <Table>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow hover key={index}>
                                            <AntTableCell>{row.name}</AntTableCell>
                                            <AntTableCell>{row.data}</AntTableCell>
                                            <AntTableCell className={classes.linear}>
                                                <BorderLinearProgress
                                                    variant="determinate"
                                                    value={100 - row.progress}
                                                />
                                            </AntTableCell>
                                            <AntTableCell align="center" className={classes.greyColor}>
                                                {row.progress} %
                                            </AntTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className={classes.itemWrapper}>
                            <div className={classes.itemTitle}>Packet Size Distribution</div>
                            <BarChartsBasic
                                points={data && points.slice(0, points.length - 1)}
                                lastPoint={
                                    data &&
                                    data.arborAlertDetail.packet_distribution[
                                        data.arborAlertDetail.packet_distribution.length - 1
                                    ]
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Summary;
