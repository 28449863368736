import React, { useContext } from 'react';
import { useStyles, CustomMenuItem } from './styles';
import { Button, ListItemIcon } from '@material-ui/core';
import { AssignmentInd, Group } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { button } from '../../../../theme/PopoverButton/styles';
import { AntPopover } from '../../../../theme/AntPopover';
import { AuthContext } from '../../../../context/auth';
import { PageContext } from '../../../../context/pages';

const UserPopover = ({ id, open, anchorEl, handleClose, firstName, lastName, email }) => {
    const classes = useStyles();
    const style = button();

    const { user, logout } = useContext(AuthContext);
    const { deleteFilter } = useContext(PageContext);
    const location = useLocation();

    return (
        <AntPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.popover}
        >
            <div className={classes.popoverWrapper}>
                <div className={classes.popoverHeader}>
                    <div className={classes.userName}>
                        {firstName} {lastName}
                    </div>
                    <div className={classes.userEmail}>{email}</div>
                </div>

                <div className={classes.popoverBody}>
                    <Link
                        to="/my-profile/0"
                        className={
                            location.pathname.includes('/my-profile/0')
                                ? `${classes.linkItemActive}`
                                : `${classes.linkItem}`
                        }
                        onClickCapture={() => {
                            handleClose();
                        }}
                    >
                        <CustomMenuItem selected={location.pathname.includes('/my-profile/0')}>
                            <ListItemIcon>
                                <AssignmentInd />
                            </ListItemIcon>
                            My profile
                        </CustomMenuItem>
                    </Link>
                    <Link
                        to="/my-profile/1"
                        className={
                            location.pathname.includes('/my-profile/1')
                                ? `${classes.linkItemActive} `
                                : `${classes.linkItem}`
                        }
                        onClickCapture={() => {
                            handleClose();
                            deleteFilter();
                        }}
                    >
                        <CustomMenuItem selected={location.pathname.includes('/my-profile/1')}>
                            <ListItemIcon>
                                <Group />
                            </ListItemIcon>
                            Members
                        </CustomMenuItem>
                    </Link>

                    <div className={classes.buttonBox}>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={style.button}
                            onClick={() => {
                                logout();
                                handleClose();
                            }}
                        >
                            log out
                        </Button>
                    </div>
                </div>
            </div>
        </AntPopover>
    );
};

export default UserPopover;
