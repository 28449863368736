import React from 'react';
import { Grid, FormGroup } from '@material-ui/core';
import { useStyles } from './styles';
import { BaseSwitch } from '../../../theme/Switch';

const BaseSwitchPpsBps = ({ switchBpsPps, setSwitchBpsPps, name, disabled }) => {
    const classes = useStyles();

    return (
        <FormGroup className={disabled ? `${classes.switchOff}` : ''}>
            <Grid component="label" className={classes.switcher}>
                <Grid item>pps</Grid>
                <Grid item>
                    <BaseSwitch
                        checked={switchBpsPps[name] === 'bps'}
                        onChange={event => {
                            setSwitchBpsPps({ ...switchBpsPps, [name]: event.target.checked ? 'bps' : 'pps' });
                        }}
                        name="bps"
                    />
                </Grid>
                <Grid item>bps</Grid>
            </Grid>
        </FormGroup>
    );
};

export default BaseSwitchPpsBps;
