import React, { useState, useRef, useContext } from 'react';
import { AntTableCell, AntTableCellFilter } from '../../theme/Table';
import { Typography, Table, TableHead, TableRow, TableBody, Grid, TableContainer } from '@material-ui/core';
import TableFilter from '../../components/global/table/TableFilter';
import TableScroll from '../../components/global/table/TableScroll';
import { textWidthCalculation } from '../../helpers/calculation';
import { AntContainer } from '../../theme/Wrapper';
import ModalSwitchOffResource from '../../components/modals/modalsL7Protection/switchOffResource';
import ModalSwitchOnResource from '../../components/modals/modalsL7Protection/switchOnResource';
import { PageContext } from '../../context/pages';
import { useLazyQuery } from '@apollo/client';
import { GET_L7_RESOURCES_VENDOR } from '../../actions/get';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import Preloader from '../../components/global/Preloader';
import TablePaginationComponent from '../../components/global/table/TablePagination';
import Resource from './Resource';
import TableSort from '../../components/global/table/TableSort';
import TableNoDataComponent from '../../components/global/table/TableNoData';

const ApplicationProtection = () => {
    const table = useRef(null);
    const [sortActive, setSortActive] = useState('sortL7ResourceId');
    const { filter, setFilter } = useContext(PageContext);

    const [isSwitchOn, setIsSwitchOn] = useState({
        switchOn: false,
        l7ResourceId: '',
        name: '',
        partnerClientAccountId: '',
    });
    const [isSwitchOff, setIsSwitchOff] = useState({
        switchOff: false,
        l7ResourceId: '',
        name: '',
        partnerClientAccountId: '',
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_L7_RESOURCES_VENDOR);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            l7ResourceName: filter?.l7ResourceName || '',
            serviceIp: filter?.serviceIp || '',
            deletedAt: null,
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.l7ResourceId) proxy.l7ResourceId = Number(filter?.l7ResourceId);
        if (filter?.accountId) proxy.accountId = Number(filter?.accountId);
        return proxy;
    };

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        500,
        [filter]
    );

    if (loading) return <Preloader />;

    const maxWidth = {
        l7ResourceId: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceId))
        ),
        l7ResourceName: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceName))
        ),
        accountId: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.accountId))
        ),
        serviceIp: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.serviceIp))
        ),
    };

    return (
        <AntContainer maxWidth={false}>
            <ModalSwitchOffResource
                show={isSwitchOff.switchOff}
                hideModal={() => setIsSwitchOff(!isSwitchOff.switchOff)}
                l7ResourceId={isSwitchOff.l7ResourceId}
                name={isSwitchOff.name}
                partnerClientAccountId={isSwitchOff.partnerClientAccountId}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <ModalSwitchOnResource
                show={isSwitchOn.switchOn}
                hideModal={() => setIsSwitchOn(!isSwitchOn.switchOn)}
                l7ResourceId={isSwitchOn.l7ResourceId}
                name={isSwitchOn.name}
                partnerClientAccountId={isSwitchOff.partnerClientAccountId}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <TableScroll tableRef={table}>
                <TableContainer>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1">L7 DDoS Protection resource list</Typography>
                        </Grid>
                    </Grid>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>
                                    <TableSort
                                        name="sortL7ResourceId"
                                        direction={filter?.sort?.sortL7ResourceId}
                                        label="ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>Resource Name</AntTableCell>
                                <AntTableCell>Service IP</AntTableCell>
                                <AntTableCell>
                                    <TableSort
                                        name="sortPartnerClientAccountId"
                                        direction={filter?.sort?.sortPartnerClientAccountId}
                                        label="Account ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>L7 Protection</AntTableCell>
                                <AntTableCell>Active bot protection</AntTableCell>
                                <AntTableCell>
                                    <TableSort
                                        name="sortCreatedAt"
                                        direction={filter?.sort?.sortCreatedAt}
                                        label="Created At"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell />
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="l7ResourceId"
                                        maxWidth={maxWidth.l7ResourceId}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="l7ResourceName"
                                        maxWidth={maxWidth.l7ResourceName}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="serviceIp"
                                        maxWidth={maxWidth.serviceIp}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="accountId"
                                        maxWidth={maxWidth.accountId}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.l7ResourcesVendor.items.length !== 0 ? (
                                data.l7ResourcesVendor.items.map(
                                    ({
                                        serviceIp,
                                        l7ResourceId,
                                        createdAt,
                                        l7ResourceName,
                                        iauthDisableL7,
                                        serviceAbpMode,
                                        partnerClientAccountId,
                                        serviceEnable,
                                    }) => (
                                        <Resource
                                            key={l7ResourceId}
                                            serviceIp={serviceIp}
                                            createdAt={createdAt}
                                            l7ResourceName={l7ResourceName}
                                            l7ResourceId={l7ResourceId}
                                            iauthDisableL7={iauthDisableL7}
                                            serviceAbpMode={serviceAbpMode}
                                            serviceEnable={serviceEnable}
                                            setIsSwitchOff={setIsSwitchOff}
                                            setIsSwitchOn={setIsSwitchOn}
                                            partnerClientAccountId={partnerClientAccountId}
                                        />
                                    )
                                )
                            ) : (
                                <TableNoDataComponent colSpan={8} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.l7ResourcesVendor.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </AntContainer>
    );
};
export default ApplicationProtection;
