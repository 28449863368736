import React from 'react';
import { useStyles } from './styles';
import { TextField, Typography, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GET_PARTNER_CLIENT_ACCOUNT } from '../../../actions/get';
import { toast } from 'react-toastify';
import { UPDATE_PARTNER_CLIENT_ACCOUNT } from '../../../actions/update';

const GeneralAccount = ({ partnerClientAccount }) => {
    const classes = useStyles();
    const { id } = useParams();

    const { handleSubmit, control } = useForm();

    const [updateClient] = useMutation(UPDATE_PARTNER_CLIENT_ACCOUNT);
    const onSubmit = data => {
        updateClient({
            variables: {
                id: Number(id),
                name: data.accountName,
            },
            refetchQueries: [
                {
                    query: GET_PARTNER_CLIENT_ACCOUNT,
                    variables: {
                        id: Number(id),
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                General
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="accountID"
                    control={control}
                    defaultValue={id}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            readonly
                            disabled
                            onChange={onChange}
                            value={id}
                            label="Account ID"
                            variant="filled"
                            className={classes.input}
                        />
                    )}
                />
                <Controller
                    name="accountName"
                    control={control}
                    defaultValue={partnerClientAccount && partnerClientAccount.name}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            onChange={onChange}
                            value={value}
                            label="Account Name"
                            variant="filled"
                            className={classes.input}
                        />
                    )}
                />

                <div className={classes.buttonWrapper}>
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                        Save
                    </Button>
                </div>
            </form>
        </>
    );
};
export default GeneralAccount;
