import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
        textAlign: 'center',
    },
    description: {
        padding: '0 60px',
        marginBottom: 30,
        textAlign: 'center',
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: theme.palette.grey.backgroundMain,
    },
    input: {
        marginBottom: 10,
        width: '100%',
    },
    button: {
        marginBottom: 30,
        width: '100%',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        padding: '15px 0',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        fontStyle: 'normal',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        marginBottom: 30,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    list: {
        width: '100%',
        padding: 0,
        marginBottom: 30,
    },
    listItem: {
        padding: '0',
    },
    listIcon: {
        marginRight: 15,
    },
    active: {
        color: theme.palette.common.black,
    },
    disable: {
        color: '#c6c4c4',
    },
}));
