const routes = {
    auth: '/',
    joinAccountFirst: '/invite-partner-account-employee',
    joinAccountSecond: '/join-account-second-step',
    joinAccountDisabled: '/join-account-disabled',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    resetPasswordDone: '/reset-password-done',
    myProfile: '/my-profile/:tab',
    accounts: '/accounts',
    accountEdit: '/edit-account/:tab/:id',
    accountMembersEdit: '/settings-account-members/:tab/:id',
    users: '/users',
    netProtection: '/net-protection/:tab',
    appProtection: '/app-protection/:tab',
    anomalyDetails: '/anomaly-details/:tab/:backLink/:id/:indexPage',
    mitigationsDetails: '/mitigations-details/:backLink/:start/:mitigationId/:id/:indexPage',
};

export default routes;
