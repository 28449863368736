import React, { useEffect, useState, useContext } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs';
import { AntContainerHead } from '../../theme/Wrapper';
import TabPanelLayout from '../../components/global/tab/TabPanelLayout';
import UserInfo from './userInfo';
import Members from './members';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageContext } from '../../context/pages';

const MyProfile = () => {
    const classes = useStyles();

    const { tab } = useParams();
    const [indexTab, setIndexTab] = useState(0);

    const { deleteFilter } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <AntTabs value={indexTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                        <AntTab component={NavLink} label="My Profile" {...a11yProps(0)} to="/my-profile/0" />
                        <AntTab
                            component={NavLink}
                            label="account members"
                            {...a11yProps(1)}
                            to="/my-profile/1"
                            onClick={() => {
                                deleteFilter();
                            }}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <UserInfo />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <Members />
            </TabPanelLayout>
        </>
    );
};

export default MyProfile;
