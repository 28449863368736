import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    errorCharts: {
        fontSize: 20,
        margin: '50px 0px',
        textAlign: 'center',
    },
    tabs: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 30,
    },
    chartBar: {
        position: 'relative',
        margin: 'auto',
        width: '100%',
        minHeight: '415px',
    },
    chart: {
        position: 'relative',
        margin: 'auto',
        maxHeight: 400,
        height: 'calc(100vh - 30vh) !important',
        width: 'calc(100% - 1px) !important',

        '& .zoom': {
            position: 'absolute',
            padding: '7px 16px',
            color: '#FFFFFF',
            backgroundColor: '#2196f3',
            fontSize: 14,
            minWidth: 64,
            boxSizing: 'border-box',
            transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: 'Roboto',
            fontWeight: 500,
            borderRadius: 4,
            textTransform: 'uppercase',
            borderColor: 'transparent',
            cursor: 'pointer',
            top: 20,
            left: 80,
        },
    },
    request: {
        '&:after': {
            position: 'absolute',
            content: "'rpm - requests per minute'",
            bottom: 5,
            left: 0,
            fontSize: 14,
        },
    },
}));
