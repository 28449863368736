import React, { useState } from 'react';
import { useStyles } from './styles';
import {
    TextField,
    InputAdornment,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff, FiberManualRecord, Error, NavigateNext } from '@material-ui/icons';
import Logo from '../../../components/global/Logo';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Login, SET_PASSWORD } from '../../../actions/update';
import { useMutation } from '@apollo/client';
import { saveState } from '../../../helpers/localStorage';
import routes from '../../../routes';

const JoinAccountFirst = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    const email = searchParams.get('email');
    const navigate = useNavigate();

    const [setPassword] = useMutation(SET_PASSWORD, {
        context: {
            plug: true,
        },
    });
    const [login] = useMutation(Login, {
        context: {
            plug: true,
        },
    });

    const onSubmit = data => {
        setPassword({
            variables: {
                newPassword: data.doublePassword,
                verifyToken: token,
                email: email,
                type: 'PARTNER_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => toast.success('Success'))
            .then(() => {
                login({
                    variables: {
                        email: email,
                        password: data.doublePassword,
                        type: 'PARTNER_ACCOUNT_EMPLOYEE',
                    },
                }).then(res => localStorage.setItem('join', res.data.login.accessToken));
            })
            .then(() => {
                saveState(
                    {
                        id,
                        email,
                        password: data.doublePassword,
                        type: 'PARTNER_ACCOUNT_EMPLOYEE',
                    },
                    'secondStep'
                );
                navigate(routes.joinAccountSecond);
            })
            .catch(err => {
                toast.error(err.message);
                if (err.message === 'VerifyTokenWrongForbiddenProblem') {
                    navigate(routes.joinAccountDisabled);
                }
            });
    };

    const checkUppercase = /([A-Z])|([А-Я])/;
    const checkLowercase = /([a-z])|([а-я])/;
    const checkNumber = /([0-9])/;
    const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(checkUppercase, 'Password must have one uppercase character')
            .matches(checkLowercase, 'Password must have one lowercase character')
            .matches(checkNumber, 'Password must have one number')
            .matches(checkSpecialCharacter, 'Password must have one special character')
            .required('New Password is required'),
        doublePassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match please try again')
            .required('Enter your password again'),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const inputVariable = !watch('password') ? '' : watch('password');

    return (
        <AuthWrapper>
            <Logo />
            <Typography variant="h1" className={classes.title}>
                Join account
            </Typography>
            <div className={classes.description}>Almost done, just setup your password.</div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            error={!!errors.password}
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={
                                value.charCodeAt(value.length - 1) >= 33 && value.charCodeAt(value.length - 1) <= 126
                                    ? value
                                    : value.replace(value[value.length - 1], '')
                            }
                            label="Password"
                            onChange={onChange}
                            variant="filled"
                            helperText={errors?.password?.message ?? ' '}
                            className={classes.input}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                            {errors.password ? (
                                                <Error color="error" />
                                            ) : showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <List className={classes.list}>
                    <ListItem
                        className={`${classes.listItem} ${
                            inputVariable?.split('').length >= 8 ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="8 characters minimum" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkUppercase.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one uppercase character" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkLowercase.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one lowercase character" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkNumber.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary="one number" />
                    </ListItem>
                    <ListItem
                        className={`${classes.listItem} ${
                            checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable
                        }`}
                    >
                        <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                        <ListItemText primary=" one special character" />
                    </ListItem>
                </List>
                <Controller
                    name="doublePassword"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            error={!!errors.doublePassword}
                            id="doublePassword"
                            type={showPassword2 ? 'text' : 'password'}
                            value={value}
                            label="Confirm password"
                            onChange={onChange}
                            variant="filled"
                            helperText={errors?.doublePassword?.message ?? ' '}
                            className={classes.input}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword2(!showPassword2)}>
                                            {errors.doublePassword ? (
                                                <Error color="error" />
                                            ) : showPassword2 ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <div className={classes.nextLink}>
                    <Button type="submit" className={classes.link}>
                        Next
                        <NavigateNext viewBox="0 0 24 26" />
                    </Button>
                </div>
            </form>
        </AuthWrapper>
    );
};

export default JoinAccountFirst;
