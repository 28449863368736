import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    outer: {
        overflow: 'hidden',
        '& > table': {
            transform: props => `translateX(-${props.translate}px)`,
        },
    },
}));

export default useStyles;
