import React from 'react';
import { Button, TableRow } from '@material-ui/core';
import { AntTableCell, useStyles } from '../../../../theme/Table';
import { MoreHoriz } from '@material-ui/icons';
import dateFormat from 'dateformat';

const L3Resource = ({ id, name, ips, setAnchorEl, setIpsPopover, partnerClientAccountId, createdAt }) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {id}
            </AntTableCell>
            <AntTableCell>{name}</AntTableCell>
            <AntTableCell>
                {ips.length === 1 ? (
                    ips[0]
                ) : ips.length === 0 ? (
                    '-'
                ) : (
                    <div>
                        {ips[0]}
                        <Button
                            className={classes.buttonMore}
                            aria-describedby={id}
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                                setIpsPopover(ips);
                            }}
                        >
                            <span className={classes.buttonMoreSpan}>
                                <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                            </span>
                        </Button>
                    </div>
                )}
            </AntTableCell>
            <AntTableCell>{partnerClientAccountId}</AntTableCell>
            <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ss TT')}</AntTableCell>
        </TableRow>
    );
};

export default L3Resource;
