import React, { useContext, useEffect, useState } from 'react';
import { AntTab, AntTabs, useStyles } from '../../theme/Tabs';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link, NavLink, useParams } from 'react-router-dom';
import GeneralAccount from './generalAccount';
import MembersAccount from './membersAccount';
import L3ProtectionAccount from './l3ProtectionAccount';
import L7ProtectionAccount from './l7ProtectionAccount';
import { AntContainerHead } from '../../theme/Wrapper';
import TabPanelLayout from '../../components/global/tab/TabPanelLayout';
import { GET_PARTNER_CLIENT_ACCOUNT } from '../../actions/get';
import { useQuery } from '@apollo/client';
import Preloader from '../../components/global/Preloader';
import { PageContext } from '../../context/pages';
import TabPanelBackLink from '../../components/global/tab/TabPanelBackLink';

const AccountEdit = () => {
    const classes = useStyles();
    const { tab, id } = useParams();
    const [indexTab, setIndexTab] = useState(0);
    const { deleteFilter } = useContext(PageContext);

    const handleChange = (event, newValue) => {
        setIndexTab(newValue);
    };

    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        setIndexTab(Number(tab));
    }, [tab]);

    const { data, loading } = useQuery(GET_PARTNER_CLIENT_ACCOUNT, {
        variables: {
            id: Number(id),
        },
    });

    if (loading) return <Preloader />;

    return (
        <>
            <div position="static" className={classes.bar}>
                <AntContainerHead maxWidth={false}>
                    <TabPanelBackLink to="/accounts" deleteFilter={deleteFilter} edit="Edit" text="account" />
                    <AntTabs
                        value={indexTab}
                        onChange={handleChange}
                        className={classes.itemTab}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <AntTab
                            label="general"
                            {...a11yProps(0)}
                            to={`/edit-account/0/${id}`}
                            component={NavLink}
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            label="members"
                            {...a11yProps(1)}
                            to={`/edit-account/1/${id}`}
                            component={NavLink}
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            label="Network Protection"
                            {...a11yProps(2)}
                            to={`/edit-account/2/${id}`}
                            component={NavLink}
                            onClick={() => deleteFilter()}
                        />
                        <AntTab
                            label="Application Protection"
                            {...a11yProps(3)}
                            to={`/edit-account/3/${id}`}
                            component={NavLink}
                            onClick={() => deleteFilter()}
                        />
                    </AntTabs>
                </AntContainerHead>
            </div>
            <TabPanelLayout value={indexTab} index={0}>
                <GeneralAccount partnerClientAccount={data && data.partnerClientAccount} />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={1}>
                <MembersAccount />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={2}>
                <L3ProtectionAccount dataPartnerClientAccount={data && data.partnerClientAccount} />
            </TabPanelLayout>
            <TabPanelLayout value={indexTab} index={3}>
                <L7ProtectionAccount dataPartnerClientAccount={data && data.partnerClientAccount} />
            </TabPanelLayout>
        </>
    );
};
export default AccountEdit;
