import { SvgIcon } from '@material-ui/core';

export function LogoFullIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 456 95" style={{ height: 95, width: 456 }}>
            <path
                d="M111.424 66.937C102.303 66.937 95.5741 63.1158 95.1055 55.7257C95.1253 55.5224 95.2175 55.3329 95.3652 55.1918C95.513 55.0507 95.7065 54.9672 95.9105 54.9567H102.099C102.307 54.9534 102.508 55.0323 102.659 55.1762C102.81 55.3201 102.898 55.5175 102.904 55.7257C103.24 59.9915 106.16 61.8421 111.424 61.8421C115.894 61.8421 118.633 60.1117 118.633 57.0355C118.633 49.8258 95.8024 56.2064 95.8024 42.7001C95.8024 36.0791 101.402 32.5703 110.126 32.5703C118.453 32.5703 124.377 35.8868 125.038 42.5799C125.043 42.6786 125.028 42.7773 124.993 42.8698C124.958 42.9622 124.905 43.0466 124.836 43.1176C124.767 43.1886 124.685 43.2447 124.593 43.2824C124.502 43.3202 124.404 43.3387 124.305 43.3369H118.453C118.23 43.3543 118.008 43.2855 117.834 43.1448C117.66 43.004 117.546 42.8019 117.516 42.5799C116.915 39.3235 114.392 37.5451 109.994 37.5451C105.992 37.5451 103.469 38.951 103.469 42.1353C103.469 49.2009 126.3 42.5078 126.3 56.3986C126.348 63.1157 119.823 66.937 111.424 66.937Z"
                fill="black"
            />
            <path
                d="M163.563 54.957C163.659 54.958 163.754 54.9794 163.842 55.0197C163.93 55.06 164.008 55.1183 164.071 55.1909C164.135 55.2635 164.182 55.3487 164.211 55.4409C164.239 55.5332 164.247 55.6303 164.236 55.7261C163.238 61.9025 157.639 66.9373 148.614 66.9373C137.559 66.9373 131.575 59.8597 131.575 49.8621C131.575 39.4801 137.896 32.5947 148.398 32.5947C158.9 32.5947 164.98 39.3479 165.221 50.439C165.203 50.6401 165.113 50.8279 164.967 50.9672C164.821 51.1066 164.629 51.1879 164.428 51.1959H139.65C139.987 58.2014 142.979 61.518 148.71 61.518C152.832 61.518 155.572 59.6674 156.569 55.7261C156.631 55.5079 156.761 55.3154 156.941 55.1769C157.12 55.0384 157.34 54.9613 157.566 54.957H163.563ZM148.386 37.954C143.58 37.954 140.72 40.7538 139.854 46.041H156.509C156.173 41.7752 153.613 37.954 148.386 37.954Z"
                fill="black"
            />
            <path
                d="M172.082 66.1675C171.879 66.1566 171.687 66.0727 171.542 65.9314C171.396 65.7901 171.306 65.6007 171.289 65.3984L171.626 49.6091L171.289 34.1321C171.306 33.9298 171.396 33.7405 171.542 33.5991C171.687 33.4578 171.879 33.3739 172.082 33.363H178.607C178.706 33.3629 178.805 33.383 178.896 33.4221C178.987 33.4611 179.07 33.5184 179.139 33.5903C179.207 33.6622 179.26 33.7473 179.295 33.8405C179.33 33.9336 179.345 34.0328 179.34 34.1321L179.148 39.227C180.451 37.2553 182.243 35.6552 184.349 34.5832C186.455 33.5112 188.803 33.0038 191.164 33.1106C191.679 33.118 192.193 33.1582 192.702 33.2309C192.921 33.2599 193.122 33.3671 193.268 33.5328C193.413 33.6985 193.494 33.9114 193.495 34.1321V38.5901C193.491 38.6885 193.466 38.7851 193.424 38.8739C193.381 38.9628 193.321 39.0421 193.247 39.1072C193.173 39.1723 193.086 39.2217 192.993 39.2526C192.899 39.2835 192.8 39.2952 192.702 39.287C192.214 39.2542 191.724 39.2542 191.236 39.287C184.844 39.287 179.845 42.7838 179.376 50.1017L179.508 65.4465C179.491 65.6489 179.401 65.8382 179.256 65.9795C179.11 66.1209 178.918 66.2046 178.715 66.2155L172.082 66.1675Z"
                fill="black"
            />
            <path
                d="M218.826 65.6626C218.732 65.8657 218.584 66.0386 218.397 66.1617C218.21 66.2849 217.993 66.3534 217.769 66.3596H209.442C209.216 66.3556 208.996 66.2882 208.807 66.1649C208.618 66.0416 208.467 65.8675 208.372 65.6626L196.788 34.0598C196.761 33.9781 196.754 33.8908 196.768 33.8056C196.782 33.7205 196.816 33.64 196.868 33.5713C196.92 33.5025 196.988 33.4476 197.067 33.4112C197.145 33.3749 197.231 33.3583 197.317 33.3628H204.383C204.607 33.3746 204.823 33.4453 205.011 33.5677C205.199 33.6901 205.351 33.8598 205.452 34.0598L213.864 60.2433L222.792 34.0598C222.886 33.8549 223.037 33.6808 223.226 33.5576C223.415 33.4343 223.635 33.3668 223.861 33.3628H229.869C229.956 33.3562 230.044 33.3713 230.124 33.4068C230.204 33.4422 230.273 33.4969 230.327 33.5659C230.381 33.635 230.416 33.7162 230.431 33.8024C230.445 33.8886 230.438 33.977 230.41 34.0598L218.826 65.6626Z"
                fill="black"
            />
            <path
                d="M236.009 66.1673C235.805 66.1567 235.612 66.0733 235.464 65.9322C235.316 65.7911 235.224 65.6016 235.204 65.3982L235.541 49.5487L235.204 34.1319C235.224 33.9285 235.316 33.739 235.464 33.5979C235.612 33.4567 235.805 33.3734 236.009 33.3628H242.798C243.002 33.3734 243.196 33.4567 243.344 33.5979C243.491 33.739 243.584 33.9285 243.603 34.1319L243.267 49.5487L243.603 65.3982C243.584 65.6016 243.491 65.7911 243.344 65.9322C243.196 66.0733 243.002 66.1567 242.798 66.1673H236.009ZM236.141 29.7939C235.941 29.7834 235.75 29.7013 235.605 29.5624C235.459 29.4235 235.368 29.2371 235.348 29.0369V23.0288C235.365 22.8264 235.455 22.6372 235.601 22.4959C235.747 22.3545 235.939 22.2706 236.141 22.2598H242.666C242.87 22.2703 243.064 22.3537 243.212 22.4948C243.359 22.636 243.451 22.8254 243.471 23.0288V29.0369C243.449 29.2381 243.355 29.4247 243.208 29.5634C243.06 29.7021 242.868 29.7838 242.666 29.7939H236.141Z"
                fill="black"
            />
            <path
                d="M267.033 66.9373C256.446 66.9373 250.21 60.2442 250.21 49.8621C250.21 39.4801 256.398 32.5947 267.189 32.5947C275.709 32.5947 281.176 37.2451 282.041 44.1304C282.044 44.2292 282.028 44.3277 281.993 44.4201C281.957 44.5125 281.904 44.5969 281.836 44.6685C281.768 44.7401 281.686 44.7973 281.595 44.837C281.505 44.8766 281.407 44.8979 281.308 44.8994H275.516C275.294 44.903 275.078 44.8277 274.907 44.6869C274.735 44.546 274.619 44.3488 274.579 44.1304C274.305 42.3937 273.399 40.8194 272.035 39.7103C270.671 38.6012 268.945 38.0356 267.189 38.1223C261.265 38.1223 258.465 42.2077 258.465 49.8501C258.465 57.7448 261.325 61.5059 267.189 61.566C271.323 61.566 274.315 59.1627 274.915 54.5004C274.936 54.2869 275.037 54.0893 275.198 53.9467C275.358 53.8042 275.566 53.7274 275.781 53.7314H281.789C281.888 53.733 281.985 53.7541 282.076 53.7938C282.166 53.8334 282.248 53.8907 282.316 53.9623C282.385 54.0339 282.438 54.1183 282.473 54.2107C282.508 54.3031 282.525 54.4016 282.522 54.5004C281.753 61.7102 275.829 66.9373 267.033 66.9373Z"
                fill="black"
            />
            <path
                d="M319.509 54.957C319.605 54.9598 319.699 54.9824 319.786 55.0233C319.873 55.0642 319.95 55.1225 320.013 55.1947C320.076 55.2669 320.124 55.3514 320.153 55.4428C320.182 55.5343 320.192 55.6307 320.182 55.7261C319.173 61.9025 313.585 66.9373 304.561 66.9373C293.506 66.9373 287.51 59.8597 287.51 49.8621C287.51 39.4801 293.842 32.5947 304.333 32.5947C314.823 32.5947 320.915 39.3479 321.155 50.439C321.138 50.6401 321.048 50.8279 320.902 50.9672C320.756 51.1066 320.564 51.1879 320.362 51.1959H295.585C295.921 58.2014 298.913 61.518 304.645 61.518C308.766 61.518 311.506 59.6674 312.504 55.7261C312.565 55.5079 312.696 55.3154 312.875 55.1769C313.055 55.0384 313.274 54.9613 313.501 54.957H319.509ZM304.333 37.954C299.526 37.954 296.666 40.7538 295.801 46.041H312.455C312.119 41.7752 309.524 37.954 304.333 37.954Z"
                fill="black"
            />
            <path
                d="M345.609 66.8065C343.662 66.9068 341.72 66.5366 339.946 65.7271C338.172 64.9176 336.62 63.6928 335.419 62.1562L335.624 76.2392C335.604 76.4414 335.511 76.6294 335.363 76.7685C335.215 76.9077 335.022 76.9886 334.819 76.9963H328.029C327.829 76.9857 327.638 76.9036 327.493 76.7647C327.347 76.6258 327.256 76.4394 327.236 76.2392L327.561 52.4109L327.236 34.1342C327.253 33.9318 327.343 33.7425 327.489 33.6012C327.635 33.4598 327.827 33.376 328.029 33.3651H334.626C334.829 33.376 335.021 33.4598 335.167 33.6012C335.313 33.7425 335.402 33.9318 335.419 34.1342L335.287 37.8232C336.495 36.1822 338.086 34.8605 339.92 33.9727C341.754 33.0849 343.777 32.6577 345.813 32.7282C355.27 32.7282 360.329 40.1183 360.329 49.6712C360.329 59.2242 354.802 66.8065 345.609 66.8065ZM343.482 61.327C349.274 61.327 352.134 57.1213 352.134 50.1158C352.134 42.6657 349.346 38.2678 343.615 38.2678C338.291 38.2678 335.203 42.0889 335.095 49.4789C335.059 57.0612 338.015 61.327 343.47 61.327H343.482Z"
                fill="black"
            />
            <path
                d="M367.779 66.1673C367.577 66.1564 367.385 66.0725 367.239 65.9312C367.093 65.7898 367.003 65.6005 366.986 65.3982L367.323 49.5487L366.986 34.1319C367.003 33.9296 367.093 33.7402 367.239 33.5989C367.385 33.4575 367.577 33.3737 367.779 33.3628H374.581C374.783 33.3737 374.975 33.4575 375.121 33.5989C375.267 33.7402 375.357 33.9296 375.374 34.1319L375.037 49.5487L375.374 65.3982C375.357 65.6005 375.267 65.7898 375.121 65.9312C374.975 66.0725 374.783 66.1564 374.581 66.1673H367.779ZM367.912 29.7939C367.711 29.7834 367.52 29.7013 367.375 29.5624C367.229 29.4235 367.138 29.2371 367.119 29.0369V23.0288C367.136 22.8264 367.225 22.6372 367.371 22.4959C367.517 22.3545 367.709 22.2706 367.912 22.2598H374.449C374.651 22.2706 374.843 22.3545 374.989 22.4959C375.135 22.6372 375.224 22.8264 375.242 23.0288V29.0369C375.222 29.2371 375.131 29.4235 374.985 29.5624C374.84 29.7013 374.649 29.7834 374.449 29.7939H367.912Z"
                fill="black"
            />
            <path
                d="M401.473 66.8063C399.527 66.9057 397.587 66.5349 395.815 65.7254C394.044 64.916 392.493 63.6916 391.295 62.156L391.487 76.239C391.467 76.4392 391.376 76.6256 391.231 76.7645C391.085 76.9034 390.895 76.9855 390.694 76.9961H383.893C383.692 76.9855 383.501 76.9034 383.356 76.7645C383.21 76.6256 383.119 76.4392 383.1 76.239L383.436 52.4107L383.1 34.134C383.117 33.9316 383.206 33.7423 383.352 33.601C383.498 33.4596 383.69 33.3758 383.893 33.3649H390.49C390.694 33.3754 390.887 33.4588 391.035 33.6C391.183 33.7411 391.275 33.9306 391.295 34.134L391.151 37.823C392.359 36.1827 393.95 34.8616 395.784 33.9739C397.618 33.0861 399.64 32.6585 401.677 32.728C411.134 32.728 416.193 40.1181 416.193 49.671C416.193 59.224 410.665 66.8063 401.473 66.8063ZM399.346 61.3268C405.138 61.3268 408.009 57.1211 408.009 50.1156C408.009 42.6655 405.21 38.2676 399.478 38.2676C394.155 38.2676 391.066 42.0887 390.958 49.4787C390.886 57.061 393.938 61.3268 399.346 61.3268Z"
                fill="black"
            />
            <path
                d="M453.481 54.957C453.577 54.958 453.672 54.9794 453.76 55.0197C453.847 55.06 453.926 55.1183 453.989 55.1909C454.053 55.2635 454.1 55.3487 454.128 55.4409C454.157 55.5332 454.165 55.6303 454.153 55.7261C453.156 61.9025 447.556 66.9373 438.532 66.9373C427.489 66.9373 421.493 59.8597 421.493 49.8621C421.493 39.4801 427.814 32.5947 438.316 32.5947C448.818 32.5947 454.898 39.3479 455.139 50.439C455.121 50.6401 455.031 50.8279 454.885 50.9672C454.739 51.1066 454.547 51.1879 454.346 51.1959H429.508C429.844 58.2014 432.836 61.518 438.568 61.518C442.702 61.518 445.43 59.6674 446.427 55.7261C446.489 55.5079 446.619 55.3154 446.799 55.1769C446.978 55.0384 447.198 54.9613 447.424 54.957H453.481ZM438.304 37.954C433.497 37.954 430.649 40.7538 429.772 46.041H446.427C446.091 41.7752 443.495 37.954 438.304 37.954Z"
                fill="black"
            />
            <path
                d="M58.6856 24.2763L68.8754 14.0865C69.1302 13.8332 69.3303 13.5303 69.4636 13.1967C69.5968 12.863 69.6603 12.5056 69.6501 12.1465C69.64 11.7874 69.5565 11.4342 69.4047 11.1086C69.2528 10.783 69.0359 10.4919 68.7673 10.2533C60.6746 3.17584 50.1496 -0.482075 39.4118 0.0510656C28.6741 0.584206 18.5631 5.26668 11.2113 13.111C3.8595 20.9553 -0.158484 31.3483 0.00478798 42.098C0.16806 52.8478 4.49977 63.1138 12.0864 70.7313L1.88461 80.9212C1.6318 81.1743 1.43328 81.4764 1.30117 81.8089C1.16907 82.1414 1.10616 82.4974 1.11628 82.855C1.1264 83.2127 1.20937 83.5645 1.36007 83.889C1.51077 84.2135 1.72606 84.5038 1.99279 84.7423C10.0844 91.819 20.6078 95.4771 31.3442 94.9452C42.0806 94.4133 52.1909 89.733 59.5434 81.8912C66.8959 74.0493 70.9159 63.6588 70.7559 52.9104C70.5959 42.1621 66.2683 31.8958 58.6856 24.2763ZM48.0873 38.1551C52.0495 42.9645 54.0115 49.1129 53.5673 55.3284C53.123 61.5438 50.3066 67.3507 45.7006 71.5476C41.0946 75.7446 35.0515 78.0104 28.8217 77.8764C22.5918 77.7423 16.6519 75.2186 12.2307 70.8274L22.5887 60.4694C23.0606 59.9955 23.3363 59.3611 23.3608 58.6928C23.3853 58.0246 23.1568 57.3716 22.7209 56.8645C18.8058 52.0459 16.8849 45.9107 17.353 39.7197C17.8211 33.5287 20.6427 27.7521 25.2378 23.5769C29.833 19.4018 35.8527 17.1451 42.0601 17.2707C48.2675 17.3963 54.1911 19.8946 58.6136 24.2523L48.2555 34.5983C47.7995 35.0709 47.5339 35.6951 47.5095 36.3514C47.4851 37.0077 47.7037 37.65 48.1234 38.1551H48.0873Z"
                fill="url(#paint0_linear_5960_231946)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5960_231946"
                    x1="4.05959"
                    y1="106.624"
                    x2="75.5325"
                    y2="-28.1267"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.22" stopColor="#00E9C6" />
                    <stop offset="0.42" stopColor="#00DDC8" />
                    <stop offset="0.72" stopColor="#01CDCB" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

export function LogoMiniIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 34 45" style={{ height: 34, width: 45 }}>
            <path
                d="M27.934 11.4917L32.7938 6.65686C32.9142 6.53687 33.0088 6.39371 33.0717 6.23613C33.1347 6.07855 33.1646 5.90986 33.1598 5.74036C33.155 5.57087 33.1155 5.40413 33.0437 5.25034C32.9719 5.09655 32.8693 4.95894 32.7423 4.84592C28.8852 1.50926 23.8783 -0.210149 18.7737 0.0489966C13.669 0.308142 8.86401 2.52567 5.36837 6.2356C1.87274 9.94554 -0.0413901 14.8591 0.0282003 19.9437C0.0977908 25.0283 2.14569 29.8881 5.74156 33.502L0.881754 38.3368C0.761326 38.4568 0.666759 38.6 0.603828 38.7575C0.540897 38.9151 0.510929 39.0838 0.515751 39.2533C0.520573 39.4228 0.560094 39.5895 0.631883 39.7433C0.703671 39.8971 0.806213 40.0347 0.93327 40.1478C4.79033 43.4844 9.79725 45.2038 14.9019 44.9447C20.0066 44.6855 24.8116 42.468 28.3072 38.7581C31.8028 35.0481 33.717 30.1346 33.6474 25.05C33.5778 19.9654 31.5299 15.1056 27.934 11.4917ZM22.8911 18.0635C24.725 20.3506 25.6145 23.2467 25.3789 26.163C25.1432 29.0793 23.8001 31.7968 21.6224 33.7631C19.4448 35.7294 16.5962 36.7968 13.6558 36.7483C10.7154 36.6998 7.90397 35.539 5.79307 33.502L10.7215 28.5988C10.9475 28.3749 11.0798 28.074 11.0914 27.7569C11.1031 27.4398 10.9934 27.1301 10.7845 26.8904C8.9077 24.6075 7.98339 21.6949 8.20233 18.7538C8.42127 15.8126 9.76676 13.0673 11.9611 11.0844C14.1555 9.10148 17.0313 8.03229 19.995 8.09749C22.9587 8.16269 25.7841 9.35729 27.8882 11.4348L22.954 16.3436C22.7235 16.5674 22.5883 16.8708 22.5766 17.191C22.5649 17.5112 22.6775 17.8236 22.8911 18.0635Z"
                fill="url(#paint0_linear_5943_48213)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5943_48213"
                    x1="1.90066"
                    y1="50.5122"
                    x2="35.6752"
                    y2="-13.4927"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.22" stopColor="#00E9C6" />
                    <stop offset="0.42" stopColor="#00DDC8" />
                    <stop offset="0.72" stopColor="#01CDCB" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

export function UserIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 22" style={{ height: 22, width: 20 }}>
            <path
                d="M13.7105 11.7096C14.6909 10.9383 15.4065 9.88056 15.7577 8.68358C16.109 7.4866 16.0784 6.2099 15.6703 5.0311C15.2621 3.85231 14.4967 2.83003 13.4806 2.10649C12.4644 1.38296 11.2479 0.994141 10.0005 0.994141C8.75303 0.994141 7.5366 1.38296 6.52041 2.10649C5.50423 2.83003 4.73883 3.85231 4.3307 5.0311C3.92257 6.2099 3.892 7.4866 4.24325 8.68358C4.59449 9.88056 5.31009 10.9383 6.29048 11.7096C4.61056 12.3827 3.14477 13.499 2.04938 14.9396C0.953983 16.3801 0.270048 18.0909 0.070485 19.8896C0.0560396 20.021 0.0676015 20.1538 0.10451 20.2807C0.141419 20.4075 0.202952 20.5259 0.285596 20.629C0.452504 20.8371 0.695269 20.9705 0.960485 20.9996C1.2257 21.0288 1.49164 20.9514 1.69981 20.7845C1.90798 20.6176 2.04131 20.3749 2.07049 20.1096C2.29007 18.1548 3.22217 16.3494 4.6887 15.0384C6.15524 13.7274 8.05338 13.0027 10.0205 13.0027C11.9876 13.0027 13.8857 13.7274 15.3523 15.0384C16.8188 16.3494 17.7509 18.1548 17.9705 20.1096C17.9977 20.3554 18.1149 20.5823 18.2996 20.7467C18.4843 20.911 18.7233 21.0011 18.9705 20.9996H19.0805C19.3426 20.9695 19.5822 20.8369 19.747 20.6309C19.9119 20.4248 19.9886 20.162 19.9605 19.8996C19.76 18.0958 19.0724 16.3806 17.9713 14.9378C16.8703 13.4951 15.3974 12.3791 13.7105 11.7096V11.7096ZM10.0005 10.9996C9.20936 10.9996 8.436 10.765 7.7782 10.3255C7.12041 9.88599 6.60772 9.26128 6.30497 8.53037C6.00222 7.79947 5.923 6.9952 6.07734 6.21928C6.23168 5.44335 6.61265 4.73062 7.17206 4.17121C7.73147 3.6118 8.4442 3.23084 9.22012 3.0765C9.99605 2.92215 10.8003 3.00137 11.5312 3.30412C12.2621 3.60687 12.8868 4.11956 13.3264 4.77736C13.7659 5.43515 14.0005 6.20851 14.0005 6.99964C14.0005 8.0605 13.5791 9.07792 12.8289 9.82806C12.0788 10.5782 11.0614 10.9996 10.0005 10.9996Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
