import React from 'react';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { useLocation } from 'react-router';
import { Typography } from '@material-ui/core';

const ResetPasswordDone = () => {
    const classes = useStyles();
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const email = searchParams.get('email');

    return (
        <AuthWrapper>
            <Typography variant="h1" className={classes.title}>
                Done!
            </Typography>
            <div className={classes.description}>
                <p>
                    Password reset instructions have been sent to<span>{email}</span>
                </p>
                <p>If you didn’t receive the email, check the spam folder or contact us.</p>
            </div>

            <Link to={routes.auth} className={classes.link}>
                RETURN TO LOG IN
            </Link>
        </AuthWrapper>
    );
};

export default ResetPasswordDone;
