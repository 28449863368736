import React, { useContext, useRef, useState } from 'react';
import { AntTableCell, AntTableCellFilter } from '../../../theme/Table/index';
import { Table, TableBody, TableContainer, TableHead, TableRow, Grid, Typography } from '@material-ui/core';
import TableScroll from '../../../components/global/table/TableScroll';
import ModalMore from '../../../components/modals/modalsTable/more';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/table/TableFilter';
import { GET_ARBOR_RESOURCES } from '../../../actions/get';
import { useLazyQuery } from '@apollo/client';
import Preloader from '../../../components/global/Preloader/index';
import { PageContext } from '../../../context/pages';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import L3Resource from './Resource';
import TableSort from '../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const Resources = () => {
    const table = useRef(null);
    const [sortActive, setSortActive] = useState('sortId');
    const [anchorEl, setAnchorEl] = useState(null);
    const [ipsPopover, setIpsPopover] = useState([]);

    const { filter, setFilter } = useContext(PageContext);

    const [getFilter, { data, loading, error }] = useLazyQuery(GET_ARBOR_RESOURCES);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            deletedAt: null,
            name: filter?.name || '',
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);
        if (filter?.partnerClientAccountId) proxy.partnerClientAccountId = Number(filter?.partnerClientAccountId);
        return proxy;
    };

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables(), fetchPolicy: 'network-only' });
        },
        500,
        [filter]
    );

    if (loading) return <Preloader />;
    if (error) return 'errr';

    const open = Boolean(anchorEl);
    const idPopover = open ? 'popover-more' : undefined;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(Math, data && data.arborResources.items.map(items => textWidthCalculation(items.name))),
        partnerClientAccountId: Math.max.apply(
            Math,
            data && data.arborResources.items.map(items => textWidthCalculation(items.partnerClientAccountId))
        ),
    };

    return (
        <>
            <ModalMore
                id={idPopover}
                open={open}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                value={ipsPopover}
                title="Ips"
            />
            <TableScroll tableRef={table}>
                <TableContainer>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1">Network protection resource list</Typography>
                        </Grid>
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>
                                    <TableSort
                                        name="sortId"
                                        direction={filter?.sort?.sortId}
                                        label="ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>Resource Name</AntTableCell>
                                <AntTableCell>Protected IPs</AntTableCell>
                                <AntTableCell>
                                    <TableSort
                                        name="sortAccountId"
                                        direction={filter?.sort?.sortAccountId}
                                        label=" Account ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>
                                    <TableSort
                                        name="sortCreatedAt"
                                        direction={filter?.sort?.sortCreatedAt}
                                        label="Created At"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="id"
                                        maxWidth={maxWidth.id}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="name"
                                        maxWidth={maxWidth.name}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="partnerClientAccountId"
                                        maxWidth={maxWidth.partnerClientAccountId}
                                        setFilter={setFilter}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.arborResources.items.length !== 0 ? (
                                data.arborResources.items.map(
                                    ({ id, name, ips, createdAt, partnerClientAccountId }) => (
                                        <L3Resource
                                            key={id}
                                            id={id}
                                            name={name}
                                            ips={ips}
                                            setAnchorEl={setAnchorEl}
                                            setIpsPopover={setIpsPopover}
                                            partnerClientAccountId={partnerClientAccountId}
                                            createdAt={createdAt}
                                        />
                                    )
                                )
                            ) : (
                                <TableNoDataComponent colSpan={5} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.arborResources.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
            </TableScroll>
        </>
    );
};
export default Resources;
