import React, { useContext, useEffect, useRef, useState } from 'react';
import { AntTableCell, AntTableCellFilter } from '../../theme/Table';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
} from '@material-ui/core';
import TableScroll from '../../components/global/table/TableScroll';
import { AntContainer } from '../../theme/Wrapper';
import { textWidthCalculation } from '../../helpers/calculation';
import PopoverBar from './PopoverBar';
import LoginEmployeePopover from './LoginEmployeePopover';
import ModalDeleteAccount from '../../components/modals/modalsAccounts/deleteAccount';
import ModalCreateAccount from '../../components/modals/modalsAccounts/createAccount';
import TablePaginationComponent from '../../components/global/table/TablePagination';
import { GET_PARTNER_CLIENT_ACCOUNTS } from '../../actions/get';
import { PageContext } from '../../context/pages';
import Preloader from '../../components/global/Preloader';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { useLazyQuery } from '@apollo/client';
import TableFilter from '../../components/global/table/TableFilter';
import { AuthContext } from '../../context/auth';
import Account from './Account';
import TableSort from '../../components/global/table/TableSort';
import TableNoDataComponent from '../../components/global/table/TableNoData';

const Accounts = () => {
    const table = useRef(null);

    const { user } = useContext(AuthContext);
    const { filter, setFilter } = useContext(PageContext);
    const [sortActive, setSortActive] = useState('sortId');

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElPopover, setAnchorElPopover] = useState(null);

    const [index, setIndex] = useState({
        id: '',
        name: '',
    });
    const [emails, setEmails] = useState([]);

    const [isCreated, setIsCreated] = useState(false);
    const [isDeleted, setIsDeleted] = useState({
        deleted: false,
        id: '',
        name: '',
    });

    const [getFilter, { data, loading }] = useLazyQuery(GET_PARTNER_CLIENT_ACCOUNTS);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            name: filter?.name,
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.id) proxy.id = Number(filter?.id);
        if (!filter?.sort?.checked) proxy.deletedAt = null;
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );

    if (loading) return <Preloader />;

    const maxWidth = {
        id: Math.max.apply(Math, data && data.partnerClientAccounts.items.map(items => textWidthCalculation(items.id))),
        name: Math.max.apply(
            Math,
            data && data.partnerClientAccounts.items.map(items => textWidthCalculation(items.name))
        ),
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClosePopover = () => {
        setAnchorElPopover(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'accountList' : undefined;

    const openPopover = Boolean(anchorElPopover);
    const idPopover = openPopover ? 'loginEmployee' : undefined;

    return (
        <AntContainer maxWidth={false}>
            <ModalCreateAccount
                show={isCreated}
                hideModal={() => setIsCreated(false)}
                query={GET_PARTNER_CLIENT_ACCOUNTS}
                getVariables={getVariables()}
                partnerAccountId={user && user.partnerAccountId}
            />
            <ModalDeleteAccount
                show={isDeleted.deleted}
                hideModal={() => setIsDeleted(!isDeleted.deleted)}
                id={isDeleted.id}
                name={isDeleted.name}
                query={GET_PARTNER_CLIENT_ACCOUNTS}
                getVariables={getVariables()}
            />
            <TableScroll tableRef={table}>
                <TableContainer ref={table}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h1">Accounts</Typography>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <FormControlLabel
                                style={{ marginRight: 40 }}
                                control={
                                    <Checkbox
                                        checked={filter?.sort?.checked || false}
                                        onChange={event =>
                                            setFilter({
                                                ...filter,
                                                sort: { ...filter?.sort, checked: event.target.checked },
                                            })
                                        }
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Show deleted accounts"
                            />
                            <Button color="primary" variant="contained" size="small" onClick={() => setIsCreated(true)}>
                                New account
                            </Button>
                        </Grid>
                    </Grid>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <AntTableCell>
                                    <TableSort
                                        name="sortId"
                                        direction={filter?.sort?.sortId}
                                        label="ID"
                                        setFilter={setFilter}
                                        sortActive={sortActive}
                                        setSortActive={setSortActive}
                                        filter={filter}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCell>
                                <AntTableCell>Account Name</AntTableCell>
                                <AntTableCell>Created At</AntTableCell>
                                <AntTableCell>Status</AntTableCell>
                                <AntTableCell />
                            </TableRow>
                            <TableRow>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="id"
                                        setFilter={setFilter}
                                        filter={filter}
                                        maxWidth={maxWidth.id}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter>
                                    <TableFilter
                                        name="name"
                                        setFilter={setFilter}
                                        filter={filter}
                                        maxWidth={maxWidth.name}
                                        type={'FILTER_ACTIVE'}
                                    />
                                </AntTableCellFilter>
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                                <AntTableCellFilter />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.partnerClientAccounts.items.length !== 0 ? (
                                data.partnerClientAccounts.items.map(
                                    ({ id, name, createdAt, deletedAt, partnerClientAccountEmployees }) => (
                                        <Account
                                            key={id}
                                            id={id}
                                            name={name}
                                            createdAt={createdAt}
                                            deletedAt={deletedAt}
                                            partnerClientAccountEmployees={partnerClientAccountEmployees}
                                            setEmails={setEmails}
                                            setAnchorElPopover={setAnchorElPopover}
                                            handleClick={handleClick}
                                            setIndex={setIndex}
                                        />
                                    )
                                )
                            ) : (
                                <TableNoDataComponent colSpan={5} />
                            )}
                        </TableBody>
                    </Table>
                    <TablePaginationComponent
                        filter={filter}
                        setFilter={setFilter}
                        count={data && data.partnerClientAccounts.info.totalCount}
                        getFilter={getFilter}
                        getVariables={getVariables()}
                        type={'FILTER_ACTIVE'}
                    />
                </TableContainer>
                <LoginEmployeePopover
                    id={idPopover}
                    open={openPopover}
                    anchorEl={anchorElPopover}
                    handleClose={handleClosePopover}
                    data={emails}
                    type={'PARTNER_CLIENT_ACCOUNT_EMPLOYEE'}
                />
                <PopoverBar
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    setIsDeleted={setIsDeleted}
                    idAccount={index.id}
                    name={index.name}
                />
            </TableScroll>
        </AntContainer>
    );
};

export default Accounts;
