import React, { useContext, useEffect, useState } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table/index';
import {
    Table,
    TableBody,
    Typography,
    TableContainer,
    TableHead,
    TableRow,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/table/TableFilter';
import TableDatePicker from '../../../components/global/table/TableDatePicker';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import { PageContext } from '../../../context/pages';
import Preloader from '../../../components/global/Preloader';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { useLazyQuery } from '@apollo/client';
import { GET_ARBOR_MITIGATIONS } from '../../../actions/get';
import Mitigation from './Mitigation';
import TableSort from '../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const Mitigations = () => {
    const classes = useStyles();

    const [sortActive, setSortActive] = useState('sortMitigationId');
    const { filter, setFilter } = useContext(PageContext);

    const [getFilter, { data, loading }] = useLazyQuery(GET_ARBOR_MITIGATIONS);

    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            gte: new Date(filter.gte).toISOString(),
            lte: new Date(filter.lte).toISOString(),
            name: filter?.name || '',
            prefix: filter?.prefix || '',
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.arborAlertId) proxy.arborAlertId = Number(filter?.arborAlertId);
        else if (filter?.mitigationId) proxy.mitigationId = Number(filter?.mitigationId);
        if (filter?.sort?.checked) proxy.ongoing = 'true';
        return proxy;
    };

    useEffect(() => {
        getFilter({ variables: getVariables() });
    }, []);

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        1000,
        [filter]
    );
    if (loading) return <Preloader />;

    const maxWidth = {
        mitigationId: Math.max.apply(
            Math,
            data && data.arborMitigations.items.map(items => textWidthCalculation(items.mitigationId))
        ),
        arborAlertId: Math.max.apply(
            Math,
            data && data.arborMitigations.items.map(items => textWidthCalculation(items.arborAlertId))
        ),
        prefix: Math.max.apply(
            Math,
            data && data.arborMitigations.items.map(items => textWidthCalculation(items.prefix))
        ),
        managedObjectName: Math.max.apply(
            Math,
            data && data.arborMitigations.items.map(items => textWidthCalculation(items.managedObjectName))
        ),
    };

    return (
        <>
            <TableContainer>
                <div className={classes.header}>
                    <Typography variant="h1">Mitigations list</Typography>
                    <div className={classes.date}>
                        <FormControlLabel
                            style={{ marginRight: 40 }}
                            control={
                                <Checkbox
                                    checked={filter?.sort?.checked || false}
                                    onChange={event =>
                                        setFilter({
                                            ...filter,
                                            sort: { ...filter?.sort, checked: event.target.checked },
                                        })
                                    }
                                    name="checkedA"
                                    color="primary"
                                />
                            }
                            label="Show only ongoin mitigations"
                        />
                        <Typography style={{ paddingRight: 15 }}>Time period of shown stats</Typography>
                        <TableDatePicker filter={filter} setFilter={setFilter} />
                    </div>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <AntTableCell>
                                <TableSort
                                    name="sortMitigationId"
                                    direction={filter?.sort?.sortMitigationId}
                                    label="ID"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortAnomalyId"
                                    direction={filter?.sort?.sortAnomalyId}
                                    label="Anomaly ID"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>Attacted IPs</AntTableCell>
                            <AntTableCell>Resource Name</AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortStart"
                                    direction={filter?.sort?.sortStart}
                                    label="Started At"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortStop"
                                    direction={filter?.sort?.sortStop}
                                    label="Ended At"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCell>
                                <TableSort
                                    name="sortDuration"
                                    direction={filter?.sort?.sortDuration}
                                    label="Duration"
                                    setFilter={setFilter}
                                    sortActive={sortActive}
                                    setSortActive={setSortActive}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCell>
                            <AntTableCellFilter />
                        </TableRow>

                        <TableRow>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="mitigationId"
                                    maxWidth={maxWidth.mitigationId}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="arborAlertId"
                                    maxWidth={maxWidth.arborAlertId}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="prefix"
                                    maxWidth={maxWidth.prefix}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter>
                                <TableFilter
                                    name="name"
                                    maxWidth={maxWidth.managedObjectName}
                                    setFilter={setFilter}
                                    filter={filter}
                                    type={'FILTER_ACTIVE'}
                                />
                            </AntTableCellFilter>
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                            <AntTableCellFilter />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.arborMitigations.items.length !== 0 ? (
                            data.arborMitigations.items.map(
                                ({
                                    id,
                                    mitigationId,
                                    arborAlertId,
                                    start,
                                    stop,
                                    prefix,
                                    managedObjectName,
                                    duration,
                                }) => (
                                    <Mitigation
                                        key={mitigationId}
                                        mitigationId={mitigationId}
                                        arborAlertId={arborAlertId}
                                        start={start}
                                        stop={stop}
                                        prefix={prefix}
                                        managedObjectName={managedObjectName}
                                        duration={duration}
                                        id={id}
                                    />
                                )
                            )
                        ) : (
                            <TableNoDataComponent colSpan={8} />
                        )}
                    </TableBody>
                </Table>
                <TablePaginationComponent
                    filter={filter}
                    setFilter={setFilter}
                    count={data && data.arborMitigations.info.totalCount}
                    getFilter={getFilter}
                    getVariables={getVariables()}
                    type={'FILTER_ACTIVE'}
                />
            </TableContainer>
        </>
    );
};
export default Mitigations;
