import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { CustomSwitchOnOff } from '../../../theme/Switch';
import { TextField, Typography, Grid, TableContainer } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import TableL7Resources from './TableL7Resources';
import TableScroll from '../../../components/global/table/TableScroll';
import { PageContext } from '../../../context/pages';
import { toast } from 'react-toastify';
import { GET_L7_RESOURCES_VENDOR, GET_PARTNER_CLIENT_ACCOUNT } from '../../../actions/get';
import { UPDATE_ACCOUNT_SETTINGS } from '../../../actions/update';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import Preloader from '../../../components/global/Preloader';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import ModalSwitchOffResource from '../../../components/modals/modalsL7Protection/switchOffResource';
import ModalSwitchOnResource from '../../../components/modals/modalsL7Protection/switchOnResource';

const L7ProtectionAccount = ({ dataPartnerClientAccount }) => {
    const classes = useStyles();
    const table = useRef(null);
    const [switches, setSwitches] = useState(false);

    const { id: partnerClientAccountId } = useParams();
    const { filter, setFilter } = useContext(PageContext);
    const [sortActive, setSortActive] = useState('sortL7ResourceId');

    const [isSwitchOn, setIsSwitchOn] = useState({
        switchOn: false,
        l7ResourceId: '',
        name: '',
    });
    const [isSwitchOff, setIsSwitchOff] = useState({
        switchOff: false,
        l7ResourceId: '',
        name: '',
    });

    const [updateAccountSettings] = useMutation(UPDATE_ACCOUNT_SETTINGS);

    const update = dataInput => {
        updateAccountSettings({
            variables: {
                id: dataPartnerClientAccount.accountSettings.id,
                l3Limit: dataPartnerClientAccount.accountSettings.l3.l3Limit,
                l7Limit: Number(dataInput),
                dosGateLimit: dataPartnerClientAccount.accountSettings.dosGate.dosGateLimit,
            },
            refetchQueries: [
                {
                    query: GET_PARTNER_CLIENT_ACCOUNT,
                    variables: {
                        id: dataPartnerClientAccount.id,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const [getFilter, { data, loading }] = useLazyQuery(GET_L7_RESOURCES_VENDOR);
    const getVariables = () => {
        const proxy = {
            limit: filter.limit,
            page: filter.page + 1,
            l7ResourceName: filter?.l7ResourceName || '',
            serviceIp: filter?.serviceIp || '',
            deletedAt: null,
            partnerClientAccountId: Number(partnerClientAccountId),
            [sortActive]: filter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
        };
        if (filter?.l7ResourceId) proxy.l7ResourceId = Number(filter?.l7ResourceId);
        return proxy;
    };

    useDebouncedEffect(
        () => {
            getFilter({ variables: getVariables() });
        },
        500,
        [filter]
    );

    useEffect(() => {
        if (dataPartnerClientAccount && dataPartnerClientAccount.accountSettings.l7.l7Limit >= 1) {
            setSwitches(true);
        } else {
            setSwitches(false);
        }
    }, [dataPartnerClientAccount]);

    if (loading) return <Preloader />;

    const handleChange = event => {
        setSwitches(event.target.checked);
        if (event.target.checked === true) {
            update(1);
        } else {
            update(0);
        }
    };

    return (
        <>
            <ModalSwitchOffResource
                show={isSwitchOff.switchOff}
                hideModal={() => setIsSwitchOff(!isSwitchOff.switchOff)}
                l7ResourceId={isSwitchOff.l7ResourceId}
                name={isSwitchOff.name}
                partnerClientAccountId={isSwitchOff.partnerClientAccountId}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <ModalSwitchOnResource
                show={isSwitchOn.switchOn}
                hideModal={() => setIsSwitchOn(!isSwitchOn.switchOn)}
                l7ResourceId={isSwitchOn.l7ResourceId}
                name={isSwitchOn.name}
                partnerClientAccountId={isSwitchOff.partnerClientAccountId}
                query={GET_L7_RESOURCES_VENDOR}
                getVariables={getVariables()}
            />
            <Typography variant="h1" className={classes.title}>
                Application protection service
            </Typography>
            <div className={classes.checkedBox}>
                <Typography>Application protection service visibility</Typography>
                <CustomSwitchOnOff
                    name="l3service"
                    checked={switches}
                    onChange={handleChange}
                    className={classes.switch}
                />
            </div>
            <Typography variant="h1" style={{ marginBottom: 15 }}>
                Resource limit:
            </Typography>
            <Grid container>
                <Grid item xs>
                    <div className={classes.inputWrapper}>
                        {dataPartnerClientAccount && (
                            <TextField
                                defaultValue=""
                                placeholder={
                                    dataPartnerClientAccount.accountSettings.l7.l7Limit === null
                                        ? 0
                                        : dataPartnerClientAccount.accountSettings.l7.l7Limit
                                }
                                disabled={!switches}
                                onBlur={event => {
                                    if (event.target.value !== '') {
                                        update(event.target.value);
                                    }
                                }}
                                variant="outlined"
                                className={classes.input}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <div>
                <TableScroll tableRef={table}>
                    <TableContainer>
                        <TableL7Resources
                            data={data}
                            setFilter={setFilter}
                            filter={filter}
                            setIsSwitchOff={setIsSwitchOff}
                            setIsSwitchOn={setIsSwitchOn}
                            sortActive={sortActive}
                            setSortActive={setSortActive}
                        />
                        <TablePaginationComponent
                            filter={filter}
                            setFilter={setFilter}
                            count={data && data.l7ResourcesVendor.info.totalCount}
                            getFilter={getFilter}
                            getVariables={getVariables()}
                            type={'FILTER_ACTIVE'}
                        />
                    </TableContainer>
                </TableScroll>
            </div>
        </>
    );
};
export default L7ProtectionAccount;
