import React from 'react';
import { ToastContainer } from 'react-toastify';

const Layout = ({ children }) => {
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ToastContainer position="bottom-right" autoClose={8000} />
            {children}
        </div>
    );
};

export default Layout;
