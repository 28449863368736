import React, { useContext, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { GlobalCss } from './common/theme';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { PageProvider } from './common/context/pages';
import { AuthContext } from './common/context/auth';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout from './common/components/global/Layout';
import routes from './common/routes';
import AuthRoute from './common/components/global/route/AuthRoute';
import MyPrivateRoute from './common/components/global/route/MyPrivateRoute';
import Auth from './common/pages/auth';
import JoinAccountFirst from './common/pages/joinAccount/firstStep';
import JoinAccountSecond from './common/pages/joinAccount/secondStep';
import JoinAccountDisabledToken from './common/pages/joinAccount/disabledToken';
import ForgotPassword from './common/pages/forgotPassword';
import ResetPassword from './common/pages/forgotPassword/resetPassword';
import ResetPasswordDone from './common/pages/forgotPassword/done';
import MyProfile from './common/pages/myProfile';
import Accounts from './common/pages/accounts';
import AccountEdit from './common/pages/accountEdit';
import AccountMembersEdit from './common/pages/accountMembersEdit';
import Users from './common/pages/users';
import L3Protection from './common/pages/l3Protection';
import AnomalyDetails from './common/pages/l3Protection/anomalies/anomalyDetails';
import MitigationsDetails from './common/pages/l3Protection/mitigations/mitigationsDetails';
import L7Protection from './common/pages/l7Protection';
import useInterval from 'use-interval';
import { scheme } from './common/scheme';
import NotFound from './common/pages/notFound';

const App = () => {
    const theme = createMuiTheme(scheme());
    const { user } = useContext(AuthContext);
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const token = searchParams.get('tokenByEmployee');
    const navigate = useNavigate();
    const context = useContext(AuthContext);

    const startTime = new Date();
    useInterval(
        () => {
            const curTime = new Date();
            const delta = curTime.getTime() - startTime.getTime();
            if (delta > 82800000) {
                context.logout();
            }
        },
        user ? 1200000 : null
    );

    useEffect(() => {
        if (token) {
            context.login(token);
            navigate('/my-profile/0');
        }
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <GlobalCss />
            <Layout>
                <PageProvider>
                    <Routes>
                        <Route
                            exact
                            path={routes.auth}
                            element={
                                <AuthRoute>
                                    <Auth />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.joinAccountFirst}
                            element={
                                <AuthRoute>
                                    <JoinAccountFirst />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.joinAccountSecond}
                            element={
                                <AuthRoute>
                                    <JoinAccountSecond />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.joinAccountDisabled}
                            element={
                                <AuthRoute>
                                    <JoinAccountDisabledToken />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.forgotPassword}
                            element={
                                <AuthRoute>
                                    <ForgotPassword />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.resetPassword}
                            element={
                                <AuthRoute>
                                    <ResetPassword />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.resetPasswordDone}
                            element={
                                <AuthRoute>
                                    <ResetPasswordDone />
                                </AuthRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.myProfile}
                            element={
                                <MyPrivateRoute>
                                    <MyProfile />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.accounts}
                            element={
                                <MyPrivateRoute>
                                    <Accounts />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.accountEdit}
                            element={
                                <MyPrivateRoute>
                                    <AccountEdit />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.accountMembersEdit}
                            element={
                                <MyPrivateRoute>
                                    <AccountMembersEdit />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.users}
                            element={
                                <MyPrivateRoute>
                                    <Users />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.netProtection}
                            element={
                                <MyPrivateRoute>
                                    <L3Protection />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.anomalyDetails}
                            element={
                                <MyPrivateRoute>
                                    <AnomalyDetails />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.mitigationsDetails}
                            element={
                                <MyPrivateRoute>
                                    <MitigationsDetails />
                                </MyPrivateRoute>
                            }
                        />
                        <Route
                            exact
                            path={routes.appProtection}
                            element={
                                <MyPrivateRoute>
                                    <L7Protection />
                                </MyPrivateRoute>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </PageProvider>
            </Layout>
        </MuiThemeProvider>
    );
};

export default App;
