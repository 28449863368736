import React from 'react';
import { AntTableCell, useStyles } from '../../../../theme/Table';
import { Button, TableRow } from '@material-ui/core';
import { Description, MoreHoriz } from '@material-ui/icons';
import dateFormat from 'dateformat';
import { valueCalculation, valueTimeCalculation } from '../../../../helpers/calculation';
import { Link } from 'react-router-dom';

const Anomaly = ({
    alertId,
    importance,
    alertResourceCidr,
    arborResource,
    misuseTypes,
    start,
    stop,
    duration,
    maxImpactBps,
    maxImpactPps,
    setAnchorElMore,
    setTypePopover,
    stateSwitch,
}) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {alertId}
            </AntTableCell>
            <AntTableCell>{importance}</AntTableCell>
            <AntTableCell>{alertResourceCidr}</AntTableCell>
            <AntTableCell>
                {misuseTypes.length === 1 ? (
                    misuseTypes[0]
                ) : misuseTypes.length === 0 ? (
                    '-'
                ) : (
                    <div>
                        {misuseTypes[0]}
                        <Button
                            className={classes.buttonMore}
                            aria-describedby={alertId}
                            onClick={event => {
                                setAnchorElMore(event.currentTarget);
                                setTypePopover(misuseTypes);
                            }}
                        >
                            <span className={classes.buttonMoreSpan}>
                                <MoreHoriz className={classes.buttonMoreIcon} viewBox="3 0 25 5" /> more
                            </span>
                        </Button>
                    </div>
                )}
            </AntTableCell>
            <AntTableCell>{arborResource?.name}</AntTableCell>
            <AntTableCell>{dateFormat(start, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell>{stop ? dateFormat(stop, 'dd/mm/yy h:MM:ssTT') : 'Ongoing'}</AntTableCell>
            <AntTableCell>
                {stop ? valueTimeCalculation(duration) : valueTimeCalculation((new Date() - new Date(start)) / 1000)}
            </AntTableCell>
            <AntTableCell>
                {stateSwitch.bps ? valueCalculation(maxImpactBps, 'bps') : valueCalculation(maxImpactPps, 'pps')}
            </AntTableCell>
            <AntTableCell>
                <Link to={`/anomaly-details/0/net-protection/${alertId}/1`}>
                    <Description color="action" className={classes.icon} />
                </Link>
            </AntTableCell>
        </TableRow>
    );
};

export default Anomaly;
