import React from 'react';
import { useStyles } from './styles';
import { LogoFullIcon } from '../../../../assets/img';

const Logo = () => {
    const classes = useStyles();
    return (
        <div className={classes.logo}>
            <LogoFullIcon />
        </div>
    );
};

export default Logo;
