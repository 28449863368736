import React from 'react';
import { TableRow } from '@material-ui/core';
import { AntTableCell, useStyles } from '../../../../theme/Table';
import { DeleteForever } from '@material-ui/icons';

const Member = ({ id, first, last, email, setIsDeleted }) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell>{id}</AntTableCell>
            <AntTableCell>
                {first} {last}
            </AntTableCell>
            <AntTableCell>{email}</AntTableCell>
            <AntTableCell align="right">
                <div>
                    <DeleteForever
                        viewBox="0 0 24 24"
                        className={classes.iconDelete}
                        onClick={() =>
                            setIsDeleted({
                                deleted: true,
                                id,
                                email,
                            })
                        }
                    />
                </div>
            </AntTableCell>
        </TableRow>
    );
};

export default Member;
