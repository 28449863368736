import { makeStyles } from '@material-ui/core/styles';
import { withStyles, MenuItem } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    popover: {
        zIndex: 99999,
    },
    popoverWrapper: {
        background: theme.palette.common.white,
        border: '1px solid',
        borderColor: theme.palette.grey.greyDivider,
    },
    popoverHeader: {
        padding: '11px 40px 3px 16px',
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey.greyDivider,
    },
    popoverBody: {
        padding: '15px 16px 28px 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    userName: {
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: theme.palette.grey.greyHighEmphasis,
    },
    userEmail: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: theme.palette.grey.greyHighEmphasis,
    },
    linkItem: {
        textDecoration: 'none',
        color: theme.palette.grey.greyHighEmphasis,
    },
    linkItemActive: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
    },
    menuItem: {
        padding: 9,
        borderRadius: 4,
        color: theme.palette.grey.greyInactive,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '35px',
    },
}));

export const CustomMenuItem = withStyles(theme => ({
    root: {
        padding: '9px 20px',
        borderRadius: 4,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
        '&:focus': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    },
}))(MenuItem);
