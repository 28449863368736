import React from 'react';
import { useStyles } from './styles';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

const ListPassword = ({ inputVariable, checkUppercase, checkLowercase, checkNumber, checkSpecialCharacter }) => {
    const classes = useStyles();

    return (
        <>
            <List className={classes.list}>
                <ListItem
                    className={`${classes.listItem} ${
                        inputVariable?.split('').length >= 8 ? classes.active : classes.disable
                    }`}
                >
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary="8 characters minimum" />
                </ListItem>
                <ListItem
                    className={`${classes.listItem} ${
                        checkUppercase.test(inputVariable) ? classes.active : classes.disable
                    }`}
                >
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary="one uppercase character" />
                </ListItem>
                <ListItem
                    className={`${classes.listItem} ${
                        checkLowercase.test(inputVariable) ? classes.active : classes.disable
                    }`}
                >
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary="one lowercase character" />
                </ListItem>
                <ListItem
                    className={`${classes.listItem} ${
                        checkNumber.test(inputVariable) ? classes.active : classes.disable
                    }`}
                >
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary="one number" />
                </ListItem>
                <ListItem
                    className={`${classes.listItem} ${
                        checkSpecialCharacter.test(inputVariable) ? classes.active : classes.disable
                    }`}
                >
                    <FiberManualRecord fontSize="inherit" className={classes.listIcon} />
                    <ListItemText primary="one special character" />
                </ListItem>
            </List>
        </>
    );
};

export default ListPassword;
