import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import CardsHead from '../../../../../components/global/cards/CardsHead';
import { useStyles } from './styles';
import { valueCalculation } from '../../../../../helpers/calculation';
import TablesTraffic from './TablesTraffic';

const Traffic = ({ store }) => {
    const classes = useStyles();
    const { id } = useParams();

    const details = store.arborAlerts.items[0];
    const startValue = () => {
        return details.importance === 'low'
            ? 1
            : details.importance === 'medium'
            ? 2
            : details.importance === 'high'
            ? 3
            : '';
    };

    return (
        <div className={classes.wrapper}>
            <Grid container spacing={2} className={classes.headerWrapper}>
                <Grid item xs className={classes.titleWrapper}>
                    <Typography variant="h6">Anomaly details</Typography>
                    <Typography variant="h6">(id {id})</Typography>
                </Grid>
                <Grid item xs>
                    <CardsHead title="Severity Level" data={details.importance} starValue={startValue()} />
                </Grid>
                <Grid item xs>
                    <CardsHead title="Max bit rate" data={`${valueCalculation(details.maxImpactBps, 'bps')}`} />
                </Grid>
                <Grid item xs>
                    <CardsHead title="Max packet rate" data={`${valueCalculation(details.maxImpactPps, 'pps')}`} />
                </Grid>
                <Grid item xs md={3}>
                    <CardsHead title="Resource Name" data={details.alertManagedObjectName} />
                </Grid>
                <Grid item xs>
                    <CardsHead title="Target IP" data={details.alertResourceCidr} />
                </Grid>
            </Grid>

            <div className={classes.tablesWrapper}>
                <TablesTraffic title="Top Traffic Patterns (last 5 minutes)" id={details.id} />
            </div>
        </div>
    );
};

export default Traffic;
