import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles.js';

const TabPanelBackLink = ({ to, deleteFilter, edit = '', text = 'Back' }) => {
    const classes = useStyles();

    return (
        <Link to={to} className={classes.backLink} onClick={() => (deleteFilter ? deleteFilter() : '')}>
            <ChevronLeft />
            <Typography className={classes.backText}>
                {edit && (
                    <>
                        {edit}
                        <br />
                    </>
                )}
                <span>{text}</span>
            </Typography>
        </Link>
    );
};

export default TabPanelBackLink;
