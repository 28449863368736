import React, { useReducer, createContext } from 'react';
import { loadState, saveState } from '../helpers/localStorage';

if (localStorage.getItem('jwtToken') === null) {
    localStorage.removeItem('FILTER_ACTIVE');
    localStorage.removeItem('SECONDARY_FILTER');
}

export const initialState = {
    filter: loadState('FILTER_ACTIVE') || {
        limit: 10,
        page: 0,
        gte: new Date(new Date().setDate(new Date().getDate() - 1)),
        lte: new Date(),
        timeBillingStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
        timeBillingEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
    },
    secondaryFilter: loadState('SECONDARY_FILTER') || {
        limit: 10,
        page: 0,
    },
};

const PageContext = createContext({
    filter: {
        limit: 10,
        page: 0,
        gte: new Date(new Date().setDate(new Date().getDate() - 1)),
        lte: new Date(),
        timeBillingStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
        timeBillingEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
    },
    secondaryFilter: {
        limit: 10,
        page: 0,
    },
    setFilter: data => {},
    setSecondaryFilter: data => {},
    deleteFilter: () => {},
    deleteSecondaryFilter: () => {},
});

function pagesReducer(state, action) {
    switch (action.type) {
        case 'FILTER_ACTIVE':
            return {
                ...state,
                filter: action.payload,
            };
        case 'SECONDARY_FILTER': {
            return {
                ...state,
                secondaryFilter: action.payload,
            };
        }
        case 'DELETE_SECONDARY_FILTER':
            return {
                ...state,
                secondaryFilter: {
                    limit: 10,
                    page: 0,
                },
            };
        case 'DELETE_FILTER':
            return {
                ...state,
                filter: {
                    limit: 10,
                    page: 0,
                    gte: new Date(new Date().setDate(new Date().getDate() - 1)),
                    lte: new Date(),
                    timeBillingStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0),
                    timeBillingEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
                },
            };
        default:
            return state;
    }
}

function PageProvider(props) {
    const [state, dispatch] = useReducer(pagesReducer, initialState);

    function setFilter(data) {
        saveState(data, 'FILTER_ACTIVE');
        dispatch({
            type: 'FILTER_ACTIVE',
            payload: data,
        });
    }

    function setSecondaryFilter(data) {
        saveState(data, 'SECONDARY_FILTER');
        dispatch({
            type: 'SECONDARY_FILTER',
            payload: data,
        });
    }

    function deleteSecondaryFilter() {
        localStorage.removeItem('SECONDARY_FILTER');
        dispatch({ type: 'DELETE_SECONDARY_FILTER' });
    }

    function deleteFilter() {
        localStorage.removeItem('FILTER_ACTIVE');
        dispatch({ type: 'DELETE_FILTER' });
    }

    return (
        <PageContext.Provider
            value={{
                setFilter,
                deleteFilter,
                deleteSecondaryFilter,
                setSecondaryFilter,
                filter: state.filter,
                secondaryFilter: state.secondaryFilter,
            }}
            {...props}
        />
    );
}

export { PageContext, PageProvider };
