import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core';

export const BaseSwitch = withStyles(theme => ({
    switchBase: {
        color: '#F1F1F1',
        '& + $track': {
            backgroundColor: theme.palette.grey.greyDark,
            borderColor: theme.palette.grey.greyDark,
            opacity: 1,
        },
        '&$checked': {
            color: theme.palette.primary.main,
            '& + $track': {
                backgroundColor: theme.palette.primary.primary100,
                borderColor: theme.palette.primary.primary100,
                opacity: 1,
            },
            '&:hover': {
                backgroundColor: theme.palette.grey.greyLight,
            },
        },
    },
    track: {},
    checked: {},
}))(Switch);

export const CustomSwitchOnOff = withStyles(theme => ({
    root: {
        width: 47,
        height: 22,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(24px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                position: 'relative',
                '&:before': {
                    content: "'ON'",
                    color: theme.palette.common.white,
                    position: 'absolute',
                    top: '2px',
                    left: '5px',
                    fontSize: '12px',
                },
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        boxShadow: 'none',
    },
    track: {
        borderRadius: 16,
        opacity: 1,
        backgroundColor: '#BFBFBF',
        position: 'relative',
        '&:before': {
            content: "'OFF'",
            color: theme.palette.common.white,
            position: 'absolute',
            top: '2px',
            right: '5px',
            fontSize: '11px',
        },
    },
    checked: {},
}))(Switch);
