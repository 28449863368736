import { Button, withStyles } from '@material-ui/core';

export const BaseButton = withStyles(theme => ({
    root: {},
    sizeLarge: {
        padding: '15px 30px',
    },
    sizeSmall: {
        padding: '7px 16px',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        textAlign: 'center',
        letterSpacing: '1.25px',
        borderRadius: '4px',
    },
}))(Button);
