import React, { useContext } from 'react';
import { useStyles } from './styles';
import { TextField, InputAdornment, Button, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import Logo from '../../../components/global/Logo';
import AuthWrapper from '../../../components/global/Wrappers/authWrapper';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { loadState } from '../../../helpers/localStorage';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { Login, UPDATE_PARTNER_ACCOUNT_EMPLOYEE } from '../../../actions/update';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const JoinAccountSecond = () => {
    const classes = useStyles();
    const { id, email, type, password } = loadState('secondStep');
    const navigate = useNavigate();
    const context = useContext(AuthContext);

    const [login] = useMutation(Login, {
        update(_, result) {
            context.login(result.data.login.accessToken);
            navigate('/accounts');
        },
        onError(err) {
            console.log(err);
        },
    });

    const [updateClient] = useMutation(UPDATE_PARTNER_ACCOUNT_EMPLOYEE);

    const onSubmit = data => {
        updateClient({
            variables: {
                email,
                first: data.firstName,
                last: data.lastName,
                id: Number(id),
            },
        })
            .then(() => {
                login({
                    variables: {
                        password,
                        email,
                        type,
                    },
                })
                    .then(() => localStorage.removeItem('secondStep'))
                    .catch(err => console.log(err.message));
            })
            .catch(err => toast.error(err.message));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Current first Name is required').min(3, 'Name must be at least 3 characters'),
        lastName: Yup.string().required('Current last Name is required').min(3, 'Name must be at least 3 characters'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <AuthWrapper>
            <Logo />
            <Typography variant="h1" className={classes.title}>
                Join account
            </Typography>
            <div className={classes.description}>Almost done, just enter your first and last name.</div>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            error={!!errors.firstName}
                            onChange={onChange}
                            value={value}
                            label="First name"
                            variant="filled"
                            className={classes.input}
                            helperText={errors?.firstName?.message ?? ' '}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {errors.firstName ? <Error color="error" /> : ''}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            error={!!errors.lastName}
                            onChange={onChange}
                            value={value}
                            label="Last name"
                            variant="filled"
                            className={classes.input}
                            helperText={errors?.lastName?.message ?? ' '}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {errors.lastName ? <Error color="error" /> : ''}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <Button type="submit" color="primary" variant="contained" className={classes.button}>
                    join account
                </Button>
            </form>
        </AuthWrapper>
    );
};

export default JoinAccountSecond;
