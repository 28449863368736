import React, { useContext, useState } from 'react';
import { useStyles } from '../../theme/Auth';
import { TextField, InputAdornment, IconButton, Button, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from '../../components/global/Logo';
import AuthWrapper from '../../components/global/Wrappers/authWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import routes from '../../routes';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AuthContext } from '../../context/auth';
import { useMutation } from '@apollo/client';
import { Login } from '../../actions/update';
import Preloader from '../../components/global/Preloader';

const Auth = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const context = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  const [login, { error, loading }] = useMutation(Login, {
    context: {
      plug: true,
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    localStorage.removeItem('jwtToken');
    login({
      variables: {
        password: data.password,
        email: data.email,
        type: 'PARTNER_ACCOUNT_EMPLOYEE',
      },
    })
      .then((res) => {
        context.login(res.data.login.accessToken);
        navigate('/accounts');
      })
      .catch((err) => console.log(err.message));
  };

  const checkUppercase = /([A-Z])|([А-Я])/;
  const checkLowercase = /([a-z])|([а-я])/;
  const checkNumber = /([0-9])/;
  const checkSpecialCharacter = /[^A-Za-zА-Яа-яёЁ0-9\s]/;

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Current Email Address is required').email('Email is invalid'),
    password: Yup.string()
      .min(6, 'New Password must be at least 8 characters')
      .matches(checkUppercase, 'Password must have one uppercase character')
      .matches(checkLowercase, 'Password must have one lowercase character')
      .matches(checkNumber, 'Password must have one number')
      .matches(checkSpecialCharacter, 'Password must have one special character')
      .required('Current New Password is required'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  if (loading) return <Preloader />;

  return (
    <>
      <AuthWrapper>
        <Logo />
        <Typography variant="h1" className={classes.title}>
          Sign in with email
        </Typography>
        <div
          className={
            error || errors.email || errors.password ? classes.errorBox : classes.errorNone
          }
        >
          <div>{error ? error.message : ''}</div>
          <div>{errors.email ? errors?.email?.message : ''}</div>
          <div>{errors.password ? errors?.password?.message : ''}</div>
        </div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                label="Email"
                variant="filled"
                className={classes.input}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={value}
                label="Password"
                onChange={onChange}
                variant="filled"
                helperText={
                  errors?.password?.message || 'Password must contain at least 8 characters'
                }
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Button type="submit" color="primary" variant="contained" className={classes.button}>
            Sing in
          </Button>
          <Link to={routes.forgotPassword} className={classes.link}>
            Forgot password?
          </Link>
        </form>
      </AuthWrapper>
    </>
  );
};

export default Auth;
