import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
        textAlign: 'center',
    },
    description: {
        '& p': {
            padding: '0 20px',
            marginBottom: 30,
            textAlign: 'center',
            color: theme.palette.common.black,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.25px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            '& span': {
                display: 'block',
            },
        },
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));
