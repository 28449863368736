import React from 'react';
import { useStyles } from './styles';
import { TextField, Button, InputAdornment, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UPDATE_PARTNER_ACCOUNT_EMPLOYEE } from '../../../../actions/update';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const User = ({ user, data, query }) => {
    const classes = useStyles();

    const [setInfo] = useMutation(UPDATE_PARTNER_ACCOUNT_EMPLOYEE);

    const onSubmit = inputData => {
        setInfo({
            variables: {
                first: inputData.firstName,
                last: inputData.lastName,
                id: user && user.id,
            },
            refetchQueries: [
                {
                    query: query,
                    variables: {
                        id: user && user.id,
                    },
                },
            ],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Current First Name is required').min(3, 'Please enter at least 3 characters'),
        lastName: Yup.string().required('Current Last Name is required').min(3, 'Please enter at least 3 characters'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <div>
            <Typography variant="h1" className={classes.title}>
                User Information
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="firstName"
                    control={control}
                    defaultValue={data?.partnerAccountEmployee?.name?.first || ''}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            onChange={onChange}
                            value={value}
                            label="First Name"
                            variant="filled"
                            className={classes.input}
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message ?? ' '}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{errors.firstName ? <Error color="error" /> : ''}</InputAdornment>,
                            }}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    defaultValue={data?.partnerAccountEmployee?.name?.last || ''}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            onChange={onChange}
                            value={value}
                            label="Last Name"
                            variant="filled"
                            className={classes.input}
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message ?? ' '}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{errors.lastName ? <Error color="error" /> : ''}</InputAdornment>,
                            }}
                        />
                    )}
                />
                <TextField disabled defaultValue={data?.partnerAccountEmployee?.email} label="Email" variant="filled" className={classes.input} />
                <div className={classes.buttonWrapper}>
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default User;
