import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
    Popover,
    Button,
    FormGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Checkbox,
    Typography,
} from '@material-ui/core';
import { useStyles } from '../../../../theme/Modals';

const ModalThreatLevel = ({ id, open = false, anchorEl, setAnchorEl, state, setState, filter, setFilter }) => {
    const classes = useStyles();

    const handleListItemClick = value => {
        handleClose(value);
    };

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClose = value => {
        setAnchorEl(null);
        setFilter({
            ...filter,
            threatLevel: Object.entries(value || {})?.reduce((acc, value) => {
                if (value[1]) {
                    acc.push(value[0]);
                }
                return acc;
            }, []),
        });
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                handleClose(filter?.threatLevel);
                handleListItemClick(state);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.modal} style={{ width: 170 }}>
                <div className={classes.header}>
                    <Typography variant="h1">Filter</Typography>

                    <Button
                        className={classes.close}
                        onClick={() => {
                            handleClose(filter.threatLevel);
                            handleListItemClick(state);
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
                <div className={classes.modalBody}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{ paddingBottom: 10 }}>
                            Treat level
                        </FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" checked={state?.low} onChange={handleChange} name="low" />
                                }
                                label="Low"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={state?.medium}
                                        onChange={handleChange}
                                        name="medium"
                                    />
                                }
                                label="Medium"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={state?.high}
                                        onChange={handleChange}
                                        name="high"
                                    />
                                }
                                label="High"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </Popover>
    );
};

export default ModalThreatLevel;
