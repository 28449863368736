import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 28,
        textAlign: 'center',
    },
    description: {
        padding: '0 60px',
        marginBottom: 50,
        textAlign: 'center',
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
    },
    form: {
        background: theme.palette.grey.backgroundMain,
    },
    input: {
        marginBottom: 10,
        width: '100%',
    },
    button: {
        marginTop: 10,
        marginBottom: 30,
        width: '100%',
        padding: '16px 0',
    },
}));
