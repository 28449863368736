import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    bg: {
        backgroundRepeat: 'repeat',
        height: '100vh',
        width: '100vw',
        overflowX: 'hidden',
        backgroundColor: theme.palette.grey.backgroundMain,
    },
    wrapper: {
        minHeight: 'calc(100vh - 50px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '540px',
        margin: '0 auto',
    },
    contentWrapper: {
        marginTop: 30,
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '60px 32px',
    },
    copyright: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: 'rgba(0, 0, 0, 0.45)',
    },
}));
