import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    pickerWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    dataPicker: {
        margin: '5px',
        border: 0,
        padding: '9.5px 0',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.25px',
        color: theme.palette.dataPiker.colorInputDefault,
        backgroundColor: theme.palette.dataPiker.bgInputDefault,
        textAlign: 'center',
        borderRadius: 4,
        maxWidth: 150,
        '&:hover': {
            outlineColor: theme.palette.dataPiker.colorInputFocus,
            outline: '1px solid',
        },
        '&:focus': {
            color: theme.palette.dataPiker.colorInputFocus,
            outlineColor: theme.palette.primary.main,
            outline: '2px solid',
        },
        '&:disabled:hover': {
            outline: 'none',
            cursor: 'not-allowed',
        },
    },
    poper: {
        '& .react-datepicker__header': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '& .react-datepicker__day-name, .react-datepicker__current-month,': {
            color: theme.palette.primary.contrastText,
        },
        '&  .react-datepicker-time__header': {
            color: theme.palette.primary.contrastText,
            minHeight: '46px',
        },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item':
            {
                height: '25px',
                padding: '5px 10px',
            },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list':
            {
                padding: '8px 0',
            },
        '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range':
            {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 15,
            },

        '& .react-datepicker__day:hover': {
            borderRadius: 15,
        },
        '& .react-datepicker__day--keyboard-selected': {
            borderRadius: 15,
        },
        '& .react-datepicker__day--keyboard-selected:hover:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover':
            {
                backgroundColor: theme.palette.primary.main,
            },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
            {
                backgroundColor: theme.palette.primary.main,
            },
        '& .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today':
            {
                backgroundColor: theme.palette.secondary.light,
                borderRadius: 15,
                color: theme.palette.primary.main,
            },
    },
}));
