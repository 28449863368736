import { gql } from 'graphql-tag';

export const Login = gql`
  mutation login($email: String!, $password: String!, $type: ActorTypeForAuthEnum!) {
    login(input: { email: $email, password: $password, type: $type }) {
      accessToken
    }
  }
`;

export const LOGIN_BY_EMPLOYEE = gql`
  mutation loginByEmployee($type: ActorTypeForAuthEnum!, $email: String!) {
    loginByEmployee(input: { email: $email, type: $type }) {
      accessToken
      returnUrl
    }
  }
`;

export const DEACTIVE_TWO_FACTOR_AUTH = gql`
  mutation {
    deActivateTwoFactorAuth {
      email
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword(
    $newPassword: String!
    $oldPassword: String
    $email: String!
    $type: ActorTypeEnum!
    $verifyToken: String
    $resetToken: String
  ) {
    setPassword(
      input: {
        oldPassword: $oldPassword
        newPassword: $newPassword
        email: $email
        type: $type
        verifyToken: $verifyToken
        resetToken: $resetToken
      }
    ) {
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $type: ActorTypeEnum!) {
    resetPassword(input: { email: $email, type: $type }) {
      email
    }
  }
`;

export const UPDATE_PARTNER_ACCOUNT_EMPLOYEE = gql`
  mutation updatePartnerAccountEmployee(
    $first: String
    $last: String
    $id: Float!
    $notificationSubscribe: [NotificationSubscribeInput!]
    $lang: UserLangEnum
    $role: ActorRoleEnum
  ) {
    updatePartnerAccountEmployee(
      input: {
        name: { first: $first, last: $last }
        id: $id
        notificationSubscribe: $notificationSubscribe
        lang: $lang
        role: $role
      }
    ) {
      name {
        first
        last
      }
      role
      email
      notificationSubscribe {
        channel
        trigger
      }
      lang
    }
  }
`;

export const UPDATE_L7_RESOURCE = gql`
  mutation updateL7Resource(
    $partnerClientAccountId: Float
    $serviceId: Float!
    $serviceEnable: Float
  ) {
    updateL7Resource(
      input: {
        partnerClientAccountId: $partnerClientAccountId
        serviceId: $serviceId
        serviceEnable: $serviceEnable
      }
    ) {
      serviceId
    }
  }
`;

export const UPDATE_PARTNER_CLIENT_ACCOUNT = gql`
  mutation updatePartnerClientAccount($id: Float!, $name: String) {
    updatePartnerClientAccount(input: { name: $name, id: $id }) {
      id
      name
    }
  }
`;

export const UPDATE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE = gql`
  mutation updatePartnerClientAccountEmployee($id: Float!, $role: ActorRoleEnum) {
    updatePartnerClientAccountEmployee(input: { role: $role, id: $id }) {
      id
    }
  }
`;

export const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation updateAccountSettings(
    $id: Float!
    $l3Limit: Float
    $l7Limit: Float
    $dosGateLimit: Float
  ) {
    updateAccountSettings(
      input: {
        id: $id
        l3: { l3Limit: $l3Limit }
        l7: { l7Limit: $l7Limit }
        dosGate: { dosGateLimit: $dosGateLimit }
      }
    ) {
      id
    }
  }
`;

export const SET_ACCESS = gql`
  mutation setAccess(
    $partnerClientAccountEmployeeId: Float!
    $l3Delete: [Float!]
    $l3Read: [Float!]
    $l3Update: [Float!]
    $l7Delete: [Float!]
    $l7Read: [Float!]
    $l7Update: [Float!]
    $create: [CreateEmployeeAccessEnum!]
  ) {
    setAccess(
      input: {
        partnerClientAccountEmployeeId: $partnerClientAccountEmployeeId
        l3Delete: $l3Delete
        l3Read: $l3Read
        l3Update: $l3Update
        l7Delete: $l7Delete
        l7Read: $l7Read
        l7Update: $l7Update
        create: $create
      }
    ) {
      id
    }
  }
`;
