import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '30px 30px 50px 30px',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabs: {
        display: 'flex',
    },
    itemWrapper: {
        padding: '0 10px',
        height: '100%',
        maxHeight: 477,
    },
    itemTitle: {
        color: theme.palette.grey.dark,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        padding: '20px 0',
        whiteSpace: 'nowrap',
    },
    cartTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#363636',
        marginLeft: 30,
    },
    charts: {
        paddingTop: 20,
        backgroundColor: '#F6F7FB',
    },
    linear: {
        minWidth: '80px',
    },
    greyColor: {
        color: '#9E9E9E',
    },
}));

export const AntTabs = withStyles(theme => ({
    root: {
        padding: '0 10px',
        margin: 0,
        minHeight: 0,
    },
    indicator: {
        backgroundColor: 'transparent',
    },
}))(Tabs);

export const AntTab = withStyles(theme => ({
    root: {
        minHeight: 22,
        minWidth: 0,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '16px',
        textAlign: 'center',
        padding: '0 10px',
        letterSpacing: '0.5px',
        textTransform: 'lowercase',
        color: 'rgba(0, 0, 0, 0.6)',
        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);
