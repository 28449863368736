import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    linear: {
        minWidth: '70px',
    },
    greyColor: {
        color: '#9E9E9E',
    },
    tableTop: {
        marginBottom: 80,
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    button: {
        textTransform: 'none',
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '21px',
        letterSpacing: '0.25px',
    },
    noDataText: {
        padding: '10px 10px 10px 0',
        color: 'rgb(0 0 0 / 36%)',
    },
}));
