import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export const FilterTextField = withStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.grey.greyLight,
        minWidth: 60,
        borderRadius: 4,
        '& .MuiFormLabel-root': {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '21px',
            color: theme.palette.grey.grey500,
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '5px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },

        '& .MuiInputLabel-outlined': {
            transform: 'translate(10px, 5px) scale(1)',
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(13px, -10px) scale(1)',
        },
        '& .MuiInputBase-input': {
            minWidth: '60px',
            minHeight: '19px',
            fontSize: '12.5px',
            fontWeight: 400,
        },
    },
}))(TextField);
