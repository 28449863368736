import React, { useState, useRef, useEffect, useContext } from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../theme/Table';
import {
  TableContainer,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import TableScroll from '../../../components/global/table/TableScroll';
import Preloader from '../../../components/global/Preloader';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { textWidthCalculation } from '../../../helpers/calculation';
import TableFilter from '../../../components/global/table/TableFilter';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  GET_L7_RESOURCES_VENDOR,
  GET_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
  GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
} from '../../../actions/get';
import { SET_ACCESS } from '../../../actions/update';
import { toast } from 'react-toastify';
import { PageContext } from '../../../context/pages';
import TablePaginationComponent from '../../../components/global/table/TablePagination';
import TableSort from '../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../components/global/table/TableNoData';

const L7ProtectionAccountMembers = ({
  partnerClientAccountEmployeeId,
  partnerClientAccountId,
  email,
  role,
}) => {
  const classes = useStyles();
  const table = useRef(null);
  const { setSecondaryFilter, secondaryFilter } = useContext(PageContext);
  const [sortActive, setSortActive] = useState('sortL7ResourceId');

  const [setAccess] = useMutation(SET_ACCESS);
  const { data: access } = useQuery(GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS, {
    variables: {
      partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
    },
  });

  const [getFilter, { data, loading }] = useLazyQuery(GET_L7_RESOURCES_VENDOR);
  const getVariables = () => {
    const proxy = {
      limit: secondaryFilter.limit,
      page: secondaryFilter.page + 1,
      l7ResourceName: secondaryFilter?.name || '',
      partnerClientAccountId: Number(partnerClientAccountId),
      deletedAt: null,
      [sortActive]: secondaryFilter?.sort?.[sortActive]?.toUpperCase() || 'DESC',
    };
    if (secondaryFilter?.id) proxy.l7ResourceId = Number(secondaryFilter?.id);
    return proxy;
  };

  useEffect(() => {
    getFilter({ variables: getVariables() });
  }, []);

  useDebouncedEffect(
    () => {
      getFilter({ variables: getVariables() });
    },
    1000,
    [secondaryFilter]
  );

  if (loading) return <Preloader />;

  const maxWidth = {
    id: Math.max.apply(
      Math,
      data && data.l7ResourcesVendor.items.map((items) => textWidthCalculation(items.l7ResourceId))
    ),
    name: Math.max.apply(
      Math,
      data &&
        data.l7ResourcesVendor.items.map((items) => textWidthCalculation(items.l7ResourceName))
    ),
  };

  const pushItem = (event, index) => {
    const arr = [];
    arr.push(
      ...(access && access.partnerClientAccountEmployeeAccess?.[index]),
      Number(event.target.name)
    );
    return arr;
  };

  const returnItem = (event, index) => {
    const arr = [];
    arr.push(...(access && access.partnerClientAccountEmployeeAccess?.[index]));
    const position = arr.indexOf(Number(event.target.name));
    arr.splice(position, 1);
    return arr;
  };

  const handleChange = (event, index) => {
    const variables = event.target.checked
      ? {
          partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          [index]: pushItem(event, index),
        }
      : {
          partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          [index]: returnItem(event, index),
        };

    setAccess({
      variables: variables,
      refetchQueries: [
        {
          query: GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
          variables: {
            partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          },
        },
      ],
    })
      .then(() => toast.success('Success'))
      .catch((err) => toast.error(err.message));
  };

  const customData =
    data &&
    data.l7ResourcesVendor.items.map((item) => {
      return {
        ...item,
        l7Read: access && access.partnerClientAccountEmployeeAccess?.l7Read.includes(item.id),
        l7Delete: access && access.partnerClientAccountEmployeeAccess?.l7Delete.includes(item.id),
        l7Update: access && access.partnerClientAccountEmployeeAccess?.l7Update.includes(item.id),
      };
    });

  const statusCreateL7 = access && access.partnerClientAccountEmployeeAccess.create.includes('L7');

  const handleChangeCreateL7 = (value) => {
    // В variables добавляется полностью массив, т.к реализация изменения доступа к другим сервисам будет не скоро, настоял Сергей
    // В случае реализации на backEnd изменение настроек для других сервисов, следует переписать логику с перебором массива
    const variables = value ? ['DOSGATE', 'L3', 'L7'] : ['DOSGATE', 'L3'];
    setAccess({
      variables: {
        partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
        create: variables,
      },
      refetchQueries: [
        {
          query: GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS,
          variables: {
            partnerClientAccountEmployeeId: Number(partnerClientAccountEmployeeId),
          },
        },
      ],
    })
      .then(() => toast.success('Success'))
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      <TableScroll tableRef={table}>
        <TableContainer ref={table}>
          <Grid container>
            <Grid item xs>
              <Typography variant="h1" className={classes.titleHeader}>
                Access to application protection resources for {email}
              </Typography>
              {access &&
                access.partnerClientAccountEmployeeAccess.create &&
                role === 'ENGINEER' && (
                  <div
                    style={{
                      border: '0.5px solid rgba(0, 0, 0, 0.26)',
                      borderRadius: 10,
                      paddingLeft: 15,
                      marginTop: 20,
                      maxWidth: 350,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusCreateL7}
                          onChange={() => {
                            handleChangeCreateL7(!statusCreateL7);
                          }}
                          name="L7"
                          color="primary"
                        />
                      }
                      label="create application protection resources"
                    />
                  </div>
                )}
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <AntTableCell>
                  <TableSort
                    name="sortL7ResourceId"
                    direction={secondaryFilter?.sort?.sortL7ResourceId}
                    label="ID"
                    setFilter={setSecondaryFilter}
                    sortActive={sortActive}
                    setSortActive={setSortActive}
                    filter={secondaryFilter}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCell>
                <AntTableCell>Resource Name</AntTableCell>
                <AntTableCell />
                {role === 'ENGINEER' && (
                  <>
                    <AntTableCell />
                    <AntTableCell />
                  </>
                )}
              </TableRow>
              <TableRow>
                <AntTableCellFilter>
                  <TableFilter
                    name="id"
                    setFilter={setSecondaryFilter}
                    filter={secondaryFilter}
                    maxWidth={maxWidth.id}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCellFilter>
                <AntTableCellFilter>
                  <TableFilter
                    name="name"
                    setFilter={setSecondaryFilter}
                    filter={secondaryFilter}
                    maxWidth={maxWidth.name}
                    type={'SECONDARY_FILTER'}
                  />
                </AntTableCellFilter>
                <AntTableCellFilter />
                {role === 'ENGINEER' && (
                  <>
                    <AntTableCellFilter />
                    <AntTableCellFilter />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {customData && access && customData.length !== 0 ? (
                access.partnerClientAccountEmployeeAccess &&
                customData.map(
                  ({ id, l7ResourceId, l7ResourceName, l7Read, l7Delete, l7Update }) => (
                    <TableRow hover key={id}>
                      <AntTableCell>{l7ResourceId}</AntTableCell>
                      <AntTableCell>{l7ResourceName}</AntTableCell>
                      <AntTableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ padding: '0 9px' }}
                              checked={l7Read}
                              onChange={(event) => {
                                handleChange(event, 'l7Read');
                              }}
                              name={`${id}`}
                              color="primary"
                            />
                          }
                          label="View"
                        />
                      </AntTableCell>
                      {role === 'ENGINEER' && (
                        <>
                          <AntTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ padding: '0 9px' }}
                                  checked={l7Update}
                                  onChange={(event) => {
                                    handleChange(event, 'l7Update');
                                  }}
                                  name={`${id}`}
                                  color="primary"
                                />
                              }
                              label="Edit"
                            />
                          </AntTableCell>
                          <AntTableCell>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{ padding: '0 9px' }}
                                  checked={l7Delete}
                                  onChange={(event) => {
                                    handleChange(event, 'l7Delete');
                                  }}
                                  name={`${id}`}
                                  color="primary"
                                />
                              }
                              label="Delete"
                            />
                          </AntTableCell>
                        </>
                      )}
                    </TableRow>
                  )
                )
              ) : (
                <TableNoDataComponent colSpan={3} />
              )}
            </TableBody>
          </Table>
          <TablePaginationComponent
            filter={secondaryFilter}
            setFilter={setSecondaryFilter}
            count={data && data.l7ResourcesVendor.info.totalCount}
            getFilter={getFilter}
            getVariables={getVariables()}
            type={'SECONDARY_FILTER'}
          />
        </TableContainer>
      </TableScroll>
    </>
  );
};
export default L7ProtectionAccountMembers;
