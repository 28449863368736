import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    popover: {
        zIndex: 99999,
    },
    popoverWrapper: {
        background: theme.palette.common.white,
        border: '1px solid',
        borderColor: theme.palette.grey.greyLight,
    },
    popoverBody: {
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    popoverItem: {
        width: '100%',
        padding: 0,
    },
    popoverMenuItem: {
        justifyContent: 'center',
        width: '100%',
        padding: '5px 20px',
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '1.25px',
        color: theme.palette.grey.greyInactive,
        textTransform: 'uppercase',
    },
    item: {
        '&:hover': {
            backgroundColor: theme.palette.primary.primary50,
            color: theme.palette.primary.main,
        },
    },
    itemDelete: {
        '&:hover': {
            backgroundColor: theme.palette.primary.primary50,
            color: theme.palette.error.main,
        },
    },
    link: {
        textDecoration: 'none',
    },
}));
