import React from 'react';
import { AntTableCell } from '../../../theme/Table';
import dateFormat from 'dateformat';
import { TableRow } from '@material-ui/core';

const User = ({ id, email, first, last, partnerClientAccountId, createdAt, deletedAt }) => {
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {id}
            </AntTableCell>
            <AntTableCell>{email}</AntTableCell>
            <AntTableCell>{first}</AntTableCell>
            <AntTableCell>{last}</AntTableCell>
            <AntTableCell>{partnerClientAccountId}</AntTableCell>
            <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ss TT')}</AntTableCell>
            <AntTableCell>{deletedAt ? 'Deleted' : 'Active'}</AntTableCell>
        </TableRow>
    );
};

export default User;
