import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './common/context/auth';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_API,
});

const authLink = setContext(async (_, previousContext) => {
    const { headers, plug } = previousContext;
    const token = localStorage.getItem('jwtToken') || localStorage.getItem('join');
    if (plug) {
        return {
            headers: {
                ...headers,
                authorization: '',
            },
        };
    } else {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    }
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
