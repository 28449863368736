import React from 'react';
import { Chip, TableRow } from '@material-ui/core';
import { AntTableCell, useStyles } from '../../../theme/Table';
import dateFormat from 'dateformat';
import { PowerSettingsNew } from '@material-ui/icons';

const Resource = ({
    l7ResourceId,
    l7ResourceName,
    serviceIp,
    partnerClientAccountId,
    iauthDisableL7,
    serviceAbpMode,
    serviceEnable,
    setIsSwitchOn,
    setIsSwitchOff,
    createdAt,
}) => {
    const classes = useStyles();
    return (
        <TableRow hover>
            <AntTableCell component="th" scope="row">
                {l7ResourceId}
            </AntTableCell>
            <AntTableCell>{l7ResourceName}</AntTableCell>
            <AntTableCell>{serviceIp}</AntTableCell>
            <AntTableCell>{partnerClientAccountId}</AntTableCell>
            <AntTableCell>
                {iauthDisableL7 === 1 ? (
                    <Chip label="Disabled" style={{ padding: '0 20px' }} />
                ) : (
                    <Chip label="Active" style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }} />
                )}
            </AntTableCell>
            <AntTableCell>
                {serviceAbpMode === 0 ? (
                    <Chip label="Disabled" style={{ padding: '0 20px' }} />
                ) : (
                    <Chip label="Active" style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }} />
                )}
            </AntTableCell>
            <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
            <AntTableCell align="center">
                {serviceEnable === 0 ? (
                    <PowerSettingsNew
                        className={classes.iconPowerOff}
                        onClick={e => {
                            setIsSwitchOn({
                                switchOn: true,
                                name: l7ResourceName,
                                l7ResourceId: l7ResourceId,
                                partnerClientAccountId: partnerClientAccountId,
                            });
                        }}
                    />
                ) : (
                    <PowerSettingsNew
                        className={classes.iconPowerOn}
                        onClick={e => {
                            setIsSwitchOff({
                                switchOff: true,
                                name: l7ResourceName,
                                l7ResourceId: l7ResourceId,
                                partnerClientAccountId: partnerClientAccountId,
                            });
                        }}
                    />
                )}
            </AntTableCell>
        </TableRow>
    );
};

export default Resource;
