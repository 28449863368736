import { TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { AntTableCell } from '../../../../theme/Table';

const TableNoDataComponent = ({ colSpan }) => {
    return (
        <TableRow>
            <AntTableCell align="center" colSpan={colSpan}>
                <Typography variant="h1" style={{ opacity: 0.3, padding: '80px 0 80px 0' }}>
                    No data
                </Typography>
            </AntTableCell>
        </TableRow>
    );
};

export default TableNoDataComponent;
