import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 30,
    },
    form: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: 400,
        marginRight: 30,
        [theme.breakpoints.between('xs', 'sm')]: {
            marginRight: 0,
        },
    },
    input: {
        marginBottom: 5,
        width: '100%',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        padding: '15px 30px',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            marginTop: 15,
        },
    },
    listPass1: {
        '@media screen and (min-width: 1500px)': {
            display: 'none',
        },
    },
    listPass2: {
        '@media screen and (max-width: 1500px)': {
            display: 'none',
        },
    },
}));
