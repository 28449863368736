import React, { useState } from 'react';
import { AntTableCell, AntTableCellWhite, BorderLinearProgress } from '../../../../../../theme/Table';
import { Table, TableHead, TableBody, TableRow, TableContainer, Grid, Typography } from '@material-ui/core';
import CardsTable from '../../../../../../components/global/cards/CardsTable';
import { useQuery } from '@apollo/client';
import { GET_ARBOR_ALERT_DETAIL } from '../../../../../../actions/get';
import { percentCalculation, valueCalculation } from '../../../../../../helpers/calculation';
import Preloader from '../../../../../../components/global/Preloader/index';
import ReactCountryFlag from 'react-country-flag';
import BaseSwitchPpsBps from '../../../../../../components/global/BaseSwitchPpsBps';
import { useStyles } from './styles';

const TablesTraffic = ({ title, id }) => {
    const classes = useStyles();

    const [format, setFormat] = useState({
        tableTop: 'bps',
        sourceIpAdd: 'bps',
        destinationIpAdd: 'bps',
        sourceTcpPort: 'bps',
        destTcpPort: 'bps',
        sourceUdpPort: 'bps',
        destUdpPort: 'bps',
        sourceCountries: 'bps',
        sourceAsn: 'bps',
        protocols: 'bps',
        tcpFlags: 'bps',
        misuseType: 'bps',
    });

    const { data, loading } = useQuery(GET_ARBOR_ALERT_DETAIL, {
        variables: {
            id,
        },
    });

    if (loading) return <Preloader />;

    const typeUnitSpeed = unitSpeed => (unitSpeed === 'bps' ? 'bytes' : 'packets');

    const progress = type => {
        return (
            data &&
            data.arborAlertDetail[`dest_addr_${type}`].reduce((p, c) => {
                return p + c[`avg_${type}`];
            }, 0)
        );
    };

    return (
        <>
            <div className={classes.tableTop}>
                <Grid container>
                    <Grid item xs>
                        <Typography>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <BaseSwitchPpsBps
                            switchBpsPps={format}
                            setSwitchBpsPps={setFormat}
                            name={'tableTop'}
                            disabled={data && data.arborAlertDetail[`top_patterns_${typeUnitSpeed(format.tableTop)}`].length < 1}
                        />
                    </Grid>
                </Grid>

                {data && data.arborAlertDetail[`top_patterns_${typeUnitSpeed(format.tableTop)}`].length < 1 ? (
                    <div className={classes.noDataText}> No patterns found in the last 5 minutes of the selected timeframe.</div>
                ) : (
                    <TableContainer style={{ maxHeight: 320 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <AntTableCellWhite />
                                    <AntTableCellWhite>Source</AntTableCellWhite>
                                    <AntTableCellWhite>Protocol</AntTableCellWhite>
                                    <AntTableCellWhite>Flags</AntTableCellWhite>
                                    <AntTableCellWhite>Source Port</AntTableCellWhite>
                                    <AntTableCellWhite>Destination</AntTableCellWhite>
                                    <AntTableCellWhite>Dest Port</AntTableCellWhite>
                                    <AntTableCellWhite align="center">Max traffic</AntTableCellWhite>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.arborAlertDetail[`top_patterns_${typeUnitSpeed(format.tableTop)}`].map((item, index) => (
                                        <TableRow hover key={`${index}tableTop${format.tableTop}`}>
                                            <AntTableCell>{index + 1}.</AntTableCell>
                                            <AntTableCell>
                                                {item[`source_ip_${typeUnitSpeed(format.tableTop)}`] === '0.0.0.0/0'
                                                    ? 'Highly Distributed'
                                                    : item[`source_ip_${typeUnitSpeed(format.tableTop)}`]}
                                            </AntTableCell>
                                            <AntTableCell>{item[`src_protocol_${typeUnitSpeed(format.tableTop)}`]}</AntTableCell>
                                            <AntTableCell>-</AntTableCell>
                                            <AntTableCell>{item[`src_port_${typeUnitSpeed(format.tableTop)}`]}</AntTableCell>
                                            <AntTableCell>{item[`dest_ip_${typeUnitSpeed(format.tableTop)}`]}</AntTableCell>
                                            <AntTableCell>{item[`dest_port_${typeUnitSpeed(format.tableTop)}`]}</AntTableCell>
                                            <AntTableCell align="center">
                                                {valueCalculation(item[`max_${typeUnitSpeed(format.tableTop)}`], format.tableTop)}
                                            </AntTableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Source IP Addresses"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'sourceIpAdd'}
                        headTitles={['IP', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`src_addr_${typeUnitSpeed(format.sourceIpAdd)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.sourceIpAdd)}`], progress(typeUnitSpeed(format.sourceIpAdd)));
                                return (
                                    <TableRow hover key={`${index}sourceIpAdd${format.sourceIpAdd}`}>
                                        <AntTableCell>
                                            {item[`name_${typeUnitSpeed(format.sourceIpAdd)}`] === '0.0.0.0/0'
                                                ? 'Highly Distributed'
                                                : item[`name_${typeUnitSpeed(format.sourceIpAdd)}`]}
                                        </AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.sourceIpAdd)}`], format.sourceIpAdd)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.sourceIpAdd)}`], format.sourceIpAdd)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Destination IP Addresses"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'destinationIpAdd'}
                        headTitles={['IP', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`dest_addr_${typeUnitSpeed(format.destinationIpAdd)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(
                                    item[`avg_${typeUnitSpeed(format.destinationIpAdd)}`],
                                    progress(typeUnitSpeed(format.destinationIpAdd))
                                );
                                return (
                                    <TableRow hover key={`${index}destinationIpAdd${format.destinationIpAdd}`}>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.destinationIpAdd)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.destinationIpAdd)}`], format.destinationIpAdd)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.destinationIpAdd)}`], format.destinationIpAdd)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Source TCP Ports"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'sourceTcpPort'}
                        headTitles={['Port', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`src_tcp_ports_${typeUnitSpeed(format.sourceTcpPort)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(
                                    item[`avg_${typeUnitSpeed(format.sourceTcpPort)}`],
                                    progress(typeUnitSpeed(format.sourceTcpPort))
                                );
                                return (
                                    <TableRow hover key={`${index}sourceTcpPort${format.sourceTcpPort}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.sourceTcpPort)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.sourceTcpPort)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.sourceTcpPort)}`], format.sourceTcpPort)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.sourceTcpPort)}`], format.sourceTcpPort)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Destination TCP Ports"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'destTcpPort'}
                        headTitles={['Port', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`dest_tcp_ports_${typeUnitSpeed(format.destTcpPort)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.destTcpPort)}`], progress(typeUnitSpeed(format.destTcpPort)));
                                return (
                                    <TableRow hover key={`${index}destTcpPort${format.destTcpPort}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.destTcpPort)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.destTcpPort)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.destTcpPort)}`], format.destTcpPort)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.destTcpPort)}`], format.destTcpPort)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Source UDP Ports"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'sourceUdpPort'}
                        headTitles={['Port', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`src_udp_ports_${typeUnitSpeed(format.sourceUdpPort)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(
                                    item[`avg_${typeUnitSpeed(format.sourceUdpPort)}`],
                                    progress(typeUnitSpeed(format.sourceUdpPort))
                                );
                                return (
                                    <TableRow hover key={`${index}sourceUdpPort${format.sourceUdpPort}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.sourceUdpPort)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.sourceUdpPort)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.sourceUdpPort)}`], format.sourceUdpPort)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.sourceUdpPort)}`], format.sourceUdpPort)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Destination UDP Ports"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'destUdpPort'}
                        headTitles={['Port', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`dest_udp_ports_${typeUnitSpeed(format.destUdpPort)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.destUdpPort)}`], progress(typeUnitSpeed(format.destUdpPort)));
                                return (
                                    <TableRow hover key={`${index}destUdpPort${format.destUdpPort}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.destUdpPort)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.destUdpPort)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.destUdpPort)}`], format.destUdpPort)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.destUdpPort)}`], format.destUdpPort)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Source Countries"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'sourceCountries'}
                        headTitles={['', 'Country', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`src_countries_${typeUnitSpeed(format.sourceCountries)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(
                                    item[`avg_${typeUnitSpeed(format.sourceCountries)}`],
                                    progress(typeUnitSpeed(format.sourceCountries))
                                );
                                return (
                                    <TableRow hover key={`${index}sourceCountries${format.sourceCountries}`}>
                                        <AntTableCell>
                                            <ReactCountryFlag
                                                className="emojiFlag"
                                                countryCode={item[`country_code_${typeUnitSpeed(format.sourceCountries)}`]}
                                                svg
                                            />
                                        </AntTableCell>
                                        <AntTableCell>{item[`country_${typeUnitSpeed(format.sourceCountries)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.sourceCountries)}`], format.sourceCountries)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.sourceCountries)}`], format.sourceCountries)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Source ASNs"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'sourceAsn'}
                        headTitles={['Number', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`src_asn_${typeUnitSpeed(format.sourceAsn)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.sourceAsn)}`], progress(typeUnitSpeed(format.sourceAsn)));
                                return (
                                    <TableRow hover key={`${index}sourceAsn${format.sourceAsn}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.sourceAsn)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.sourceAsn)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.sourceAsn)}`], format.sourceAsn)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.sourceAsn)}`], format.sourceAsn)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Protocols"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'protocols'}
                        headTitles={['Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`protos_${typeUnitSpeed(format.protocols)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.protocols)}`], progress(typeUnitSpeed(format.protocols)));
                                return (
                                    <TableRow hover key={`${index}protocols${format.protocols}`}>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.protocols)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.protocols)}`], format.protocols)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.protocols)}`], format.protocols)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="TCP Flags"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'tcpFlags'}
                        headTitles={['Flag', 'Name', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`tcp_flags_${typeUnitSpeed(format.tcpFlags)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.tcpFlags)}`], progress(typeUnitSpeed(format.tcpFlags)));
                                return (
                                    <TableRow hover key={`${index}tcpFlags${format.tcpFlags}`}>
                                        <AntTableCell>{item[`id_${typeUnitSpeed(format.tcpFlags)}`]}</AntTableCell>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.tcpFlags)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.tcpFlags)}`], format.tcpFlags)}(
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.tcpFlags)}`], format.tcpFlags)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} lg={6}>
                    <CardsTable
                        title="Misuse Types"
                        switchBpsPps={format}
                        setSwitchBpsPps={setFormat}
                        name={'misuseType'}
                        headTitles={['Type', 'Avg (Max)', '', '']}
                        data={data && data.arborAlertDetail[`misuse_types_${typeUnitSpeed(format.misuseType)}`]}
                        renderRow={data =>
                            data &&
                            data.map((item, index) => {
                                const prc = percentCalculation(item[`avg_${typeUnitSpeed(format.misuseType)}`], progress(typeUnitSpeed(format.misuseType)));
                                return (
                                    <TableRow hover key={`${index}misuseType${format.misuseType}`}>
                                        <AntTableCell>{item[`name_${typeUnitSpeed(format.misuseType)}`]}</AntTableCell>
                                        <AntTableCell>
                                            {valueCalculation(item[`avg_${typeUnitSpeed(format.misuseType)}`], format.misuseType)} (
                                            {valueCalculation(item[`max_${typeUnitSpeed(format.misuseType)}`], format.misuseType)})
                                        </AntTableCell>
                                        <AntTableCell className={classes.linear}>
                                            <BorderLinearProgress variant="determinate" value={100 - prc} />
                                        </AntTableCell>
                                        <AntTableCell align="right" className={classes.greyColor}>
                                            {prc} %
                                        </AntTableCell>
                                    </TableRow>
                                );
                            })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default TablesTraffic;
