import { gql } from 'graphql-tag';

// myProfile members
export const CREATE_PARTNER_ACCOUNT_EMPLOYEE = gql`
    mutation createPartnerAccountEmployee($email: String!, $partnerAccountId: Float!) {
        createPartnerAccountEmployee(
            input: { email: $email, partnerAccountId: $partnerAccountId, role: ADMIN }
        ) {
            createdAt
            email
            id
        }
    }
`;

// accounts
export const CREATE_PARTNER_CLIENT_ACCOUNT = gql`
    mutation createPartnerClientAccount($name: String!, $partnerAccountId: Float!) {
        createPartnerClientAccount(input: { name: $name, partnerAccountId: $partnerAccountId }) {
            id
            createdAt
            name
        }
    }
`;

export const CREATE_PARTNER_CLIENT_ACCOUNT_EMPLOYEE = gql`
    mutation createPartnerClientAccountEmployee($role: ActorRoleEnum!, $partnerClientAccountId: Float!, $email: String!) {
        createPartnerClientAccountEmployee(
            input: { role: $role, partnerClientAccountId: $partnerClientAccountId, email: $email}
        ) {
            id
            createdAt
        }
    }
`;
