import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    backLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        '&::after': {
            content: "' '",
            border: '1px solid #D5CECE',
            height: '70%',
        },
        '& svg': {
            color: '#363636',
            fontSize: '40px',
        },
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },

    backText: {
        color: '#363636',
        fontSize: '14px',
        letterSpacing: '1px',
        fontWeight: 400,
        lineHeight: '16px',
        paddingRight: 15,
        paddingLeft: 50,
        '& span': {
            whiteSpace: 'nowrap',
        },
    },
}));
