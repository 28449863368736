import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    text: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 24,
        lineHeight: '28px',
    },
}));

export const AntCard = withStyles(theme => ({
    root: {
        padding: '15px 10px 8px 10px',
        border: 'none',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80px',
        background: 'linear-gradient(180deg, #F6F7FB 0%, #FBFCFD 100%)',
    },
}))(Card);
