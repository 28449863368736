import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { Button, TextField, Dialog, Typography } from '@material-ui/core';
import { useStyles } from '../../../../theme/Modals';
import { useMutation } from '@apollo/client';
import { CREATE_PARTNER_CLIENT_ACCOUNT } from '../../../../actions/create';
import { toast } from 'react-toastify';
import Preloader from '../../../global/Preloader';

const ModalCreateAccount = ({ show, hideModal, query, getVariables, partnerAccountId }) => {
    const classes = useStyles();
    const { handleSubmit, control } = useForm();

    const [setCreateAccount, { loading }] = useMutation(CREATE_PARTNER_CLIENT_ACCOUNT);

    const onSubmit = dataInput => {
        setCreateAccount({
            variables: {
                partnerAccountId,
                name: dataInput.name,
            },
            refetchQueries: [{ query: query, variables: getVariables }],
        })
            .then(() => toast.success('Success'))
            .catch(err => toast.error(err.message));
        hideModal();
    };

    if (loading) return <Preloader />;

    return (
        <div>
            <Dialog
                className={classes.dialog}
                maxWidth="md"
                open={show}
                onClose={() => {
                    hideModal();
                }}
            >
                <div className={classes.modal}>
                    <div className={classes.header}>
                        <Typography variant="h1">Create new account</Typography>
                        <Button className={classes.close} onClick={() => hideModal()}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.modalBody}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Name"
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.modalFooter}>
                            <Button className={`${classes.button} ${classes.cancel}`} onClick={() => hideModal()}>
                                Cancel
                            </Button>
                            <Button type="submit" className={`${classes.button} ${classes.invite}`}>
                                CREATE
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ModalCreateAccount;
