import { makeStyles } from '@material-ui/core/styles';

export const button = makeStyles(theme => ({
    button: {
        padding: '2px 35px',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        letterSpacing: '1.25px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
        borderRadius: '4px',
        textDecoration: 'none',
    },
}));
