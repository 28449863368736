import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: '30px !important',
        textAlign: 'center',
    },
    form: {
        background: theme.palette.grey.backgroundMain,
    },
    button: {
        marginBottom: 32,
        width: '100%',
        padding: '16px 0',
    },

    link: {
        display: 'block',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: theme.palette.grey.grey500,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    input: {
        width: '100%',
        marginBottom: '45px !important',
    },
    errorNone: {
        display: 'none',
    },
    errorBox: {
        background: theme.palette.error.backgroundError,
        padding: '25px 0',
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: 23,
    },
}));
