import { gql } from 'graphql-tag';

export const GET_ACCOUNT_SETTINGS = gql`
  query accountSettings($id: Float) {
    accountSettings(where: { id: { eq: $id } }) {
      clientAccountId
      id
      l3 {
        l3Limit
      }
      l7 {
        l7Limit
      }
      partnerClientAccountId
      dosGate {
        dosGateLimit
      }
    }
  }
`;

export const GET_PARTNER_ACCOUNT_EMPLOYEE = gql`
  query partnerAccountEmployee($id: [Int!]) {
    partnerAccountEmployee(where: { id: { in: $id } }) {
      id
      email
      name {
        first
        last
      }
      role
      notificationSubscribe {
        channel
        trigger
      }
      partnerAccountId
      lang
    }
  }
`;

export const GET_PARTNER_ACCOUNT_EMPLOYEES = gql`
  query partnerAccountEmployees($limit: Float, $page: Float, $id: [Int!]) {
    partnerAccountEmployees(
      paginate: { limit: $limit, page: $page }
      order: { id: DESC }
      where: { id: { in: $id } }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        status
        id
        email
        name {
          first
          last
        }
        role
        notificationSubscribe {
          channel
          trigger
        }
        partnerAccountId
        createdAt
        deletedAt
      }
    }
  }
`;

export const GET_PARTNER_CLIENT_ACCOUNTS = gql`
  query partnerClientAccounts(
    $limit: Float
    $page: Float
    $id: [Int!]
    $name: String
    $deletedAt: Date
    $sortId: OrderEnum
  ) {
    partnerClientAccounts(
      paginate: { limit: $limit, page: $page }
      where: { id: { in: $id }, name: { contain: $name }, deletedAt: { eq: $deletedAt } }
      order: { id: $sortId }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        id
        createdAt
        deletedAt
        name
        partnerClientAccountEmployees {
          email
          id
          deletedAt
        }
      }
    }
  }
`;

export const GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES = gql`
  query partnerClientAccountEmployees(
    $limit: Float
    $page: Float
    $id: [Int!]
    $partnerClientAccountId: [Int!]
    $email: String
    $sortId: OrderEnum
    $deletedAt: Date
  ) {
    partnerClientAccountEmployees(
      paginate: { limit: $limit, page: $page }
      order: { id: $sortId }
      where: {
        id: { in: $id }
        partnerClientAccountId: { in: $partnerClientAccountId }
        email: { contain: $email }
        deletedAt: { eq: $deletedAt }
      }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        createdAt
        deletedAt
        email
        id
        lang
        name {
          first
          last
        }
        notificationSubscribe {
          channel
          trigger
        }
        partnerClientAccountId
        phone
        role
        status
        updatedAt
      }
    }
  }
`;

export const GET_L7_RESOURCES_VENDOR = gql`
  query l7ResourcesVendor(
    $limit: Float
    $page: Float
    $l7ResourceId: [Int!]
    $l7ResourceName: String
    $serviceIp: String
    $deletedAt: Date
    $sortL7ResourceId: OrderEnum
    $sortPartnerClientAccountId: OrderEnum
    $sortCreatedAt: OrderEnum
    $partnerClientAccountId: Float
  ) {
    l7ResourcesVendor(
      paginate: { limit: $limit, page: $page }
      order: {
        l7ResourceId: $sortL7ResourceId
        partnerClientAccountId: $sortPartnerClientAccountId
        createdAt: $sortCreatedAt
      }
      where: {
        l7ResourceId: { in: $l7ResourceId }
        l7ResourceName: { contain: $l7ResourceName }
        serviceIp: { contain: $serviceIp }
        deletedAt: { eq: $deletedAt }
        partnerClientAccountId: { eq: $partnerClientAccountId }
      }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        botAnalytics
        botProtection
        createdAt
        deletedAt
        externalNginx
        id
        isBotProfile
        l7ResourceId
        l7ResourceName
        partnerClientAccountId
        passLabelToUpstream
        protectionDisabled
        protectionMode
        serviceCreated
        serviceEnable
        serviceExternalNginx
        serviceForcessl
        serviceGeoipList
        serviceGeoipMode
        serviceGlobalWhitelist
        serviceGrpc
        serviceHttp2
        serviceHttp2https
        serviceHttps2http
        serviceId
        serviceIp
        serviceModified
        serviceName
        serviceSsl
        serviceSslAuto
        serviceSslExpire
        serviceStream
        serviceWaf
        serviceWafMode
        serviceWwwredir
        updatedAt
      }
    }
  }
`;

export const GET_ARBOR_RESOURCES = gql`
  query arborResources(
    $limit: Float
    $page: Float
    $name: String
    $id: [Int!]
    $partnerClientAccountId: [Int!]
    $deletedAt: Date
    $sortId: OrderEnum
    $sortAccountId: OrderEnum
    $sortCreatedAt: OrderEnum
  ) {
    arborResources(
      paginate: { limit: $limit, page: $page }
      where: {
        id: { in: $id }
        name: { contain: $name }
        partnerClientAccountId: { in: $partnerClientAccountId }
        deletedAt: { eq: $deletedAt }
      }
      order: { id: $sortId, partnerClientAccountId: $sortAccountId, createdAt: $sortCreatedAt }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        id
        ips
        name
        createdAt
        partnerClientAccountId
        deletedAt
      }
    }
  }
`;

export const GET_ARBOR_ALERTS = gql`
  query arborAlerts(
    $limit: Float
    $page: Float
    $importance: [String!]
    $id: [Int!]
    $detailsId: Float
    $ips: String
    $gte: Date
    $lte: Date
    $sortId: OrderEnum
    $sortDuration: OrderEnum
    $sortStart: OrderEnum
    $sortStop: OrderEnum
    $sortBps: OrderEnum
    $sortPps: OrderEnum
    $ongoing: String
  ) {
    arborAlerts(
      paginate: { limit: $limit, page: $page }
      where: {
        importance: { in: $importance }
        alertId: { in: $id, eq: $detailsId }
        alertResourceCidr: { contain: $ips }
        start: { gte: $gte, lte: $lte }
        ongoing: { eq: $ongoing }
      }
      order: {
        alertId: $sortId
        duration: $sortDuration
        start: $sortStart
        stop: $sortStop
        maxImpactBps: $sortBps
        maxImpactPps: $sortPps
      }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        alertResourceCidr
        arborResource {
          name
        }
        alertManagedObjectName
        alertResourceCidr
        alertId
        direction
        duration
        host
        id
        importance
        misuseTypes
        maxImpactBps
        maxImpactPps
        start
        stop
        type
        severityPercent
        threshold
        impactBpsPoints
        impactPpsPoints
        unit
        impactRecorded
      }
    }
  }
`;

export const GET_ARBOR_ALERT_DETAIL = gql`
  query arborAlertDetail($id: Float!) {
    arborAlertDetail(id: $id) {
      id
      arborAlertId
      protos_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      src_addr_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      src_addr_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      dest_addr_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      dest_addr_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      src_tcp_ports_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      src_tcp_ports_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      dest_tcp_ports_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      dest_tcp_ports_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      src_udp_ports_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      src_udp_ports_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      dest_udp_ports_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      dest_udp_ports_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      src_asn_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      src_asn_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      tcp_flags_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
      }
      tcp_flags_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      misuse_types_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
        sample_bytes
      }
      misuse_types_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
        sample_packets
      }
      packet_distribution {
        avg
        type
        sample
      }
      protos_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
      }
      src_countries_bytes {
        avg_bytes
        id_bytes
        max_bytes
        name_bytes
        country_bytes
        country_code_bytes
      }
      src_countries_packets {
        avg_packets
        id_packets
        max_packets
        name_packets
        country_packets
        country_code_packets
      }
      top_patterns_bytes {
        avg_bytes
        dest_ip_bytes
        dest_port_bytes
        max_bytes
        name_bytes
        src_port_bytes
        source_ip_bytes
        src_protocol_bytes
      }
      top_patterns_packets {
        avg_packets
        dest_ip_packets
        dest_port_packets
        max_packets
        name_packets
        src_port_packets
        source_ip_packets
        src_protocol_packets
      }
    }
  }
`;

export const GET_ARBOR_MITIGATIONS = gql`
  query arborMitigations(
    $limit: Float
    $page: Float
    $prefix: String
    $gte: Date
    $lte: Date
    $name: String
    $mitigationId: [Int!]
    $arborAlertId: [Int!]
    $ongoing: String
    $sortMitigationId: OrderEnum
    $sortAnomalyId: OrderEnum
    $sortDuration: OrderEnum
    $sortStart: OrderEnum
    $sortStop: OrderEnum
    $id: Float
  ) {
    arborMitigations(
      paginate: { limit: $limit, page: $page }
      order: {
        duration: $sortDuration
        start: $sortStart
        stop: $sortStop
        mitigationId: $sortMitigationId
        arborAlertId: $sortAnomalyId
      }
      where: {
        mitigationId: { in: $mitigationId }
        arborAlertId: { in: $arborAlertId }
        prefix: { contain: $prefix }
        start: { gte: $gte, lte: $lte }
        managedObjectName: { contain: $name }
        ongoing: { eq: $ongoing }
        id: { eq: $id }
      }
    ) {
      info {
        hasNextPage
        hasPreviousPage
        limit
        nextPage
        page
        previousPage
        totalCount
        totalPages
      }
      items {
        id
        mitigationId
        arborAlertId
        start
        stop
        prefix
        managedObjectName
        duration
      }
    }
  }
`;

export const GET_PARTNER_CLIENT_ACCOUNT = gql`
  query partnerClientAccount($id: [Int!]) {
    partnerClientAccount(where: { id: { in: $id } }) {
      id
      name
      accountSettings {
        id
        l3 {
          l3Limit
        }
        l7 {
          l7Limit
        }
        dosGate {
          dosGateLimit
        }
      }
    }
  }
`;

export const GET_PARTNER_CLIENT_ACCOUNT_EMPLOYEES_ACCESS = gql`
  query partnerClientAccountEmployeeAccess($partnerClientAccountEmployeeId: [Int!]) {
    partnerClientAccountEmployeeAccess(
      where: { partnerClientAccountEmployeeId: { in: $partnerClientAccountEmployeeId } }
    ) {
      partnerClientAccountEmployeeId
      id
      l3Delete
      l3Read
      l3Update
      l7Delete
      l7Read
      l7Update
      create
    }
  }
`;

export const GET_INVITATION_LINK = gql`
  query getInvitationLink($partnerClientAccountEmployeeId: Float!) {
    getInvitationLink(input: { partnerClientAccountEmployeeId: $partnerClientAccountEmployeeId }) {
      invitationLink
    }
  }
`;

export const GET_MITIGATION_STATISTIC = gql`
  query arborMitigationStatistic($arborMitigationId: Float!) {
    arborMitigationStatistic(arborMitigationId: $arborMitigationId) {
      ratesTotal {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesHttp {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesFilter {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesProtocolBaseline {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesSource24Baseline {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesSynAuth {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesDnsAuth {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesDnsMalformed {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesHttpRegex {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesDnsRegex {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesIdleReset {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesHttpMalformed {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesSipRatelimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesSipMalformed {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesZombie {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesRatelimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesRegex {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesInvalidPkts {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesDnsRatelimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesDnsNxRatelimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesGeoipFilter {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesGeoipRatelimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesSrcnetFilter {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
      ratesConnlimit {
        pass_bps
        pass_pps
        drop_bps
        drop_pps
      }
    }
  }
`;

export const GET_QR_CODE = gql`
  mutation activateTwoFactorAuth {
    activateTwoFactorAuth {
      qrCode
    }
  }
`;
