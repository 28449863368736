import React from 'react';
import { useStyles, AntTableCell, AntTableCellFilter } from '../../../../theme/Table/index';
import { Table, TableBody, TableHead, TableRow, Chip } from '@material-ui/core';
import { textWidthCalculation } from '../../../../helpers/calculation';
import TableFilter from '../../../../components/global/table/TableFilter';
import dateFormat from 'dateformat';
import { PowerSettingsNew } from '@material-ui/icons';
import TableSort from '../../../../components/global/table/TableSort';
import TableNoDataComponent from '../../../../components/global/table/TableNoData';

const TableL7Resources = ({ data, setFilter, filter, setIsSwitchOff, setIsSwitchOn, sortActive, setSortActive }) => {
    const classes = useStyles();

    const maxWidth = {
        l7ResourceId: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceId))
        ),
        l7ResourceName: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.l7ResourceName))
        ),
        serviceIp: Math.max.apply(
            Math,
            data && data.l7ResourcesVendor.items.map(items => textWidthCalculation(items.serviceIp))
        ),
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <AntTableCell>
                            <TableSort
                                name="sortL7ResourceId"
                                direction={filter?.sort?.sortL7ResourceId}
                                label="ID"
                                setFilter={setFilter}
                                sortActive={sortActive}
                                setSortActive={setSortActive}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell>Resource Name</AntTableCell>
                        <AntTableCell>Service IP</AntTableCell>
                        <AntTableCell>L7 Protection</AntTableCell>
                        <AntTableCell>Active bot protection</AntTableCell>
                        <AntTableCell>
                            <TableSort
                                name="sortCreatedAt"
                                direction={filter?.sort?.sortCreatedAt}
                                label="Created At"
                                setFilter={setFilter}
                                sortActive={sortActive}
                                setSortActive={setSortActive}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                            />
                        </AntTableCell>
                        <AntTableCell />
                    </TableRow>
                    <TableRow>
                        <AntTableCellFilter>
                            <TableFilter
                                name="l7ResourceId"
                                setFilter={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                                maxWidth={maxWidth.l7ResourceId}
                            />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter
                                name="l7ResourceName"
                                setFilter={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                                maxWidth={maxWidth.l7ResourceName}
                            />
                        </AntTableCellFilter>
                        <AntTableCellFilter>
                            <TableFilter
                                name="serviceIp"
                                setFilter={setFilter}
                                filter={filter}
                                type={'FILTER_ACTIVE'}
                                maxWidth={maxWidth.serviceIp}
                            />
                        </AntTableCellFilter>
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                        <AntTableCellFilter />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.l7ResourcesVendor.items.length !== 0 ? (
                        data.l7ResourcesVendor.items.map(
                            ({
                                serviceIp,
                                l7ResourceId,
                                createdAt,
                                l7ResourceName,
                                iauthDisableL7,
                                serviceAbpMode,
                                serviceEnable,
                            }) => (
                                <TableRow hover key={l7ResourceId}>
                                    <AntTableCell component="th" scope="row">
                                        {l7ResourceId}
                                    </AntTableCell>
                                    <AntTableCell>{l7ResourceName}</AntTableCell>
                                    <AntTableCell>{serviceIp}</AntTableCell>
                                    <AntTableCell>
                                        {iauthDisableL7 === 0 ? (
                                            <Chip
                                                label="Active"
                                                style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }}
                                            />
                                        ) : (
                                            <Chip label="Disabled" style={{ padding: '0 20px' }} />
                                        )}
                                    </AntTableCell>
                                    <AntTableCell>
                                        {serviceAbpMode === 0 ? (
                                            <Chip label="Disabled" style={{ padding: '0 20px' }} />
                                        ) : (
                                            <Chip
                                                label="Active"
                                                style={{ color: '#00C853', background: '#ECF9F3', padding: '0 20px' }}
                                            />
                                        )}
                                    </AntTableCell>
                                    <AntTableCell>{dateFormat(createdAt, 'dd/mm/yy h:MM:ssTT')}</AntTableCell>
                                    <AntTableCell align="left">
                                        {serviceEnable === 0 ? (
                                            <PowerSettingsNew
                                                className={classes.iconPowerOff}
                                                onClick={e => {
                                                    setIsSwitchOn({
                                                        switchOn: true,
                                                        name: l7ResourceName,
                                                        l7ResourceId: l7ResourceId,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <PowerSettingsNew
                                                className={classes.iconPowerOn}
                                                onClick={e => {
                                                    setIsSwitchOff({
                                                        switchOff: true,
                                                        name: l7ResourceName,
                                                        l7ResourceId: l7ResourceId,
                                                    });
                                                }}
                                            />
                                        )}
                                    </AntTableCell>
                                </TableRow>
                            )
                        )
                    ) : (
                        <TableNoDataComponent colSpan={7} />
                    )}
                </TableBody>
            </Table>
        </>
    );
};
export default TableL7Resources;
