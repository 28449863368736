import React from 'react';
import { useStyles } from './styles';
import { TextField, Button, InputAdornment, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import AuthWrapper from '../../components/global/Wrappers/authWrapper';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../routes';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RESET_PASSWORD } from '../../actions/update';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const [resPass, { loading }] = useMutation(RESET_PASSWORD, {
        fetchPolicy: 'no-cache',
        context: {
            plug: true,
        },
    });
    const onSubmit = data => {
        resPass({
            variables: {
                email: data.email,
                type: 'PARTNER_ACCOUNT_EMPLOYEE',
            },
        })
            .then(() => {
                toast.success('Success');
                navigate(`${routes.forgotPasswordDone}?email=${data.email}`);
            })
            .catch(err => console.log(err.message));
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Current Email Address is required').email('Email is invalid'),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    return (
        <>
            <AuthWrapper>
                <Typography variant="h1" className={classes.title}>
                    Reset your password
                </Typography>
                <div className={classes.description}>We’ll email you instructions to reset your password.</div>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value}
                                label="Email Address"
                                variant="filled"
                                className={classes.input}
                                error={!!errors.email}
                                helperText={errors?.email?.message ?? ' '}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {errors.email ? <Error color="error" /> : ''}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                        reset password
                    </Button>
                    <Link to={routes.auth} className={classes.link}>
                        Return to log in
                    </Link>
                </form>
            </AuthWrapper>
        </>
    );
};

export default ForgotPassword;
